import { useCallback, useEffect, useState } from "react";
function CMS(props) {
    const [cmsBlock,setCmsBlock] = useState(window.cmsblock ?? {});
        const cms_block = useCallback(async () => {
            if(cmsBlock['landing_page_bg'] == undefined ) {
                const response = await (await fetch(`https://romp.theromp.com/api/cmsblock`, {headers: {    "accept": "application/json",},    method: 'GET',})).text()
                setCmsBlock(JSON.parse(response));
                window.cmsblock = cmsBlock;
            }
        })
    useEffect(async () => {
            await cms_block();
    },[]);
    return cmsBlock[props] ?? '                            ';
}
export default CMS;