import { useWindowResize } from "../../utils/Hooks/useWindowResize";
import { FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { ReactComponent as RompDark } from "../../img/Dashboard/logo-romp.svg";
import { useState } from "react";
import { useTheme, useUser } from "../../utils/Contexts/Context";

import { ReactComponent as home } from "../../img/AllIconsSvg/Home.svg";
import { ReactComponent as Video } from "../../img/AllIconsSvg/Play.svg";
import { ReactComponent as Star } from "../../img/AllIconsSvg/Models.svg";
import { ReactComponent as saved } from "../../img/AllIconsSvg/Saved.svg";
import { ReactComponent as search } from "../../img/AllIconsSvg/Search.svg";
import { ReactComponent as Shop } from "../../img/AllIconsSvg/Shop.svg";
import { ReactComponent as cog } from "../../img/AllIconsSvg/Account.svg";
import { ReactComponent as logout } from "../../img/AllIconsSvg/Logout.svg";
import { ReactComponent as messages } from "../../img/AllIconsSvg/Inbox.svg";
import { ReactComponent as account } from "../../img/AllIconsSvg/My Profile.svg";
import { ReactComponent as Hamburger } from "../../img/AllIconsSvg/Hamburger.svg";
// import ham from "../../img/Dashboard/NavIcons/Account iconham@3x.png";
import { IconWrapper } from "./Dashboard";
export default function NavBar({ navRef, NewMessageAlert }) {
    const [nav, setNav] = useState(false);
    const links = [
        { id: 0, text: "Home", url: "", icon: home, subText: "View and edit profile details" },
        {
            id: 1,
            text: "Videos",
            url: "videos",
            icon: Video,
            subText: "View and edit account details",
        },
        {
            id: 2,
            text: "Models",
            url: "models",
            icon: Star,
            subText: "View and update subscription",
        },
        {
            id: 3,
            text: "Saved",
            url: "saved",
            icon: saved,
            subText: "View and edit billing details",
        },
        {
            id: 4,
            text: "Search",
            url: "search",
            icon: search,
            subText: "View legal and help details",
        },
        
        { id: 5, text: "Shop", url: "shop", icon: Shop, subText: "Earn money inviting friends" },
    ];
    const { setUser } = useUser();
    const { Mode } = useTheme();
    const linksJSX = links.map(({ id, text, url, icon, subText }) => {
        return (
            <NavItem
                key={id}
                text={text}
                url={"/" + url}
                sufIcon={icon}
                subText={subText}
                exe={() => {
                    setNav(false);
                }}
            />
        );
    });

    return (
        <div className="dash_nav" ref={navRef}>
            <div className="dash_nav_wrapper container">
                <div className="logo">
                    <NavLink to="/home">
                        <RompDark fill={`${Mode ? "black" : "white"}`} style={{filter: !Mode ? 'brightness(200)' : ''}} />
                    </NavLink>
                </div>
                <NavItems className="nav_main">{linksJSX}</NavItems>
                <div className="side_nav">
                    <div className="message">
                        <span
                            className="onlineStatus"
                            style={{ opacity: NewMessageAlert ? 1 : 0 }}
                        ></span>
                        <NavItems>
                            <NavItem
                                sufIcon={messages}
                                text="Inbox"
                                url="/messages"
                                className="message_text"
                            />
                        </NavItems>
                    </div>
                    <div className="extras">
                        <NavItems>
                            <NavItem sufIcon={account} text="my account" url="/account" />
                        </NavItems>
                    </div>
                    {!nav ? (
                        <IconWrapper Icon={Hamburger} exe={() => setNav(true)} className="menu" />
                    ) : (
                        <FaTimes
                            className="close_nav"
                            onClick={() => {
                                setNav(false);
                            }}
                        />
                    )}
                </div>

                <div className={`mobilenav ${nav ? "open" : ""}`}>
                    <NavItems>
                        {linksJSX}
                        <NavItem
                            text="Account"
                            url="/account"
                            className="account"
                            sufIcon={cog}
                            subText="Logout of the Romp Magazine"
                            exe={() => {
                                setNav(false);
                            }}
                        />
                        <NavItem
                            text="Logout"
                            url="/"
                            className="logout"
                            sufIcon={logout}
                            subText="Logout of the Romp Magazine"
                            exe={(e) => {
                                e.preventDefault();
                                setUser(null);
                                localStorage.removeItem("therompmagazine_user_id");
                            }}
                        />
                    </NavItems>
                </div>
            </div>
        </div>
    );
}

function NavItems({ children, className }) {
    return (
        <nav className={className}>
            <ul>{children}</ul>
        </nav>
    );
}

function NavItem({ sufIcon, text, url, className, exe, subText }) {
    const state = useWindowResize();
    return (
        <NavLink
            to={`/home${url}`}
            activeClassName={`${text === "Logout" ? "logout" : "active"}`}
            onClick={exe}
            exact={text === "Home"}
        >
            <li className={className}>
                {/* Only Saved has SVG the rest are png */}
                <div className="img_wrapper">{sufIcon && <IconWrapper Icon={sufIcon} />}</div>
                <div className="text_wrapper">
                    <p className="text">{text}</p>
                    {state && <p className="subText">{subText}</p>}
                </div>
            </li>
        </NavLink>
    );
}
