import { useContext } from "react";
import { AccountSettingsContext, ChangeAbleInput } from "./Account";
import { ReactComponent as Cog } from "../../../img/AllIconsSvg/Account.svg";
import ViewWrapper from "./ViewWrapper";

export default function AccountProfile() {
    const {
        DataDetails: { user },
        UpdateUserSettings,
    } = useContext(AccountSettingsContext);
    const { details } = user || {};
    const { name, email, address, phone } = details || {};
    return (
        <ViewWrapper icon={Cog} header={"Account"}>
            <div className="account_content">
                <div className="grid prof">
                    <div className="row">
                        <div className="head col">Name</div>
                        <div className="info col">
                            <ChangeAbleInput
                                text={name}
                                exe={async (value) => {
                                    await UpdateUserSettings({ name: value });
                                }}
                                fieldName="name"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Email</div>
                        <div className="info col">
                            <ChangeAbleInput
                                text={email}
                                exe={async (value) => {
                                    await UpdateUserSettings({ email: value });
                                }}
                                fieldName="email"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Phone No.</div>
                        <div className="info col">
                            <ChangeAbleInput
                                text={phone}
                                type={"number"}
                                exe={async (value) => {
                                    await UpdateUserSettings({ phone: value });
                                }}
                                fieldName="phone"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Address</div>
                        <div className="info col">
                            <ChangeAbleInput
                                text={address}
                                exe={async (value) => {
                                    await UpdateUserSettings({ address: value });
                                }}
                                fieldName="address"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Password</div>
                        <div className="info col">
                            <ChangeAbleInput
                                text={"**********"}
                                exe={async (value) => {
                                    await UpdateUserSettings({ password: value });
                                }}
                                fieldName="password"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ViewWrapper>
    );
}
