import { CSSTransition } from "react-transition-group";
import { MdClear } from "react-icons/md";
export function Modal({ children, state, setmodal, classTag, header }) {
    return (
        <div className="modal-custom" style={{ pointerEvents: state ? "all" : "none" }}>
            <CSSTransition in={state} unmountOnExit classNames="modal-anim" timeout={400}>
                <div className={`modal-content-custom ${classTag} scroll`}>
                    <div
                        className="modal-content-custom-wrapper"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <div className="modal-head">
                            <h3>{header}</h3>
                            <span
                                className="close"
                                onClick={() => {
                                    setmodal();
                                }}
                            >
                                <MdClear size={30} />
                            </span>
                        </div>
                        <div className="model-content-children">{children}</div>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}
