import React, { Children } from "react";
import { withRouter } from "react-router";
import { Form, Input, Button, Checkbox } from 'antd';
import { toast } from "react-hot-toast";
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import './style.scss';

class Checkout extends React.PureComponent {
    formRef = React.createRef();
    
    constructor(props) {
        super(props);
        this.state =  {
            text_input : props?.details?.users?.details?.address ?? "",
            isLoading: false
        };
    }
    renderLoading() {
        if(this.state.isLoading)
        return <div className="loading" dangerouslySetInnerHTML={{__html: `    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
            <path fill="#000" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform 
                attributeName="transform" 
                attributeType="XML" 
                type="rotate"
                dur="1s" 
                from="0 50 50"
                to="360 50 50" 
                repeatCount="indefinite" />
        </path>
        </svg>`}}>
        </div>
        else
            return null;
    }

    componentDidMount() {
        if(this.props.details.user)
            this.setState({text_input: this.props.details.user.details.address});
    }
    
    render() {
        const { text_input } = this.state;
        const onFinish = async (values) => {
            this.setState({isLoading: true});
            const response = await (await fetch('https://romp.theromp.com/api/place-order?access_token='+this.props.user.token,{
                headers: {
                    "Content-type": "application/json",
                    'Authorization': `Bearer ${this.props.user.token}`
                },
                method: "POST",
                body: JSON.stringify({
                    values,
                    items: this.props.functions.getCart(),
                }),
            })).json();
            this.setState({isLoading: false});
            if(response.success === true) {
                toast.success("Successfully placed your Order.");
                this.props.functions.clearCart();
                this.props.history.push('/home/shop/cart/checkout/success/'+response.data);
            } else {
                toast.error("Order Could not be placed due to "+response.message);
            }
        };
        
        const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        };
        const cartInfo = this.props.functions.getCartInfo();
        if(this.props.details === null || this.props.details === '' || this.props.details.user == undefined)
            return <h1>Loading Information.</h1>;
        const { isMobile } = this.props;
        if(isMobile) {
            return (
                <div className="checkout-cart">
                    {this.renderLoading()}
                    <div className="cart-view">
                        <h1 className="title">Shopping Cart</h1>
                        <div className="flex heading">
                            <span className="flex-row">Item</span>
                            <span className="flex-row">Size</span>
                            <span className="flex-row">Colour</span>
                            <span className="flex-row">Quantity</span>
                            <span className="flex-row">Price</span>
                        </div>
                        {cartInfo.items.map(t =>
                            <div className="flex items">
                                <span className="flex-row">
                                    <div class="pri">
                                        <img src={'https://romp.theromp.com/gordon?asset='+t.gallery.toString().replace('https://romp.theromp.com/public','')} />
                                        <span className="checkout-item-attribute">{t.name}</span>
                                    </div>
                                </span>
                                <span className="flex-row"><span className="checkout-item-attribute">{t.size ?? '?'}</span></span>
                                <span className="flex-row"><span className="checkout-item-attribute">{t.color ?? '?'}</span></span>
                                <span className="flex-row"><span className="checkout-item-attribute">{t.quantity}</span></span>
                                <span className="flex-row"><span className="checkout-item-attribute">$ {t.price}</span></span>
                            </div>
                        )}
                        <div className="checkout">
                            <div className="label-total ">
                                Subtotal
                            </div>
                            <div className="total-text">
                                $ {cartInfo.total}
                            </div>
                        </div>
                        <div className="checkout">
                            <div className="label-total">
                                Sales Tax
                            </div>
                            <div className="total-text ">
                            {/* $ {(cartInfo.total / 10).toFixed(2)} */}
                            $ {(0).toFixed(2)}
                            </div>
                        </div>
                        <div className="checkout">
                            <div className="label-total">
                                Total
                            </div>
                            <div className="total-text">
                            {/* $ {(parseFloat(cartInfo.total) + parseFloat(cartInfo.total/10)).toFixed(2)} */}
                            $ {(parseFloat(cartInfo.total)).toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className="checkout-div">
                        <h1>Checkout</h1>
                        <h3>Shipping/Billing Information</h3>
                        <div className="checkout-form">
                        <Form ref={this.formRef}
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ 
                                email: this.props.details.user.details.email,
                                name: this.props.details.user.details.name,
                                address: this.props.details.user.details.address, 
                                phone: this.props.details.user.details.phone,
                                card: `${this.props.details.user.credit_info.type} **** **** **** ${this.props.details.user.credit_info.last_four}`,
                                coupon: ''
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input initialValues='test' />
                            </Form.Item>
    
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please input your name!' }]}
                            >
                                <Input />
                            </Form.Item>
    
                            <Form.Item
                                label="Address"
                                name="address"
                                rules={[{ required: true, message: 'Please input your Address!' }]}
                            >
                                <Input hidden="true" />
                            <GeoapifyContext apiKey="e9e060db041142d981bd3fc0b8e002b5">
                            <GeoapifyGeocoderAutocomplete placeholder="Enter Your Address Here"
                                value={text_input}
                                preprocessHook={(e) => { this.formRef.current.setFieldsValue({address: e } || this.setState({text_input: e })); return e; }}
                                name="address"
                                type='amenity'
                                placeSelect={(e) => this.formRef.current.setFieldsValue({address: e.properties.formatted } || this.setState({text_input: e.properties.formatted }))}/>
                            </GeoapifyContext>
                            
                            </Form.Item>
    
                            <Form.Item
                                label="Phone"
                                name="phone"
                                rules={[{ required: true, message: 'Please input your phone number!' }]}
                            >
                                <Input />
                            </Form.Item>
                            
                            <Form.Item
                                label="Card"
                                name="card"
                                rules={[{ required: true, message: 'Please input your card!' }]}
                            >
                                <Input disabled />
                            </Form.Item>
                            
                            <Form.Item
                                label="Coupon Code"
                                name="coupon"
                                rules={[{ required: false, message: 'Enter Coupon Code!' }]}
                            >
                                <Input />
                            </Form.Item>
                            
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" htmlType="submit">
                                    Place Order
                                </Button>
                            </Form.Item>
                        </Form>
                        </div>
                    </div>
                    
                </div>
            );
        }
        return (
            <div className="checkout-cart">
                {this.renderLoading()}
                <div className="checkout-div">
                    <h1>Checkout</h1>
                    <h3>Shipping/Billing Information</h3>
                    <div className="checkout-form">
                    <Form ref={this.formRef}
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ 
                            email: this.props.details.user.details.email,
                            name: this.props.details.user.details.name,
                            address: this.props.details.user.details.address, 
                            phone: this.props.details.user.details.phone,
                            card: `${this.props.details.user.credit_info.type} **** **** **** ${this.props.details.user.credit_info.last_four}`,
                            coupon: ''
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input initialValues='test' />
                        </Form.Item>

                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please input your Address!' }]}>
                        <GeoapifyContext apiKey="e9e060db041142d981bd3fc0b8e002b5">
                            <GeoapifyGeocoderAutocomplete placeholder="Enter Your Address Here"
                                value={this.props.details.user.details.address}
                                preprocessHook={(e) => { this.formRef.current.setFieldsValue({address: e } || this.setState({text_input: e })); return e; }}
                                type='amenity'
                                placeSelect={(e) => this.formRef.current.setFieldsValue({address: e.properties.formatted } || this.setState({text_input: e.properties.formatted }))}/>
                        </GeoapifyContext>
                        </Form.Item>

                        <Form.Item
                            label="Phone"
                            name="phone"
                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                        >
                            <Input />
                        </Form.Item>
                        
                        <Form.Item
                            label="Card"
                            name="card"
                            rules={[{ required: true, message: 'Please input your card!' }]}
                        >
                            <Input disabled />
                        </Form.Item>
                        
                        <Form.Item
                            label="Coupon Code"
                            name="coupon"
                            rules={[{ required: false, message: 'Enter Coupon Code!' }]}
                        >
                            <Input />
                        </Form.Item>
                        
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Place Order
                            </Button>
                        </Form.Item>
                    </Form>
                    </div>
                </div>
                <div className="cart-view">
                    <h1 className="title">Shopping Cart</h1>
                    <div className="flex heading">
                        <span className="flex-row">Item</span>
                        <span className="flex-row">Size</span>
                        <span className="flex-row">Colour</span>
                        <span className="flex-row">Quantity</span>
                        <span className="flex-row">Price</span>
                    </div>
                    {cartInfo.items.map(t =>
                        <div className="flex items">
                            <span className="flex-row">
                                <div class="pri">
                                    <img src={'https://romp.theromp.com/gordon?asset='+t.gallery.toString().replace('https://romp.theromp.com/public','')} />
                                    <span>{t.name}</span>
                                </div>
                            </span>
                            <span className="flex-row">{t.size ?? '?'}</span>
                            <span className="flex-row">{t.color ?? '?'}</span>
                            <span className="flex-row">{t.quantity}</span>
                            <span className="flex-row">$ {t.price}</span>
                        </div>
                    )}
                    <div className="checkout">
                        <div className="label-total">
                            Subtotal
                        </div>
                        <div className="total-text">
                            $ {cartInfo.total}
                        </div>
                    </div>
                    <div className="checkout">
                        <div className="label-total">
                            Sales Tax
                        </div>
                        <div className="total-text">
                            {/* $ {(cartInfo.total / 10).toFixed(2)} */}
                            $ {(0).toFixed(2)}
                        </div>
                    </div>
                    <div className="checkout">
                        <div className="label-total">
                            Total
                        </div>
                        <div className="total-text">
                            {/* $ {(parseFloat(cartInfo.total) + parseFloat(cartInfo.total/10)).toFixed(2)} */}
                            $ {(parseFloat(cartInfo.total)).toFixed(2)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Checkout);