import { MdPlayCircleOutline } from "react-icons/all";
import { useDim, usePlay } from "../../../../utils/Contexts/Context";
import { useHistory } from "react-router";
import Image from "./Image";
import BookMark from "./BookMark";
import AES from "crypto-js/aes";

export default function Thumb({ imageUrl, sub, exe,type, id, tag,coming_soon = false }) {
    const { dim } = useDim();
    const { seVideotId } = usePlay();
    const history = useHistory();
    return (
        // <div className="thumb" style={dim}>
        <div className="thumb" style={dim}>
            <Image type={type} id={id} url={imageUrl} exe={exe} />
            {tag === "model" ? null : (
                <div className="controls">
                    {coming_soon === true ? null : 
                    <MdPlayCircleOutline
                        onClick={() => {
                            seVideotId(id);
                            history.push("/home/player?id="+AES.encrypt('xxxxxxxx'+id,"TheRompMagazineElemental").toString());
                        }}
                    />
                    }
                    {coming_soon === true ? null : 
                    <BookMark id={id} />
                    }
                </div>
            )}
            <span className="sub" dangerouslySetInnerHTML={{__html:sub}}></span>
        </div>
    );
}
