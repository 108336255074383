import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/all";
import { CSSTransition } from "react-transition-group";
import { useWindowResize } from "../../../utils/Hooks/useWindowResize";
import { useDim, useUser } from "../../../utils/Contexts/Context";
import { useAPIFetch } from "../../../utils/Hooks/useAPIFetch";
import CMS from "../../../utils/cms";
import { v4 } from "uuid";
import "./../style.scss";
import ImageGallery from "./ReusableComponenets/ImageGallery";
import Video from "./Video";
import FeaturedSlider from "./FeaturedSlider";
import FeaturedProduct from "./FeaturedProduct";
function Home() {
    // Gets swiper Instance so i can manipulate the swiper outside the swiper container
    const [swiperInstance, setSwiperInstance] = useState(null);
    // Custom Hook keeps tract of window width (either mobile or desktop)
    const state = useWindowResize();
    const [videodata, setVideoData] = useState({
        url: "",
        id: "",
    });
    const { refetch, setRefetch } = useUser();
    // Popup Toggler
    const [openVideo, setOpenVideo] = useState(false);
    // Hook Uses window Resize hook to set dimension for thumbnails
    const { dim } = useDim();
    // fetches gallery list
    let [Data, Loading, Err] = useAPIFetch(
        `editions?orderby=position,asc`,
        null,
        "GET",
        false,
        refetch
    );
    // fetches featured list
    let [Featured_Data, Featured_Loading, Featured_Err] = useAPIFetch(
        `editions?edition=featured`,
        null,
        "GET",
        false,
        refetch
    );
    // fetches coming soon list
    let [Comming_Data] = useAPIFetch(`coming_soon`, null, "GET", false, refetch);

    // variable to compile all lists
    let dataList = [];

    // Appends Gallery List
    let q = 0;
    dataList =
        Data &&
        Data.data
            .map((data, index) => {
                if(window.screen.height < window.screen.width && q++ === 0)
                    return;
                let tag = data.edition === "Top Ten" ? "rank" : "";
                let className;
                switch (data.edition) {
                    case "Newest Releases":
                        className = "new_gallery";
                        break;
                    case "Maldives Edition":
                        className = "maldives";
                        break;
                    case "Featured":
                        return;
                    default:
                        className = "";
                }
                return (
                    <ImageGallery
                        key={index}
                        data={data}
                        tag={tag}
                        className={className}
                        setVideoData={setVideoData}
                        setOpenVideo={setOpenVideo}
                    />
                );
            });
    // Appends comming soon List to top
    Comming_Data &&
        dataList &&
        dataList.splice(
            1,
            0,
            <ImageGallery
                key={v4()}
                data={Comming_Data.data}
                tag={"comming_soon"}
                className={"comming"}
                setVideoData={setVideoData}
                setOpenVideo={setOpenVideo}
            />
        );
    SwiperCore.use([Navigation, Autoplay]);
    return (
        <main className="top-header">
            <div className="splash">
                <div
                    className={`featured_slider ${Featured_Loading ? "skeleton" : ""}`}
                    onMouseEnter={() => {
                        swiperInstance && swiperInstance.autoplay && swiperInstance.autoplay.stop();
                    }}
                    onMouseLeave={() => {
                        swiperInstance &&
                            swiperInstance.autoplay &&
                            swiperInstance.autoplay.start();
                    }}
                >
                    {/* Show Error on Error */}
                    {Featured_Err && (
                        <h1 className="load_msg">
                            {/* An Error Occured.{" "} */}
                            <span
                                onClick={() => {
                                    setRefetch(v4());
                                }}
                            >
                                Reload
                            </span>
                        </h1>
                    )}
                    {/* Shows when Data Arives */}
                    {Featured_Data &&
                        (Featured_Data.data.length === 0 ? (
                            <h1>Error</h1>
                        ) : (
                            <>
                                {/* uses Slider instance to call Prev on slider */}
                                <div
                                    className="swiper_custom prev"
                                    onClick={() => {
                                        swiperInstance.slidePrev();
                                    }}
                                >
                                    <MdKeyboardArrowLeft />
                                </div>
                                <Swiper
                                    navigation
                                    slidesPerView={1}
                                    speed={500}
                                    spaceBetween={0}
                                    loop
                                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                                    onInit={(swiper) => {
                                        setSwiperInstance(swiper);
                                    }}
                                >
                                    {Featured_Data.data.length === 0 ? (
                                        <h1>No Videos</h1>
                                    ) : (
                                        <>
                                            {/* Slider for Featured Image */}
                                            {Featured_Data.data[0].video_editions.map(
                                                ({ video }, index) => {
                                                    let { id, cover_portrait, cover_landscape } =
                                                        video || {};
                                                        if(id == "" || id == null || id == undefined){
                                                            return ''
                                                        }
                                                    return (
                                                        <SwiperSlide key={index}>
                                                            <FeaturedSlider
                                                                cover_portrait={cover_portrait}
                                                                cover_landscape={cover_landscape}
                                                                id={id}
                                                            />
                                                        </SwiperSlide>
                                                    );
                                                }
                                            )}
                                            {Featured_Data.data[0].featured_products.map((e) => {
                                                if(e.portrait.length == 0 && e.landscape.length == 0) {
                                                    return ""
                                                }
                                                return <SwiperSlide key={e.id}>
                                                    <FeaturedProduct
                                                        cover_portrait={
                                                            e.portrait.length > 0
                                                                ? e.portrait[0]
                                                                : ""
                                                        }
                                                        cover_landscape={
                                                            e.landscape.length > 0
                                                                ? e.landscape[0]
                                                                : ""
                                                        }
                                                        id={e.id}
                                                    />
                                                </SwiperSlide>
})}
                                        </>
                                    )}
                                </Swiper>
                                {/* uses Slider instance to call next on slider */}
                                <div
                                    className=" swiper_custom next"
                                    onClick={() => {
                                        swiperInstance.slideNext();
                                    }}
                                >
                                    <MdKeyboardArrowRight />
                                </div>
                            </>
                        ))}
                </div>
                <div className="w_100 on_splash_wrapper">
                    <div className="on_splash w_100">
                        <div className="container">
                            <div className="new">
                                {Loading && (
                                    <>
                                        {Array.from({ length: 5 }).map((c, index) => (
                                            <div
                                                key={index}
                                                className="video_card skeleton"
                                                style={dim}
                                            ></div>
                                        ))}
                                    </>
                                )}
                                {/* {Err && <h1 className="load_msg">An Error Occured</h1>} */}
                                {Data &&
                                    (Data.data.length === 0 ? (
                                        <h1>No Videos</h1>
                                    ) : (
                                        <>
                                            <ImageGallery
                                                key={Data.data[0].id}
                                                data={Data.data[0]}
                                                tag="new_list"
                                                className="new_gallery_onSplash"
                                                setVideoData={setVideoData}
                                                setOpenVideo={setOpenVideo}
                                            />
                                        </>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section" id="gallery_stack">
                <div className="container">
                    <div className="galleries">
                        {Loading && (
                            <>
                                {/* Skeleton for Galleries */}
                                {Array.from({ length: 5 }).map((c, index) => (
                                    <div key={index} className={`gallery ${Loading ? "skel" : ""}`}>
                                        <p className="skeleton text span"></p>
                                        <div className="list">
                                            {Array.from({ length: state ? 3 : 5 }).map(
                                                (c, index) => (
                                                    <div
                                                        key={index}
                                                        className="video_card skeleton"
                                                        style={dim}
                                                    ></div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                        {/* {Err && <h1 className="load_msg">An Error Occured</h1>} */}
                        {Data && (Data.data.length === 0 ? <h1>No Videos</h1> : <>{dataList}</>)}
                    </div>
                </div>
            </div>
            <Footer />
            {/* Modal Popup */}
            <CSSTransition in={openVideo} unmountOnExit classNames="video_popup" timeout={400}>
                <Video videodata={videodata} closeVideo={setOpenVideo} />
            </CSSTransition>
        </main>
    );
}

export default Home;

export function Footer() {
    return (
        <footer>
            <h5 dangerouslySetInnerHTML={{__html: CMS('copyright')}}></h5>
        </footer>
    );
}
