import React, { Children } from "react";
import { BiSearch, BiBasket, FiMenu } from "react-icons/bi";
import { TiTimes } from "react-icons/ti";
import { ReactComponent as searchIcon } from "../../../../img/AllIconsSvg/Search.svg";

import { ReactComponent as Shop } from "../../../../img/AllIconsSvg/Shop.svg";

import { IconWrapper } from "../../../Dashboard/Dashboard";

import withRouter from "../../../../utils/Hooks/withRouter";
import { toast } from "react-hot-toast";
import { slide as Menu } from "react-burger-menu";
import "./style.scss";
class TopCategoriesComponent extends React.PureComponent {
    state = {
        search: false,
        lastSearch: "",
        value: "",
        selectedCategory: null,
        categories: [],
        showMenu: false
    };
    constructor(props) {
        super(props)
        this.selectedCategory = null;
    }
    gotoCategory(t) {
        this.props.history.push(
            "/home/shop/category/" +
                t.name
                    .toLowerCase()
                    .replace(/ /g, "-")
                    .replace(/--/g, "-")
                    .replace(/[^\w-]+/g, "")
        );
        this.props.functions.setProducts(t);
    }
    renderItems(element) {
        return element.map((t) =>
            t.children !== undefined && t.children.length > 0 ? (
                <li key={t.id}>
                    <span onClick={() => this.gotoCategory(t)}>{t.name}</span>
                    <ul className="blakh">{this.renderItems(t.children)}</ul>
                </li>
            ) : (
                <li key={t.id}>
                    <span onClick={() => this.gotoCategory(t)}>{t.name}</span>
                </li>
            )
        );
    }
    
    renderItems2(ele) {
        const element = ele.children ?? ele;
        return <div>{element.map((t) =>
                <li onClick={() => { if(t.children !== undefined) this.setState({selectedCategory: t}); else { this.gotoCategory(t); this.props.functions.toggleMenu(false) } }} className={t.children !== undefined && t.children.length > 0 ? 'ble' : ''} key={t.id}>
                    <span>{t.name}</span>
                </li>)}
                {/* {ele !== element ? <button onClick={() => this.setState({selectedCategory: null})} className="endofcat">Go to Root Category</button> : null} */}
                </div>
    }
    
    localMap(node, cat, found) {
        if (node && !found.found) {
            if (node.id === cat.parent_id) {
                if (!node.children) node.children = [];
                node.children.unshift(cat);
                found.found = true;
            }
            if (node.children !== null && node.children !== undefined) node.children.forEach((t) => this.localMap(t, cat, found));
        }
    }
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
              if(location.pathname === '/home/shop/search') {
                    this.setState({selectedCategory : []});
              }
              else
                  this.setState({selectedCategory : null});
        });
    }
    goBack() {
        this.props.history.goBack();
        setTimeout(() => {
        if(window.location.pathname === '/home/shop')
            this.props.functions.setProducts(
                null,
                null,
                20,
                1,
                null
            );
        },100)

    }
    render() {
        const showMenu = this.props.functions.getMenu();
        const { selectedCategory } = this.state;
        const count = this.props.functions.getCartInfo().count;
        const categories = JSON.parse(JSON.stringify(this.props.functions.getCategories()));
        if (categories.length === 0) return <h1>Loading Categories</h1>;
        const root = [];
        let cat = categories.pop();
        let max = categories.length * categories.length;
        while (cat != null && --max > 0) {
            if (cat.parent_id === null) {
                root.unshift(cat);
            } else {
                let found = { found: false };
                root.forEach((t) => this.localMap(t, cat, found));
                if (!found.found) categories.unshift(cat);
            }
            cat = categories.pop();
        }
        if(selectedCategory === null) {
            this.setState({
                selectedCategory: root
            });
        }
        const { isMobile } = this.props;
        const isHome = window.location.pathname === '/home/shop';
        if (isMobile) {
            if(selectedCategory === null)
                return null;
            return (<div className="root-menu">
                {!isHome && window.location.pathname.indexOf('/home/shop/cart/checkout/success/') === -1 ? <button onClick={() => {this.goBack();}} className="top-la">{'<'}</button> : null}
                <ul className="top-layer">
                <li
                        className="button-layer"
                        onClick={() => {
                            this.props.history.push("/home/shop/cart");
                        }}
                    >
                        <BiBasket className="basket-icon" />
                        {count !== 0 ? <small className="item-count">{count}</small> : ""}
                    </li>
                </ul>
                <div className={"mobile-menu "+ (showMenu === true ? 'show' : '')}>
                {/* <span className="titlemenu" onClick={() => selectedCategory.name && this.gotoCategory(selectedCategory)}>{selectedCategory.name === null ? 'Menu' : selectedCategory.name + ' Category'}</span> */}

                <ul className="tlayer">
                {this.renderItems2(selectedCategory)}
                </ul>
                </div>
            </div>);
        }
        //
        return (
            <div className="top-categories">
                <ul className="top-layer">
                    <li className="category-tree">
                        {this.state.search === false ? (
                            <ul className="category-layer">
                                {this.renderItems(root)}
                                {/* { Children.toArray( root.map(t=> <li onClick={() => this.gotoCategory(t)} >{t.name}</li>) )} */}
                            </ul>
                        ) : (
                            <input
                                onChange={(e) => {
                                    if (window.location.pathname !== "/home/shop/search")
                                        this.props.history.push("/home/shop/search");
                                    this.props.functions.setProducts(
                                        null,
                                        null,
                                        20,
                                        1,
                                        e.target.value
                                    );
                                    this.state.value = e.target.value;
                                }}
                                type="text"
                                placeholder="Search here.."
                                className="search"
                            />
                        )}
                    </li>
                    <li className="button-layer">
                        {this.state.search === false ? (
                            <IconWrapper Icon={searchIcon}
                                    exe={() => {
                                    this.setState({ search: true });
                                }}
                                className="search-icon"
                            />
                        ) : (
                            <TiTimes
                                onClick={() => {
                                    this.setState({ search: false });
                                    if (
                                        this.state.value !== "" ||
                                        window.location.pathname === "/home/shop/search"
                                    ) {
                                        this.props.functions.setProducts();
                                        this.props.history.push("/home/shop");
                                    }
                                }}
                                className="search-icon"
                            />
                        )}
                    </li>
                    <li
                        className="button-layer"
                        onClick={() => {
                             this.props.history.push("/home/shop/cart");
                        }}
                    >
                        {/* <BiBasket className="basket-icon" /> */}
                        <IconWrapper Icon={Shop} className="basket-icon" />
                        {count !== 0 ? <small className="item-count">{count}</small> : ""}
                    </li>
                </ul>
            </div>
        );
    }
}
export default withRouter(TopCategoriesComponent);
