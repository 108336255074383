import { ReactComponent as star } from "../../img/AllIconsSvg/Models.svg";
import { useEffect, useRef, useState } from "react";
import { useRouteMatch, Switch, Route, Link, useHistory } from "react-router-dom";
import { useAPIFetch } from "../../utils/Hooks/useAPIFetch";
import { MdVideocam } from "react-icons/md";
import { gsap } from "gsap";
import { useWindowResize } from "../../utils/Hooks/useWindowResize";
import Thumb from "./Home/ReusableComponenets/Thumb";
import Video from "./Home/Video";
import Image from "./Home/ReusableComponenets/Image";
import { useDim, useFeat } from "../../utils/Contexts/Context";
import { CSSTransition } from "react-transition-group";
import { v4 } from "uuid";
import { IconWrapper } from "./Dashboard";
import './style.scss'
function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

function Models() {
    const match = useRouteMatch();
    const [Limit, setLimit] = useState(9);
    const [OrderBy, setOrderBy] = useState("asc");
    const [Data, Loading, Err] = useAPIFetch(
        `models?limit=${Limit}&skip=0&orderby=name,${OrderBy}`
    );
    var current_url = window.location.href.split('/');
    var details = false;
    if(current_url[current_url.length-1] !== "models") {
    details =
        !Loading &&
        !Err && (
            current_url[current_url.length-1].indexOf('preview') === -1 ? 
                <Route path={match.path + "/" + current_url[current_url.length-1]} key={current_url[current_url.length-1]}>
                    <ModelDetailPage model_id={current_url[current_url.length-1]} key={v4()} />
                </Route> : <Route path={match.path + "/preview"} key="9999">
                    <ModelDetailPagePreview model_id={findGetParameter("data")} key={v4()} />
                </Route>
        );
    }
    return (
        <div className="page" id="models">
            <Switch>
                <Route path={match.path} exact>
                    <MainPage
                        Data={Data}
                        Loading={Loading}
                        Err={Err}
                        setOrderBy={setOrderBy}
                        setLimit={setLimit}
                        Limit={Limit}
                        orderValue={OrderBy}
                        key={v4()}
                    />
                </Route>
                {details}
            </Switch>
        </div>
    );
}

export default Models;

export function Model({ model_id, imageUrl }) {
    return (
        <div className="thumb" onClick={() => {}}>
            <img src={imageUrl} alt="" />
        </div>
    );
}

export function MainPage({ Data, Loading, Err, setOrderBy, setLimit, Limit,orderValue }) {
    const match = useRouteMatch();
    const { dim } = useDim();
    return (
        <div className="container">
            <div className="header">
                <IconWrapper Icon={star} size={30} />
                <h1>Models</h1>
                <select
                    defaultValue={orderValue}
                    name="category"
                    id="category"
                    onChange={(e) => {
                        setOrderBy(e.target.value);
                    }}
                >
                    <option value="asc">A - Z</option>
                    <option value="desc">Z - A</option>
                </select>
            </div>
            <div className="grid">
                {Loading && (
                    <>
                        {Array.from({ length: Limit }).map((c) => (
                            <div style={dim} key={c} className="video_card skeleton"></div>
                        ))}
                    </>
                )}
                {Err && <h1>An Error Occured</h1>}
                {Data &&
                    (Data.data.length === 0 ? (
                        <h1>No Videos</h1>
                    ) : (
                        <>
                            {Data.data.map((t) => {
                                return (
                                    <Link to={match.url + "/" + t.id} key={t.id}>
                                        <Thumb type='model_avatar'  big={false} id={t.id} sub={t.name} imageUrl={t.avatar ?? ""} tag="model" />
                                    </Link>
                                );
                            })}
                        </>
                    ))}
            </div>
            {Data && (
                <div className="more">
                    <button
                        className="show_more"
                        disabled={Limit > Data.data.length}
                        onClick={() => setLimit(Limit + 10)}
                    >
                        {Limit > Data.data.length ? "No more to Show" : "Show more"}
                    </button>
                </div>
            )}
        </div>
    );
}

export function ModelDetailPage({ model_id }) {
    const state = useWindowResize();
    const [Limit, setLimit] = useState(10);
    const { dim } = useDim();
    const [Data, Loading, Err] = useAPIFetch(`models/${model_id}`, null, "GET", false);
    const [List, List_Loading, List_Err] = useAPIFetch(
        `model_videos_contents?model_id=${model_id}&limit=${Limit}&skip=0`,
        null,
        "GET",
        false
    );

    const h1 = useRef();
    const span = useRef();
    const { setId } = useFeat();
    const history = useHistory();
    const [videodata, setVideoData] = useState({
        url: "",
        id: "",
    });
    const [openVideo, setOpenVideo] = useState(false);
    useEffect(() => {
        if (Data) {
            const timeline = gsap.timeline();
            timeline
                .fromTo(h1.current, { opacity: 0, y: "100%" }, { opacity: 1, y: 0 })
                .fromTo(span.current, { opacity: 0, y: "100%" }, { opacity: 1, y: 0 });
        }
    }, [Data]);
    if (List !== null && List.data !== null && List.data.length === 1) {
        setId(List.data[0].id);
        history.push("/home/feature");
    }
    return (
        <div className="model_detail">
            {Err && <h1>An Error Occured</h1>}
            {Loading ? '' : <Image className="max-width" big={true} url='' type='model_cover' id={model_id} />}
            <div className="container">
                <div className="panel">
                    <div className="nav">
                        <div className="navitem">
                            <MdVideocam />
                            <span>Videos</span>
                        </div>
                    </div>
                    <div className="grid">
                        {List_Loading && (
                            <>
                                {Array.from({ length: 3 }).map((c) => (
                                    <div key={c} className="video_card skeleton" style={dim}></div>
                                ))}
                            </>
                        )}
                        {List_Err && <h1>An Error Occured</h1>}
                        {List &&
                            !Err &&
                            (List.data.length === 0 ? (
                                <h1>No Videos</h1>
                            ) : (
                                <>
                                    {List.data.map(
                                        ({
                                            id,
                                            photo_portrait,
                                            photo_landscape,
                                            title,
                                            cover_landscape,
                                            cover_portrait,
                                        }) => {
                                            return (
                                                <Thumb
                                                    key={id}
                                                    imageUrl={
                                                        state === true
                                                            ? photo_portrait
                                                            : state === false && photo_landscape
                                                    }
                                                    type='photo'
                                                    id={id}
                                                    sub={title}
                                                    videodata={id}
                                                    exe={() => {
                                                        if (state) {
                                                            setId(id);
                                                            history.push("/home/feature");
                                                        } else {
                                                            setVideoData({
                                                                id: id,
                                                                url: state
                                                                    ? cover_portrait
                                                                    : cover_landscape,
                                                            });
                                                            setOpenVideo(true);
                                                        }
                                                    }}
                                                />
                                            );
                                        }
                                    )}
                                </>
                            ))}
                    </div>
                    {List && (
                        <div className="more">
                            <button
                                className="show_more"
                                disabled={Limit > List.data.length}
                                onClick={() => setLimit(Limit + 10)}
                            >
                                {Limit > List.data.length ? "No more to Show" : "Show more"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <CSSTransition in={openVideo} unmountOnExit classNames="video_popup" timeout={400}>
                <Video videodata={videodata} closeVideo={setOpenVideo} />
            </CSSTransition>
        </div>
    );
}

export function ModelDetailPagePreview({ data }) {
    const state = useWindowResize();
    const [Limit, setLimit] = useState(10);
    const { dim } = useDim();
    var Data = {};
    data = JSON.parse(atob(data));
    var Data = {};
    Data.data = {};
    for (const key in data) {
        const element = data[key];
        Data.data[key] = atob(element);
    }
    const h1 = useRef();
    const span = useRef();
    const { setId } = useFeat();
    const history = useHistory();
    const [videodata, setVideoData] = useState({
        url: "",
        id: "",
    });
    const [openVideo, setOpenVideo] = useState(false);
    useEffect(() => {
        if (Data) {
            const timeline = gsap.timeline();
            timeline
                .fromTo(h1.current, { opacity: 0, y: "100%" }, { opacity: 1, y: 0 })
                .fromTo(span.current, { opacity: 0, y: "100%" }, { opacity: 1, y: 0 });
        }
    }, [Data]);
    return (
        <div className="model_detail">
            <CSSTransition in={openVideo} unmountOnExit classNames="video_popup" timeout={400}>
                <Video videodata={videodata} closeVideo={setOpenVideo} />
            </CSSTransition>
        </div>
    );
}
