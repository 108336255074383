import { useState } from "react";
import { useRouteMatch, Link, Route, Switch } from "react-router-dom";
import { useDim,useFeat } from "../../utils/Contexts/Context";
import { useAPIFetch } from "./../../utils/Hooks/useAPIFetch";
import Thumb from "./Home/ReusableComponenets/Thumb";
import { useWindowResize } from "./../../utils/Hooks/useWindowResize";
import { ModelDetailPage } from "./Models";
import { useHistory } from "react-router";
function Search() {
    const match = useRouteMatch();
    const state = useWindowResize();
    const { setId } = useFeat();

    const history = useHistory();
    const [SearchParam, setSearchParam] = useState(null);
    const { dim } = useDim();
    const [Data, Loading, Err] = useAPIFetch(
        `tags?skip=0&tag=${SearchParam === "" ? null : SearchParam}`,
        null,
        "GET",
        false
    );
    function handleChange({ target: { value } }) {
        setSearchParam(value);
    }
    const details =
        !Loading &&
        !Err &&
        Data.data.map((t) => {
            if (t.model) {
                const id = t.model.id;
                return (
                    <Route path={"/home/models/" + id} key={id} exact>
                        <ModelDetailPage model_id={id} />
                    </Route>
                );
            }
            return null;
        });
    return (
        <>
            <Switch>
                <Route path={match.path}>
                    <div className="search page" id="search">
                        <input
                            autoFocus
                            type="text"
                            onChange={handleChange}
                            placeholder="Enter Search Criteria..."
                        />
                        <div className="container search">
                            <div className="grid" style={{position: 'relative'}}>
                                {Loading && (
                                    <>
                                        {Array.from({ length: 5 }).map((c) => (
                                            <div
                                                key={c}
                                                className="video_card skeleton"
                                                style={dim}
                                            ></div>
                                        ))}
                                    </>
                                )}
                                {Err && <h1>An Error Occured</h1>}
                                {Data &&
                                    (Data.data.length === 0 ? (
                                        <h1 id="no-result">No Results</h1>
                                    ) : (
                                        <>
                                            {Data.data.map((t) => {
                                                console.log(t);
                                                return (
                                                        <Thumb
                                                            key={t.id}
                                                            imageUrl={
                                                                state === true
                                                                    ? t.photo_portrait
                                                                    : state === false && t.photo_landscape
                                                            }
                                                            type='photo'
                                                            videodata={t.id}
                                                            id={t.id}
                                                            sub={t.title}
                                                            tag="video"
                                                            exe={() => {
                                                                setId(t.id);
                                                                history.push("/home/feature");
                                                            }}
                                                        />
                                                );
                                                return null;
                                            })}
                                        </>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Route>
                {details}
            </Switch>
        </>
    );
}

export default Search;

export function Model({ model_id, imageUrl }) {
    return (
        <div className="thumb" onClick={() => {}}>
            <img src={imageUrl} alt="" />
        </div>
    );
}
