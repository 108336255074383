import { useEffect, useCallback, useState } from 'react';
import { useUser } from "../../../utils/Contexts/Context";

function uniqueId () {
    var idStrLen = 8;
    var idStr = (Math.floor((Math.random() * 25)) + 10).toString(36) + "_";
    idStr += (new Date()).getTime().toString(36) + "_";
    do {
        idStr += (Math.floor((Math.random() * 35))).toString(36);
    } while (idStr.length < idStrLen);

    return (idStr);
}

export function withHooksHOC(Component) {
    return function WrappedComponent(props) {
        const user = useUser().user;
        const [categories, setCategories] = useState([]);
        const [details, setDetails] = useState([]);
        const [products, setProducts] = useState(null);
        const [product, setProduct] = useState(null);
        const [menu, setMenu] = useState(false);
        const [name, setName] = useState('Home');
        const [pageNo, setPageNo] = useState(1);
        const [cart, setCart] = useState(localStorage.getItem('cart') !== null ? JSON.parse(localStorage.getItem('cart')) : []);
        const [wishList, setWishList] = useState(localStorage.getItem('wishlist') !== null ? JSON.parse(localStorage.getItem('wishlist')) : []);
        const fetchProduct = useCallback(async (category_id = null,product_id = null,pageSize = 20,page = 1,search = null) => {
            const response = await (await fetch(`https://romp.theromp.com/api/products?${category_id !== null ? 'category_id='+category_id+'&' : ''}${product_id !== null ? 'product_id='+product_id +'&': ''}${search !== null ? 'search='+search +'&': ''}${'pageSize='+pageSize+'&'}${'page='+page+'&'}`)).json()
            setProduct(response.data);
        });
        const fetchProducts = useCallback(async (category_id = null,product_id = null,pageSize = 20,page = 1,search = null) => {
            const response = await (await fetch(`https://romp.theromp.com/api/products?${category_id !== null ? 'category_id='+category_id+'&' : ''}${product_id !== null ? 'product_id='+product_id +'&': ''}${search !== null ? 'search='+search +'&': ''}${'pageSize='+pageSize+'&'}${'page='+page+'&'}`)).json()
            setProducts(response.data);
        });
        const fetchDetails = useCallback(async () => {
            setDetails((await (await fetch('https://romp.theromp.com/api/get_account_settings/'+user.user_id+'?access_token='+user.token)).json()));
        })
        const getCart = () => {
            if(localStorage.getItem('cart') === null) {
                localStorage.setItem('cart',JSON.stringify(cart));
            }
            return cart;
        };
        const getWishList = () => {
            if(localStorage.getItem('wishlist') === null) {
                localStorage.setItem('wishlist',JSON.stringify(wishList));
            }
            return wishList;
        };
        const updateWishList = (wishList) => {
            localStorage.setItem('wishlist',JSON.stringify(wishList));
            setWishList(prevCart => ([...wishList]));
        }

        const updateCart = (cart) => {
            localStorage.setItem('cart',JSON.stringify(cart));
            setCart(prevCart => ([...cart]));
            try {
                fetch(`https://romp.theromp.com/api/update-cart?cart=`+btoa(JSON.stringify(cart))+'&access_token='+user.token).then(() => {}).catch(() => {});
            } catch(ex) {
            }
        }
        const productExistsInWishList = (product) => {
            for (const item of getWishList()) {
                if(item.id === product.id)
                    return true;
            }
            return false;
        }
        useEffect(async () => {
            const data = (await (await fetch('https://romp.theromp.com/api/ecategories')).json()).data;
            localStorage.setItem('categories', JSON.stringify(data));
            setCategories(data);
            fetchDetails();
            fetchProducts();
        },[]);
        const functions = {
            productExistsInWishList: productExistsInWishList,
            getWishList: getWishList,
            addToWishList: function(product) {
                const wishlist = getWishList();
                if(!productExistsInWishList(product)){
                    wishlist.push(product);
                    updateWishList(wishlist);
                }
            },
            removeFromWishList: function(product) {
                const wishlist = getWishList();
                if(productExistsInWishList(product)){
                    wishlist.splice(wishlist.indexOf(product), 1);
                    updateWishList(wishlist)
                }
            },
            getCart: getCart,
            decrementFromCart: function(product) {
                const cart = getCart();
                var already = false;
                for(var i = 0; i < cart.length; i++) {
                    if(cart[i].id === product.id && cart[i].color === product.color && cart[i].size === product.size){
                        cart[i].quantity -= 1;
                        if(cart[i].quantity === 0) {
                            cart.splice(i,1);   
                        }
                        already = true;
                        break;
                    }
                }
                updateCart(cart);
            },
            addToCart: function(product) {
                const cart = getCart();
                var already = false;
                for(var i = 0; i < cart.length; i++) {
                    if(cart[i].id === product.id && cart[i].color === product.color && cart[i].size === product.size){
                        if(product.is_downloadable === 0)
                        cart[i].quantity += 1;
                        already = true;
                        break;
                    }
                }
                if(already === false) {
                    const id = uniqueId();
                    product.uid = id;
                    cart.push(product);
                }
                const wishlist = getWishList();
                if(productExistsInWishList(product)){
                    wishlist.splice(wishlist.indexOf(product), 1);
                    updateWishList(wishlist)
                }
                updateCart(cart);
            },
            getCartInfo: function() {
                var sum = 0;
                var items = 0;
                getCart().map(t => { sum += (parseFloat(t.price) * parseInt(t.quantity)); items +=  parseInt(t.quantity) });
                return {
                    'items' : cart,
                    'count' : items,
                    'total' : sum.toFixed(2),
                }
            },
            removeFromCart: function(product) {
                updateCart(getCart().filter(item => item.uid !== product.uid));
            },
            clearCart: function() {
                updateCart([]);
            },
            getCategories: function() {
                return categories;
            },
            setProduct: function(product_id) {
                setProduct(null);
                fetchProduct(null,product_id);
            },
            getProduct: function() {
                return product;
            },
            setProducts: function(category,product_id,pageSize = 20,page = 1,search) {
                if(search) {
                        setPageNo(1)
                        setProducts(null);
                        setName('Searching for: '+search+'...');
                        fetchProducts(category, product_id, pageSize = 20,page = 1,search);
                }
                else if(!category) {
                    setName('Home')
                    setPageNo(1)
                    setProducts(null);
                        fetchProducts(category, product_id, pageSize = 20,page = 1,search);
                }
                else {
                    setName(category.name);
                    setPageNo(1)
                    setProducts(null);
                        fetchProducts(category.id,product_id,pageSize = 20,page = 1,search);
                }
            },

            getProducts: function() {
                return products;
            },

            getPage: function() {
                return name;
            },

            setPage: function(value) {
                setName(value);
            },
            toggleMenu: function(value = null) {
                setMenu(value ?? !menu);
            },
            getMenu: function() {
                return menu;
            }
        }
        const isMobile = {
            android: (agent = navigator.userAgent) => /android/i.test(agent),
            blackBerry: (agent = navigator.userAgent) => /blackberry/i.test(agent),
            iOS: (agent = navigator.userAgent) => /iphone|ipod|ipad/i.test(agent),
            opera: (agent = navigator.userAgent) => /opera mini/i.test(agent),
            safari: (agent = navigator.userAgent) => /safari/i.test(agent) && !/chrome/i.test(agent),
            windows: (agent = navigator.userAgent) => /iemobile/i.test(agent),
            // iPad uses 810 so we need to handle that.
            any: () => window.matchMedia('(max-width: 810px)').matches,
            standaloneMode: () => window.matchMedia('(display-mode: standalone)').matches
        };
        return <Component isMobile={isMobile.any()} details={details} user={user} {...props} functions={functions}/>;
      }
};
