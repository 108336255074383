import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BiPencil, CgSpinnerTwoAlt } from "react-icons/all";
import { Route, Switch, useRouteMatch } from "react-router";
import { CSSTransition } from "react-transition-group";
import { v4 } from "uuid";
import pen from "../../../img/Dashboard/AccountIcons/payment edit icon@3x.png";
import { get_account_settings } from "../../../utils/consts";
import { useUser } from "../../../utils/Contexts/Context";
import { regFetch, useAPIFetch } from "../../../utils/Hooks/useAPIFetch";
import { Footer } from "../Home/Home";
import "../style.scss";
import AccountProfile from "./AccountProfile";
import Billing from "./Billing";
import Help from "./Help";
import Contact from "./Contact";
import Income from "./Income";
import NavBar from "./NavBar";
import Profile from "./Profile";
import Subscription from "./Subscription";
import { IconWrapper } from "../Dashboard";
import { ReactComponent as BackArrow } from "../../../img/AllIconsSvg/BackArrow.svg";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Popup from 'reactjs-popup';
import { GeoapifyGeocoderAutocomplete, GeoapifyContext } from '@geoapify/react-geocoder-autocomplete'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import 'reactjs-popup/dist/index.css';
import NiceInputPassword from 'react-nice-input-password';
import 'react-nice-input-password/dist/react-nice-input-password.css';
import Download from "./Download";
export const AccountSettingsContext = createContext({});
function Account() {
    const match = useRouteMatch();
    const [Nav, setNav] = useState(true);
    const {
        user: { user_id, token },
        refetch,
        setRefetch,
    } = useUser();
    const [DataDetails, setDataDetails] = useState({ user: null });
    const [chartType, setChartType] = useState("daily");
    const [ChartDetails, setChartDetails] = useState([]);
    // fetches on page load
    const [Data, Loading, Err] = useAPIFetch(
        user_id ? `${get_account_settings}/${user_id}` : null,
        null,
        "GET"
    );
    // fethes to update UI for the user (note it has refetch but no load)
    const [Saved_Data] = useAPIFetch(`get_account_settings/${user_id}`, null, "GET", false, refetch);
    if (Saved_Data !== null) {
        if (Saved_Data.user.current_plan === null) {
            
            window.location.href = "https://romp.theromp.com/step-2";
        }
    }
    const [chartData] = useAPIFetch(
        `user-income-chart?user_id=${user_id}&type=${chartType}`,
        null,
        "GET",
        false
    );

    useEffect(() => {
        chartData && setChartDetails(chartData);
    }, [chartData]);
    useEffect(() => {
        Data && setDataDetails(Data);
    }, [Data]);

    useEffect(() => {
        Saved_Data && setDataDetails(Saved_Data);
    }, [Saved_Data]);

    const a = {
        s: "Settings Saved!",
        e: "Failed to save Settings!",
    };
    async function UpdateUserSettings(data) {
        const result = await regFetch(
            `update_user?user_id=${user_id}`,
            { ...data },
            "PUT",
            token,
            true,
            a.s,
            a.e
        );
        result.status && setRefetch(v4());
    }

    const stripePromise = loadStripe("pk_test_kK8bMcJnJm9lBKYvvBRWAs7w");
    if(Data === null)
        return <h1>Loading...</h1>;
    return (
        <Elements stripe={stripePromise} options={{ clientSecret: Data.user.intent, theme: 'night' }}>
            <main id="account">
                <header>MY ACCOUNT</header>
                <div className="container">
                    {Loading ? (
                        <h1>Loading</h1>
                    ) : Err ? (
                        <h1>
                            An Error Occured While loading Account Details!{" "}
                            <span className="reload" onClick={() => setRefetch(v4())}>
                                Reload
                            </span>{" "}
                            to try again
                        </h1>
                    ) : (
                        <AccountSettingsContext.Provider
                            value={{
                                DataDetails,
                                UpdateUserSettings,
                                setRefetch,
                                ChartDetails,
                                setChartType,
                                chartType,
                            }}
                        >
                            <div className="panel_wrapper">
                                <div className="panel">
                                    <NavBar setNav={setNav} Data={Data} Nav={Nav} />
                                    <div className="view">
                                        <div className="backArrow">
                                            <IconWrapper
                                                Icon={BackArrow}
                                                exe={() => {
                                                    setNav(true);
                                                }}
                                                size={36}
                                            />
                                        </div>
                                        <Switch>
                                            <Route path={`${match.path}`} exact>
                                                <Profile />
                                            </Route>
                                            <Route path={`${match.path}/account`}>
                                                <AccountProfile />
                                            </Route>
                                            <Route path={`${match.path}/subscription`}>
                                                <Subscription />
                                            </Route>
                                            <Route path={`${match.path}/billing`}>
                                                <Billing />
                                            </Route>
                                            <Route path={`${match.path}/income`}>
                                                <Income />
                                            </Route>
                                            <Route path={`${match.path}/downloads`}>
                                                <Download />
                                            </Route>
                                            <Route path={`${match.path}/help`}>
                                                <Help />
                                            </Route>
                                            <Route path={`${match.path}/contact`}>
                                                <Contact />
                                            </Route>
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </AccountSettingsContext.Provider>
                    )}
                </div>
                <Footer />
            </main>
        </Elements>
    );
}

export default Account;

export function Pen() {
    return <img src={pen} alt="pen" className="pen" />;
}

export function ChangeAbleInput({ text, exe, type = "text", fieldName = "" }) {
    const [inputState] = useState(true);
    const [loading, setloading] = useState(false);
    const [text_input, setText_input] = useState(text);
    const [valid, setValid] = useState(false);
    var changeableInput = false;
    const checkPhoneError = function (phone) {
        changeableInput = phone.length >= 12 && phone.length <= 16;
        return changeableInput;
    } 
    const levelBarCss = (level) => ({
        height: "8px",
        width: level > 0 ? `${(100 / 4) * level}%` : "100%",
        marginTop: 16,
        transition: "width 0.5s ease",
        backgroundColor: ["#EFEFEF", "red", "orange", "yellow", "green"][level],
        borderRadius: 0
      });
      
      const CustomLevelBar = (levels) => <div style={levelBarCss(levels)} />;
      const securityLevels = [
        {
          descriptionLabel: <h4>1 number</h4>,
          validator: /.*[0-9].*/
        },
        {
          descriptionLabel: <h4>1 lowercase letter</h4>,
          validator: /.*[a-z].*/
        },
        {
          descriptionLabel: <h4>1 uppercase letter</h4>,
          validator: /.*[A-Z].*/
        },
        {
          descriptionLabel: <h4>6 of length</h4>,
          validator: /^.{6,}$/
        }
      ];
    const getField = function() {
        if(fieldName === 'address')
            return <GeoapifyContext apiKey="e9e060db041142d981bd3fc0b8e002b5">
            <GeoapifyGeocoderAutocomplete placeholder="Enter Your Address Here"
              value={text_input}
              type='amenity'
              placeSelect={(e) => { setText_input(e.properties.formatted);}}
            />
            </GeoapifyContext>;
        if(fieldName === "phone")
            return <PhoneInput
                countrySelectProps={{ unicodeFlags: true }}
                value={text_input}
                onChange={setText_input}
                error={text_input ? (checkPhoneError(text_input) ? undefined : 'Invalid phone number') : 'Phone number required'}
            />
        if(fieldName === "password")
            return <div className="form-pass">
            <NiceInputPassword
                label="Password"
                name="pass5"
                InputComponentProps={{
                    variant: "outlined",
                    InputProps: {
                    }
                }}
                showSecurityLevelBar
                showSecurityLevelDescription
                renderLevelBarComponent={CustomLevelBar}
                value={text_input}
                securityLevels={securityLevels}
                onChange={(t) => { setText_input(t.value); setValid(t.isValid); }}
            />
          </div>;
    }

    if (fieldName === "phone" || fieldName === "address" || fieldName === "password" )
        return (
            <div className="changableinput">
                <CSSTransition in={inputState} unmountOnExit timeout={400} classNames="text">
                    <div>
                        {text}
                        <Popup trigger={<BiPencil />} modal nested position="right center">
                            {close =>
                            (<div className="modal">
                                <div className="header"> {fieldName} Field Update </div>
                                <div className="content">
                                    {loading ? (
                                        <CgSpinnerTwoAlt className="like_loader" size={45} />
                                    ) : (
                                        <>
                                            <div>
                                                {getField()}
                                            </div>
                                            <div className="actions">
                                                <button className="button-yes" onClick={async () => {
                                                    if (text_input === undefined || text_input.trim() === "" || text_input.length <= 6 || (fieldName === "password" && !valid))
                                                        return toast.error("Invalid Value.");
                                                    setloading(true);
                                                    await exe(text_input);
                                                    setloading(false);
                                                    close();
                                                }}
                                                >
                                                    Confirm Update
                                                </button>
                                                <button className="button-cancel" onClick={() => {
                                                    close();
                                                }}>
                                                    Cancel Update</button>
                                            </div>
                                        </>

                                    )}
                                </div>

                            </div>
                            )}
                        </Popup>

                    </div>
                </CSSTransition>
            </div>
        );

    return (
        <div className="changableinput">
            <CSSTransition in={inputState} unmountOnExit timeout={400} classNames="text">
                <div>
                    {text}
                </div>
            </CSSTransition>
        </div>
    );

}
