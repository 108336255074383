import { useUser } from "../utils/Contexts/Context";
import { Redirect } from "react-router-dom";
function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}
function ProtectedRoute({ children }) {
    const { user } = useUser();
    const user_local = localStorage.getItem("therompmagazine_user_id");
    const referrer = findGetParameter('referrer');
    if(referrer)
        localStorage.setItem('referrer',referrer)
    if((user || user_local) && window.location.pathname === '/') {
        setTimeout(() => {
            window.location.reload();
        },20);
        return <Redirect to="/home" />;
    }
    if(window.location.pathname === '/login' && user)
        return <Redirect to="/home" />;
    if(window.location.pathname === '/login' && !user)
        return <Redirect to="/login" />;
    
    return user || user_local ? children : <Redirect to={referrer ? `/?referrer=${referrer}` : '/'}  />;
}

export default ProtectedRoute;
