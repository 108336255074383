import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { Route, Switch, useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Footer } from "./Home/Home";
import { ReactComponent as BackArrow } from "../../img/AllIconsSvg/BackArrow.svg";
import { Scrollbars } from 'react-custom-scrollbars-2';
import "./style.scss";
import { useUser } from "../../utils/Contexts/Context";
import { IconWrapper } from "./Dashboard";
import AES from "crypto-js/aes";

function Messages({ tabsList }) {
    const match = useRouteMatch();
    const history = useHistory();
    const [Nav, setNav] = useState(true);
    if(window.setNewMessageAlert)
        window.setNewMessageAlert(false);
    const [tabs, setTabs] = useState(tabsList);
    function handleSearch({ target: { value } }) {
        value === ""
            ? setTabs(tabsList)
            : setTabs(
                  tabs.filter(
                      (tab) =>
                          tab.subject.match(value) ||
                          tab.message.match(value) ||
                          tab.msg.match(value)
                  )
              );
    }
    function parseLink(msg) {
        const data = document.createElement('div');
        data.innerHTML = msg;
        const tags = data.getElementsByTagName('a');
        for(var i = 0; i < tags.length; i++) {
            if(tags[i].href.indexOf('video://') !== -1) {
                tags[i].href = "/home/player?type=interview&id="+AES.encrypt('xxxxxxxx'+tags[i].href.replace('video://',''),"TheRompMagazineElemental");
            }
        }
        return data.innerHTML;
    }
    if (window.argh === undefined) window.argh = {};
    return (
        <main id="account" className="messages">
            <div className="container">
                <div className="panel_wrapper">
                    <div className="panel">
                        <div className={`tabs ${Nav ? "open" : ""}`}>
                                    <Scrollbars
                                        className='romp-scroll'
                                    >
                            <div className="search_wrapper">
                                <div className="search">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        onChange={handleSearch}
                                    />
                                    <BiSearch />
                                </div>
                            </div>
                            <ul className="tabs_item">
                                {tabs.map(({ icon, message, subject, id, timestamp, seen_at }) => {
                                    window.argh[id] = seen_at ?? window.argh[id];
                                    return (
                                        <TabItem
                                            icon={'https://romp.theromp.com/gordon?asset='+icon.replace('http://romp.theromp.com/public','')}
                                            sub={message}
                                            text={subject}
                                            url={id}
                                            key={id}
                                            timestamp={timestamp}
                                            seen_at={seen_at}
                                            setNav={() => setNav(false)}
                                        />
                                    );
                                })}
                            </ul>
                            </Scrollbars>
                        </div>
                        <div className="view">
                            <div className="backArrow">
                                <div className="backArrow">
                                    <IconWrapper
                                        Icon={BackArrow}
                                        exe={() => {
                                            setNav(true);
                                        }}
                                        size={36}
                                    />
                                </div>
                            </div>
                            <Switch>
                                {tabs.map(({ id, subject, icon, msg }) => (
                                    <Route path={`${match.path}/${id}`} exact key={id}>
                                        <ViewWrapper key={id} header={subject} icon={icon}>
                                            <div className="messages">
                                                            <div className="message">
                                            <Scrollbars className='romp-scroll'>
                                                                <p
                                                                    className="bubble"
                                                                    dangerouslySetInnerHTML={{ __html: parseLink(msg) }}
                                                                ></p>
                                                </Scrollbars>
                                                            </div>
                                            </div>
                                        </ViewWrapper>
                                    </Route>
                                ))}
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main>
    );
}

export default Messages;
function TabItem({ url, icon, text, sub, setNav, timestamp }) {
    const {
        user: { user_id, token },
    } = useUser();

    const markSeen = async () => {
        if (!window.argh[url]) {
            window.argh[url] = new Date().getTime();
            await (
                await fetch("https://romp.theromp.com/api/mark-seen?message_id=" + url, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            ).json();
        }
    };
    const match = useRouteMatch();
    const [time, setTime] = useState("");
    useEffect(() => {
        setInterval(() => {
            setTime(timeSince(timestamp));
        }, 1000);

        return setTime(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <NavLink
            to={`${match.url}/${url}`}
            activeClassName="active"
            onClick={() => {
                markSeen();
                setNav();
            }}
            exact
        >
            <li className={window.argh[url] ? "seen" : "not_seen"}>
                <div className="icon" style={{backgroundImage : 'url('+icon+')'}}></div>
                <div className="text">
                    <div className="message_header">
                        <h4>{text}</h4>
                        <div className="timestamp">{time}</div>
                    </div>
                    <p  style={{height: "20px"}} dangerouslySetInnerHTML={{__html: sub}}></p>
                </div>
            </li>
        </NavLink>
    );
}
function timeSince(date) {
    //-35000000
    var seconds = Math.floor((new Date() - new Date((date*1000))) / 1000);
    var interval = seconds / 31536000;

    if (interval >= 1) {
        if (Math.floor(interval) === 1) return Math.floor(interval) + " yr ago";
        return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval >= 1) {
        if (Math.floor(interval) === 1) return Math.floor(interval) + " mnth ago";
        return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval >= 1) {
        if (Math.floor(interval) === 1) return Math.floor(interval) + " dy ago";
        return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval >= 1) {
        if (Math.floor(interval) === 1) return Math.floor(interval) + " hr ago";
        return Math.floor(interval) + " hrs ago";
    }
    interval = seconds / 60;
    if (interval >= 1) {
        if (Math.floor(interval) === 1) return Math.floor(interval) + " min ago";
        return Math.floor(interval) + " mins ago";
    }
    return Math.floor(seconds) + " secs ago";
}

function ViewWrapper({ icon, children, header, subHeader }) {
    return (
        <div className="view_wrapper">
            <div className="icon">
                <div className="icon_wrapper">{icon && <img src={'https://romp.theromp.com/gordon?asset='+icon.replace('http://romp.theromp.com/public','').replace('https://romp.theromp.com/public','')} alt="" />}</div>
                <h5>{header}</h5>
                <h6>{subHeader}</h6>
            </div>
            <div className="content">{children}</div>
        </div>
    );
}
