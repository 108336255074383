import { useDim, useFeat, useLikes, usePlay, useUser } from "../../../utils/Contexts/Context";
import { MdBookmarkBorder, CgSpinnerTwoAlt, MdBookmark } from "react-icons/all";
import { useEffect, useRef, useState } from "react";
import play from "../../../img/Dashboard/HomapageIcons/Triangle 1play @3x.png";
import more from "../../../img/Dashboard/HomapageIcons/more@3x.png";
import more_light from "../../../img/Dashboard/HomapageIcons/Moremore_light.png";
import { ReactComponent as MoreIcon } from "../../../img/AllIconsSvg/More.svg";

import { regFetch } from "../../../utils/Hooks/useAPIFetch";
import { useHistory } from "react-router";
import Image from "./ReusableComponenets/Image";
import { useWindowResize } from "../../../utils/Hooks/useWindowResize";
import { v4 } from "uuid";
import { ReactComponent as saved } from "../../../img/AllIconsSvg/Saved.svg";
import { ReactComponent as savedF } from "../../../img/AllIconsSvg/Saved-fill.svg";
import { IconWrapper } from "../Dashboard";
import { CSSTransition } from "react-transition-group";
import AES from "crypto-js/aes";

export default function FeaturedSlider({ cover_portrait, cover_landscape, id }) {
    const { setId } = useFeat();
    const history = useHistory();
    const likeRef = useRef();
    const {
        user: { user_id: user, token },
    } = useUser();
    const state = useWindowResize();
    const {
        Like_List: { data },
        set_like_list_refetch,
    } = useLikes();
    const { dim } = useDim();
    const { seVideotId } = usePlay();
    const props = {
        unmountOnExit: true,
        timeout: 400,
    };
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        data && setLoading(false);
        return () => {
            setLoading(false);
        };
    }, [data]);
    const like_List_extract = data && data.filter((dat) => dat.video_id === id);
    let liked =
        like_List_extract && like_List_extract.find((x) => x.video_id === id) ? true : false;

    async function like() {
        setLoading(true);
        const result = await regFetch(
            `user_likes`,
            { user_id: user, video_id: id },
            "POST",
            token,
            true,
            "Video Saved to your List!",
            "Failed to save Video"
        );
        result.success && set_like_list_refetch(v4());
        setLoading(false);
    }

    async function dislike() {
        setLoading(true);
        const result = await regFetch(
            `user_likes/${like_List_extract.filter((list) => list.video_id === id)[0].id}`,
            null,
            "DELETE",
            token,
            true,
            "Video removed from your List!",
            "Failed to remove Video"
        );
        result && result.success && set_like_list_refetch(v4());
        setLoading(false);
    }
    return (
        <>
            <Image type='cover' id={id} url={state ? cover_portrait : cover_landscape} big={true} />
            <div className="container">
                <div
                    className="control"
                    style={{
                        bottom: `calc(${dim.width} * .9)`,
                    }}
                >
                    <div
                        className="play"
                        onClick={() => {
                            seVideotId(id);
                            history.push("/home/player?id="+AES.encrypt('xxxxxxxx'+id,"TheRompMagazineElemental").toString());
                        }}
                    >
                        <img src={play} alt="play" className="icon" />
                        <p className="Play">Play</p>
                    </div>
                    <div
                        className="list"
                        onClick={async () => {
                            liked ? dislike() : like();
                        }}
                    >
                        <div className="bookmark" ref={likeRef}>
                            {loading && <CgSpinnerTwoAlt className="like_loader" size={45} />}
                            <CSSTransition
                                in={!loading && !liked}
                                {...props}
                                classNames="save_anim"
                                unmountOnExit
                            >
                                <IconWrapper className="bookmark-icon" Icon={saved} />
                            </CSSTransition>
                            <CSSTransition
                                in={!loading && liked}
                                {...props}
                                classNames="save_liked_anim"
                                unmountOnExit
                            >
                                <IconWrapper className="bookmark-icon icon-active" Icon={savedF} onClick={async () => {}} />
                            </CSSTransition>
                        </div>
                        <p>Save</p>
                    </div>
                    <div
                        className="more"
                        onClick={() => {
                            setId(id);
                            history.push("/home/feature");
                        }}
                    >
                        {!state ? (
                            <MoreIcon className="dark-icon" />
                        ) : (
                            <MoreIcon />
                        )}
                        <p>More</p>
                    </div>
                </div>
            </div>
        </>
    );
}
