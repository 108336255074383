import { Modal } from "../../../utils/CustomComponents";
import { useModal } from "../../../utils/Hooks/useModal";
import { ReactComponent as book } from "../../../img/AllIconsSvg/help.svg";
import ViewWrapper from "./ViewWrapper";
import { createBrowserHistory } from "history";
import Contact from "./Contact"
import CMS from '../../../utils/cms';

export default function Help() {
    const [faq, setfaq] = useModal(false);
    const [help, sethelp] = useModal(false);
    const [legal, setlegal] = useModal(false);
    const [contact, setcontact] = useModal(false);
    const history = createBrowserHistory();
    return (
        <ViewWrapper icon={book} header={"Help"}>
            <div className="help_content">
                <div className="grid">
                    <div className="row">
                        <div className="head col">Legal</div>
                        <div className="info col" onClick={() => setlegal()}>
                            View Privacy Policy
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">FAQ</div>
                        <div className="info col" onClick={() => setfaq()}>
                            View FAQs
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Help</div>
                        <div className="info col" onClick={() => sethelp()}>
                            View Help articles
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Contact</div>
                        <div className="info col" onClick={() => {
                            setcontact()
                        } }>Contact Us</div>
                    </div>
                </div>
            </div>
            <Modal header="Contact Us" setmodal={setcontact} state={contact}>
                    <Contact></Contact>
            </Modal>
            <Modal header="Legal Notices" setmodal={setlegal} state={legal}>
                <div dangerouslySetInnerHTML={{__html: CMS("privacy_statement")}}></div>
            </Modal>
            <Modal header="Frequently Asked Questions" setmodal={setfaq} state={faq}>
                <div dangerouslySetInnerHTML={{__html: CMS("faq")}}></div>
            </Modal>
            <Modal header="Help Articles" setmodal={sethelp} state={help}>
                <div dangerouslySetInnerHTML={{__html: CMS("help_articles")}}></div>
            </Modal>
        </ViewWrapper>
    );
}
