import React, { Children } from "react";
import { withRouter } from "react-router";
import { toast } from "react-hot-toast";

import './style.scss';

class CartView extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    addToCart(product) {
        console.log(product)
        const productStore = {
            'id' : product.id,
            'name' : product.name,
            'price' : product.special_price ?? product.price,
            'quantity' : 1,
            'gallery' : product.gallery[0]
        };
        if(product.size !== undefined  && product.size.length > 0) {
            if(product.selectedSize === null) {
                toast.error('Select a Size.');
                return;
            }
            productStore['size'] = product.selectedSize
        }
        if(product.color !== undefined && product.color.length > 0) {
            if(product.selectedColor === null) {
                toast.error('Select a Color.');
                return;
            }
            productStore['color'] = product.selectedColor
        }
        this.props.functions.addToCart(productStore);
        toast.success(`Added "${product.name}"" to Cart.`);
    }
    render() {
        const cartInfo = this.props.functions.getCartInfo();
        const { isMobile } = this.props;
        if(isMobile) {
            return (
                <div className="mobile-cart-view">
                    <div className="cart-header">
                        <h6 className="left">Shopping Cart</h6>
                        <h6 className="right">Total $ { cartInfo.total}</h6>
                    </div>
                    <div className="cart-body">
                    { cartInfo.items.map(t=>   
                        <div className="cart-product">
                            <div className="temp">
                                <img src={'https://romp.theromp.com/gordon?asset='+t.gallery.toString().replace('https://romp.theromp.com/public','')}/>
                            </div>
                            <div className="info">
                                <h2>{t.name}</h2>
                                <h2>{'Size: '+(t.size ?? 'Not Available')}</h2>
                                <h2>{'Color: '+(t.color ?? 'Not Available')}</h2>
                            </div>
                            <div className="options">
                                <h2 onClick={() => { this.props.functions.removeFromCart(t); }}>X</h2>
                                <h2></h2>
                                <h2>$ {t.price}</h2>
                            </div>
                        </div>
                    )}
                    </div>
                    <div className="cart-footer">
                    {this.props.functions.getCartInfo().count !== 0 ?
                        <button onClick={() => this.props.history.push('/home/shop/cart/checkout')}>Checkout</button>
                    : ''}
                    </div>
                    
                </div>
            );
        }
        return (
            <div className="cart-view">
                <h1 className="title">Shopping Cart</h1>
                <div className="flex heading">
                    <span className="flex-row">Item</span>
                    <span className="flex-row">Size</span>
                    <span className="flex-row">Colour</span>
                    <span className="flex-row">Quantity</span>
                    <span className="flex-row">Price</span>
                    <span className="flex-row cancel"></span>
                </div>
                { cartInfo.items.map(t=> 
                <div className="flex items">
                    <span className="flex-row">
                        <div class="pri">
                        <img src={'https://romp.theromp.com/gordon?asset='+t.gallery.toString().replace('https://romp.theromp.com/public','')}/>
                        <span>{t.name}</span>
                        </div>
                    </span>
                    <span className="flex-row">{t.size ?? 'Not Available'}</span>
                    <span className="flex-row">{t.color ?? 'Not Available'}</span>
                    <span className="flex-row">
                        <button className="le" onClick={() => {this.props.functions.decrementFromCart(t) }}>-</button>
                            {t.quantity}
                        <button onClick={() => {this.props.functions.addToCart(t) }}>+</button>
                        </span>
                    <span className="flex-row">$ {parseFloat(t.price).toFixed(2)}</span>
                    <span onClick={() => { this.props.functions.removeFromCart(t); }} className="flex-row cancel">X</span>
                </div>
                )}

                {/*  */}
                <div className="flex items bmp">
                    <span className="flex-row">
                        <div class="pri">
                        </div>
                    </span>
                    <span className="flex-row"></span>
                    <span className="flex-row"></span>
                    <span className="flex-row bmn"><span>Subtotal</span><br/><p>Taxes and shipping calculated at checkout</p></span>
                    <span className="flex-row bmz">$ { cartInfo.total}</span>
                    <span className="flex-row cancel"></span>
                </div>
                {/*  */}
                <div className="checkout">
                {this.props.functions.getCartInfo().count !== 0 ?
                <button onClick={() => this.props.history.push('/home/shop/cart/checkout')} className="checkout-button">CHECKOUT</button> : null}
                </div>
                <div className="wishlist">
                    <h1>Wishlist</h1>
                    { this.props.functions.getWishList().map(t=> 
                                <div className="flex items">
                                    <span className="flex-row">
                                        <div class="pri">
                                        <img src={'https://romp.theromp.com/gordon?asset='+t.gallery.toString().replace('https://romp.theromp.com/public','')}/>
                                        <span>{t.name}</span>
                                        </div>
                                    </span>
                                    <span className="flex-row">{t.size !== null ? <select defaultValue={'Please Select a size'}  onChange={(x) => {t.selectedSize = x.target.value}}>
                                        <option disabled>Please Select a size</option>
                                        {t.size.map((s) => <option>{s}</option>)}
                                        </select> : 'Not Available'}</span>
                                    <span className="flex-row">{t.color !== null ? <select defaultValue={'Please Select a color'}  onChange={(x) => {t.selectedColor = x.target.value}} >
                                        <option disabled>Please Select a colour</option>
                                            {t.color.map((s) => <option>{s}</option>)}
                                        </select> : 'Not Available'}</span>
                                    <span className="flex-row">
                                        <button className="le disabled" disabled onClick={() => { }}>-</button>
                                            {t.quantity ?? 0}
                                        <button onClick={() => { this.addToCart(t) }}>+</button>
                                        </span>
                                    <span className="flex-row">$ {t.price}</span>
                                    <span onClick={() => { this.props.functions.removeFromWishList(t); }} className="flex-row cancel">X</span>
                                </div>
                    )}
                </div>

            </div>
        );
    }
}
export default withRouter(CartView);