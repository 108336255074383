import vector from "../../img/Section1/vector1.png";
import CMS from '../../utils/cms';
function Section1() {
    return (
        <div id="section1">
            <div className="section1_wrapper container">
                <div className="col_1">
                    <h2 dangerouslySetInnerHTML={{__html: CMS("landing_page_heading_2")}}>
                    </h2>
                    <p dangerouslySetInnerHTML={{__html: CMS("landing_page_p3")}}>
                    </p>
                </div>
                <div className="col_2">
                    <div className="image">
                        <img src={'https://romp.theromp.com/gordon?asset='+CMS("landing_page_image1")} alt="screens" width={456} height={369} />
                    </div>
                    <p dangerouslySetInnerHTML={{__html: CMS("landing_page_p3")}}>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Section1;
