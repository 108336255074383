import play from "../../../img/Dashboard/HomapageIcons/Triangle 1play @3x.png";
import more from "../../../img/AllIconsSvg/View.svg";
import { MdClear } from "react-icons/all";
import { useFeat } from "../../../utils/Contexts/Context";
import { useHistory } from "react-router";
import { alterZoom } from "../../../utils/Tools/Tools";
import BookMark from "./ReusableComponenets/BookMark";
import Image from "./ReusableComponenets/Image";
import AES from "crypto-js/aes";

export default function Video({ videodata, closeVideo }) {
    const { setId } = useFeat();
    const history = useHistory();
    alterZoom(true);
    return (
        <div
            className="video"
            onClick={() => {
                closeVideo(false);
            }}
        >
            <MdClear
                className="close_video"
                onClick={() => {
                    closeVideo(false);
                }}
            />
            <div
                className="video_content"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Image type='cover' id={videodata.id} url={videodata.url} big={true} />
                <div className="control_wrapper">
                    <div
                        className="play control"
                        onClick={() => {
                            setId(videodata.id);
                            history.push("/home/player?id="+AES.encrypt('xxxxxxxx'+videodata.id,"TheRompMagazineElemental").toString());
                        }}
                    >
                        <img src={play} alt="play" className="icon" />
                        <span className="Play">Play</span>
                    </div>
                    <div
                        className="view play control"
                        onClick={() => {
                            setId(videodata.id);
                            history.push("/home/feature");
                        }}
                    >
                        <img src={more} alt="" className="icon" />
                        <span className="Play">View</span>
                    </div>
                    <BookMark id={videodata.id} />
                </div>
            </div>
        </div>
    );
}
