import { useState } from "react";

export function useModal(initialState) {
    const [toggle, setToggle] = useState(initialState || false);
    function toggleState() {
        setToggle(!toggle);
    }

    return [toggle, toggleState];
}
