import { useEffect, useState } from "react";
import { MdBookmarkBorder, CgSpinnerTwoAlt, MdBookmark } from "react-icons/all";
import { CSSTransition } from "react-transition-group";
import { useLikes, useUser } from "../../../../utils/Contexts/Context";
import { regFetch } from "../../../../utils/Hooks/useAPIFetch";
import { v4 } from "uuid";
import { ReactComponent as saved } from "../../../../img/AllIconsSvg/Saved.svg";
import { ReactComponent as savedF } from "../../../../img/AllIconsSvg/Saved-fill.svg";

import { IconWrapper } from "../../Dashboard";
import '../../../../Components/Dashboard/style.scss'
export default function BookMark({ id }) {
    const {
        user: { user_id: user, token },
    } = useUser();
    const {
        Like_List: { data },
        set_like_list_refetch,
    } = useLikes();
    const props = {
        unmountOnExit: true,
        timeout: 400,
    };
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        data && setLoading(false);
        return () => {
            setLoading(false);
        };
    }, [data]);
    const like_List_extract = data && data.filter((dat) => dat.video_id === id);
    return (
        <div className="bookmark">
            {loading && <CgSpinnerTwoAlt className="like_loader" size={45} />}
            {like_List_extract && (
                <>
                    <CSSTransition
                        in={
                            !loading &&
                            (!like_List_extract.find((x) => x.video_id === id) ? true : false)
                        }
                        {...props}
                        classNames="save_anim"
                        unmountOnExit
                    >
                        <IconWrapper className="bookmark-icon" Icon={saved}
                            exe={async () => {
                                setLoading(true);
                                const result = await regFetch(
                                    `user_likes`,
                                    { user_id: user, video_id: id },
                                    "POST",
                                    token,
                                    true,
                                    "Video Saved to your List!",
                                    "Failed to save Video"
                                );
                                result.success && set_like_list_refetch(v4());
                                setLoading(false);
                            }}
                        />
                    </CSSTransition>
                    <CSSTransition
                        in={
                            !loading &&
                            (like_List_extract.find((x) => x.video_id === id) ? true : false)
                        }
                        {...props}
                        classNames="save_liked_anim"
                        unmountOnExit
                    >
                        <IconWrapper Icon={savedF}  className="bookmark-icon active-icon"
                            exe={async () => {
                                setLoading(true);
                                const result = await regFetch(
                                    `user_likes/${
                                        like_List_extract.filter((list) => list.video_id === id)[0]
                                            .id
                                    }`,
                                    null,
                                    "DELETE",
                                    token,
                                    true,
                                    "Video removed from your List!",
                                    "Failed to remove Video"
                                );
                                result && result.success && set_like_list_refetch(v4());
                                setLoading(false);
                            }}
                        />
                    </CSSTransition>
                </>
            )}
        </div>
    );
}
