import CMS from '../../utils/cms';
// Client says the video might changes later,
//just put another video in img/Section2
// and update its url here👇🏻
function Section2() {
    const da = `
    <video 
        playsinline 
        loop
        autoPlay
        muted
        class="tv"
        >
    <source src=`+'https://romp.theromp.com/'+CMS("homepage_video")+` type="video/mp4"></source>
    </video>
`
    return (
        <div id="section2">
            <div className="section2_wrapper">
                <div dangerouslySetInnerHTML={{
                    __html:da
                }}></div>
                <img src={'https://romp.theromp.com/gordon?asset='+CMS("landing_page_image2")} alt="room" className="room" />
            </div>
        </div>
    );
}

export default Section2;
