import { MdFileDownload } from "react-icons/all";

import { useContext, useEffect, useState } from "react";
import { useModal } from "../../../utils/Hooks/useModal";
import { useWindowResize } from "../../../utils/Hooks/useWindowResize";
import { AccountSettingsContext } from "./Account";
import { useUser } from "../../../utils/Contexts/Context";
import ViewWrapper from "./ViewWrapper";

export default function Download() {
    const [downloads, setDownloads] = useState([{'name' : 'Fetching Downloads','attachment': null}]);
    const {
        user: { token },
    } = useUser();
    useEffect(async () => {
        const response = await (
            await fetch("https://romp.theromp.com/api/get-user-downloads", {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
        ).json();
        if(response.length !== 0)
            setDownloads(response)
        else
            setDownloads([{'name' : 'No Downloads', 'attachment' : null}])
    },[])
    return (
        <ViewWrapper
            icon={MdFileDownload}
            header={"Downloads"}
            subHeader={
                "Download your purchased items here"
            }
        >
            <div className="icome_content">
                <div className="grid downloads">
                {downloads.map(t=> <div className="row" style={{gridTemplateColumns: '65% 20%', display: 'block', alignItems: 'center',width: '100%'}}><span style={{textAlign: 'left','float': 'left'}}>{t.name}</span> {t.attachment !== null ? <a style={{float:'right', marginTop:'-5px', 'width': '250px', textAlign: 'center'}} className="btn" target='_blank' href={t.attachment}>Download</a> : <span>No Attachment</span>}</div>)}
                </div>
            </div>
        </ViewWrapper>
    );
}
