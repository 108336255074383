import React from "react";
import TopCategoriesComponent from '../TopCategoriesComponent/TopCategoriesComponent';
import ProductListing from "../ProductListing/ProductListing";
import Product from "../Product/Product";
import { withRouter } from "react-router";
import './style.scss';
import { Switch, Route } from "react-router";
import { withHooksHOC } from '../../util/withHooksHOC';
import CartView from "../CartView/CartView";
import Checkout from "../Checkout/Checkout";
import CheckoutSuccess from "../CheckoutSuccess/CheckoutSuccess";
class ShopComponent extends React.PureComponent {
    render() {
        return (
            <div id="videos" className="page">
                <div className="container">
                    <hr className="hr"/>
                    <span className="shop" onClick={() => {
                        this.props.functions.toggleMenu(false);
                        this.props.history.push('/home/shop');
                        this.props.functions.setProducts();
                    }}>The Shop</span>
                    <TopCategoriesComponent { ...this.props } />
                    <Switch>
                        <Route path={'/home/shop'} exact>
                            <ProductListing { ...this.props } />
                        </Route>
                        <Route path={'/home/shop/search'} exact>
                            <ProductListing { ...this.props } />
                        </Route>
                        <Route path={'/home/shop/category/:categoryId'}>
                            <ProductListing { ...this.props } />
                        </Route>
                        <Route path={"/home/shop/product/:id"} >
                            <Product { ...this.props } />
                        </Route>
                        <Route path={"/home/shop/cart"} exact>
                            <CartView { ...this.props } />
                        </Route>
                        <Route path={"/home/shop/cart/checkout"} exact>
                            <Checkout { ...this.props }  />
                        </Route>
                        <Route path={"/home/shop/cart/checkout/success/:id"} exact>
                            <CheckoutSuccess { ...this.props }  />
                        </Route>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default withRouter(withHooksHOC(ShopComponent));