import { MdAdd, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { EmailForm } from "./Hero";
import { useAPIFetch } from "../../utils/Hooks/useAPIFetch";
import { useEffect, useState } from "react";
import StarRating from "react-star-ratings";
import ReactPaginate from "react-paginate";
import Collapse, { Panel } from "rc-collapse";
import CMS from '../../utils/cms';
import "rc-collapse/assets/index.css";

function Faq() {
    const faqs = [
        {
            q: "What is the romp magazine? ",
            a: `The Romp Magazine was established in 2013 as an international lifestyle magazine for men. 
Today we are proud to announce The Romp magazine is now a streaming service that offers featured models videos and images that we originally published in our magazine now streamed straight to you on any device 24/7.
`,
        },
        {
            q: "What do I get becoming a member?",
            a: `When you subscribe to our membership you will have access to all our featured model videos and photoshoot images, also interviews and behind the scenes footage. You will also have the latest up to date access to all The Romp Magazine content streamed right to your device 24/7. You also get access to our exclusive store where you can purchase limited edition collector items.`,
        },
        {
            q: "How often is content added?",
            a: "We publish brand new model feature videos, images and interviews every day!",
        },
        {
            q: "What platforms can I view The Romp Magazine on?",
            a: "You can view The Romp Magazine on your desktop, mobile device and Tv devices. We are available on smart devices. ",
        },
    ];
    const [Reviews, Loading, Err] = useAPIFetch(
        `landing_page_reviews`,
        null,
        "GET",
        false,
        null,
        false
    );
    const [AllPages, setAllPages] = useState([]);
    const [ReviewList, setReviewList] = useState([]);
    const faqJSX = faqs.map(({ q, a }, index) => {
        return (
            <Panel
                key={index}
                header={
                    <p>
                        {q} <MdAdd />
                    </p>
                }
                headerClass="my-header-class"
            >
                <p>{a}</p>
            </Panel>
        );
    });

    useEffect(() => {
        if (Reviews) {
            setAllPages(Reviews.data);
            setReviewList(Reviews.data.slice(0, 5));
        }
    }, [Reviews]);

    function filterPage(e) {
        let start = Math.floor(AllPages.length / 5) * e.selected,
            end = start + 5;
        setReviewList(AllPages.slice(start, end));
    }
    return (
        <div id="faq">
            <div className="faq_wrapper container">
                <h2 dangerouslySetInnerHTML={{__html: CMS('landing_page_frequently_asked_question')}}></h2>
                <ul>
                    <Collapse>
                        {faqJSX}
                        <Panel
                            forceRender={true}
                            header={
                                    <p>
                                        <div className="customer-review-block">
                                            <span>
                                            Customer Reviews
                                            </span>
                                            <small className="overall-rating" style={{float: 'right',marginRight: '1.5em', fontSize: '0.7em'}}>
                                                {<StarRating rating={Reviews ? Reviews.data.reduce((total, obj) => obj.stars + total,0) / Reviews.data.length : 0} starRatedColor="#FFDF00" numberOfStars={5} starDimension={"18px"} starSpacing={"0px"} name="rating"/> } Based on {Reviews ? Reviews.data.length : 0} reviews
                                            </small>
                                        </div>
                                    <MdAdd />
                                    </p>
                            }
                            headerClass="my-header-class"
                        >
                            <>
                                <div className="reviews">
                                    {Err && (
                                        <p style={{ textAlign: "center" }}>Error loading reviews</p>
                                    )}
                                    {!Loading && Reviews && Reviews.length === 0 && (
                                        <p style={{ textAlign: "center" }}>No Reviews Yet</p>
                                    )}
                                    {ReviewList.map(({ id, name, text, stars }) => (
                                        <li key={id}>
                                            <h4>{name}</h4>
                                            <p>{text}</p>
                                            <StarRating
                                                rating={stars}
                                                starRatedColor="#FFDF00"
                                                numberOfStars={5}
                                                starDimension={"18px"}
                                                starSpacing={"0px"}
                                                name="rating"
                                            />
                                        </li>
                                    ))}
                                </div>
                                {Reviews && (
                                    <div className="pagination">
                                        <ReactPaginate
                                            previousLabel={<MdKeyboardArrowLeft size={20} />}
                                            nextLabel={<MdKeyboardArrowRight size={20} />}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            pageCount={Reviews.data.length / 5}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={filterPage}
                                            containerClassName={"pagination"}
                                            activeClassName={"active"}
                                        />
                                    </div>
                                )}
                            </>
                        </Panel>
                    </Collapse>
                </ul>
                <p dangerouslySetInnerHTML={{__html: CMS("landing_page_p2")}}>                   
                </p>
                <EmailForm />
            </div>
        </div>
    );
}

export default Faq;
