import React, { useEffect } from "react";
import NavBar from "./Components/HomePage/NavBar";
import Hero from "./Components/HomePage/Hero";
import Section1 from "./Components/HomePage/Section1";
import Section2 from "./Components/HomePage/Section2";
import Faq from "./Components/HomePage/Faq";
import { Router, Route, useLocation } from "react-router-dom";
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import SimpleReactLightbox from "simple-react-lightbox";

import {
    FeatureDataProvider,
    GlobalDimensionProvider,
    LikeListProvider,
    PlayerDataProvider,
    ThemeModeProvider,
    UserProvider,
} from "./utils/Contexts/Context";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./Components/ProtectedRoute";
import { useNavigatorOnLine } from "./utils/Hooks/useAPIFetch";
import { alterZoom } from "./utils/Tools/Tools";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
function App() {
    useNavigatorOnLine(); //Alerts when user goes offline and online
    alterZoom(); // disables zoom or enables
    const history = createBrowserHistory();
    const TRACKING_ID = "UA-71951303-1";
    ReactGA.initialize(TRACKING_ID);
    history.listen((location, action) => {
        ReactGA.pageview(location.pathname + location.search)
    })
    return (
        <SimpleReactLightbox>
            <Router history={history} basename={process.env.PUBLIC_URL}>
                <UserProvider>
                    <ThemeModeProvider>
                        <ProtectedRoute>
                            <Route path="/home">
                                <GlobalDimensionProvider>
                                    <FeatureDataProvider>
                                        <PlayerDataProvider>
                                            <LikeListProvider>
                                                <Dashboard />
                                            </LikeListProvider>
                                        </PlayerDataProvider>
                                    </FeatureDataProvider>
                                </GlobalDimensionProvider>
                            </Route>
                        </ProtectedRoute>
                    </ThemeModeProvider>
                    <Route path="/login" exact>
                        <Login />
                    </Route>
                    <Route path="/" exact>
                        <div className="App">
                            <NavBar />
                            <Hero />
                            <Section1 />
                            <Section2 />
                            <Faq />
                        </div>
                    </Route>
                </UserProvider>
                <ScrollTop />
            </Router>
            <Toaster containerClassName="toast" />
        </SimpleReactLightbox>
    );
}

export default App;

// Scrolls to top n page route
function ScrollTop() {
    const path = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [path.pathname]);
    return null;
}
