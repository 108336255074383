import { useEffect, useState, Children } from "react";
import { useHistory } from "react-router";
import { useUser } from "../../utils/Contexts/Context";
import { useAPIFetch } from "../../utils/Hooks/useAPIFetch";
import Image from '../Dashboard/Home/ReusableComponenets/Image'
import AES from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
import {
    FaArrowLeft,
} from "react-icons/fa";
import { FiX } from "react-icons/fi";
async function enterFullscreen(elem) { 
    elem = elem || document;
    if(elem.requestFullscreen)
        return elem.requestFullscreen();
    else if(elem.mozRequestFullScreen)
        return elem.mozRequestFullScreen();
    else if(elem.webkitRequestFullScreen)
        return elem.webkitRequestFullScreen();
    else if(elem.msRequestFullscreen)
        return elem.msRequestFullscreen();
    else
        elem.webkitEnterFullscreen()
}
async function exitFullscreen(elem) {
    elem = elem || document;
    if (elem.cancelFullScreen) {
        return elem.cancelFullScreen();
    } else if (elem.mozCancelFullScreen) {
        return elem.mozCancelFullScreen();
    } else if (elem.webkitCancelFullScreen) {
        return elem.webkitCancelFullScreen();
    } else if (elem.webkitExitFullScreen) {
        return elem.webkitExitFullScreen()
    }
}
function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] == parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

function Player({ navRef }) {
    const timeout = 10;
    const history = useHistory();
    var video_id = null;
    const data = AES.decrypt(findGetParameter('id'), "TheRompMagazineElemental").toString(enc);
    video_id = data.replace('xxxxxxxx','');
    if(video_id == undefined || video_id == null || video_id == "null")
        history.goBack();
        const {
            user: { user_id,token },
            refetch,
        } = useUser();
    
    const [Saved_Data] = useAPIFetch(`get_account_settings/${user_id}`, null, "GET", false, refetch);
    if (Saved_Data != null) {
        if (Saved_Data.user.current_plan == null) {
            window.location.href = "https://romp.theromp.com/step-2";
        }
        window.autoplay = Saved_Data.user.settings.auto_play;
    }
    const [info, setInfo] = useState({
        'title' : '',
        'subtitle' : '',
        'src' : '', 
        'next': []
    });
    const [ended, setEnded] = useState(false);
    const [uri, setUri] = useState("");
    const [counters, setCounters] = useState(timeout);
    const fetchVideo = async () => {
        const response = await (await fetch('https://romp.theromp.com/api/get-video/'+video_id,{headers: {Authorization: `Bearer ${token}`,},method: "GET",})).json()
        if(response.data != undefined && response.data.includes('video not yet')) {
            history.goBack();
        } else  {
            // setTimeout(() => {
            //     // var player = dashjs.MediaPlayer().create();
            //     // player.setAutoPlay(true);
            //     // player.initialize(document.getElementsByTagName('video')[0], response.current_video.video_url.replace('video.m3u8','video.mpd') , true);
            //     // setTimeout(() => {
            //     // player.setQualityFor('video',4);
            //     // },2000)

            //     // var hls = new Hls();
            //     // hls.attachMedia(document.getElementsByTagName('video')[0]);
            //     // hls.loadSource(response.current_video.video_url);
            // }, 1000);
                window.capture = 0;
                setUri(response.current_video.video_url.split('/')[3]);
                setInfo({
                    'title' : response.video_info.title,
                    'subtitle' : response.video_info.information,
                    'src' : response.current_video.video_url,
                    'video_id' : video_id,
                    'next': response.related.map(function(t) { 
                        return {
                        name: t.title,
                        id: t.id,
                        playing: window.id = (window.id ?? 1) + 1 ? true : false,
                        info: t
                    }})
                });
        }
    };
    
    var is_interview_link = findGetParameter('type') == "interview";
    
    useEffect(() => {
        if(!is_interview_link)
            fetchVideo();
    },[]);
    if(window.counter == null)
            window.counter = timeout;
        var interval = -1;
        const update_video_id = function(id) {
            window.counter = timeout;
            video_id = id;
            const times = setInterval(() => {},100000);
            for(var i = 0; i < times; i++) clearInterval(i);
            setEnded(false); 
            fetchVideo().then(() => {;
                setCounters(timeout);
            })
        }
        
        // alert(document.querySelector('iframe').contentWindow)
        var mql = window.matchMedia("(orientation: portrait)");
        mql.onchange = (e) => {
            if(e.matches){
                var iframe = document.querySelector('iframe');
                enterFullscreen().catch(() => {});
                enterFullscreen(iframe).catch(() => {});
            }
        }
        window.addEventListener("message", (msg) => {
            if(msg.data == 'started')  {
                if(ended == true) {
                    setEnded(false);
                    setCounters(timeout);
                    clearInterval(interval);
                }
            }
            else if(msg.data == 'fullscreen') { 
                var iframe = document.querySelector('iframe');
                enterFullscreen().catch(() => {
                });
                enterFullscreen(iframe).catch(() => {
                });
            }
            else if(msg.data == 'exitfullscreen') { 
                var iframex = document.querySelector('iframe');
                exitFullscreen(iframex).catch(() => {
                });;
                exitFullscreen().catch(() => {
                });;
            }
            else if(msg.data == 'ended') {
                if(is_interview_link == true) { 
                    var a = -1
                    a = setInterval(() => {
                        if(window.location.href.indexOf("/player") != -1)
                            history.goBack()
                        else {
                            clearTimeout(a);
                            window.location.reload()
                        }
                    },200)
                } else {
                setEnded(true);
                var empty = setInterval(() => { },1000);
                for(var i = 0; i <= empty; i++) clearInterval(i);
                    interval = setInterval(() => {
                        if(window.counter >= 0) {
                            setCounters(window.counter); window.counter -= 1;
                        }
                        else if(window.counter <= 0) {
                            history.push("/home/player?id="+AES.encrypt('xxxxxxxx'+info.next[0].info.id,"TheRompMagazineElemental").toString());
                            update_video_id(info.next[0].info.id);
                            // lock.release();
                        }
                    },1000)
                }
            }
        })
    if(is_interview_link == true) {
        if(video_id == "null") {
            history.goBack();
            return <h1>Interview Video Does not Exist Yet.</h1>
        } else {
        // video_id = 'https://vz-a34fcbfc-43e.b-cdn.net/'+video_id+'/playlist.m3u8'
        return (
            <div className="main-player" >
                <div className="back">
                        <div onClick={() => { history.goBack() }} style={{ cursor: "pointer" }}>
                            <FaArrowLeft />
                            <span>Go Back</span>
                        </div>
                        <div className="exit-video">
                        <FiX onClick={() => {
                            var a = -1
                            a = setInterval(() => {
                                if(window.location.href.indexOf("/player") != -1)
                                    history.goBack()
                                else {
                                    clearTimeout(a);
                                    window.location.reload()
                                }
                            },200)
                        }} />
                        </div>
                    </div>
                <div  dangerouslySetInnerHTML={{__html: '<iframe class="plyr__video-embed" id="video-player-iframe" src="https://iframe.mediadelivery.net/embed/20584/'+video_id+'?autoplay=true&access_token='+token+'" loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; playsinline; picture-in-picture; encrypted-media; vr; autoplay;" autoplay allowFullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allowtransparency></iframe>'}}/>
            </div>
        )
    
        }
    }
    if(info.title == '' || uri == '')
        return (
            <h1>Loading Video Player.</h1>
        );
        // new Function ('browser.permissions.request(permissionsToRequest).then(onResponse).then((currentPermissions) => {console.log(`Current permissions:`, currentPermissions);})')();
        
    function get_related() {
        // 
        if(ended == true)
        return <div className="related-videos">
                { Children.toArray(info.next.map(t=> <div className="thumb"><Image type='photo' id={t.info.id} big={false} exe={() => { history.push("/home/player?id="+AES.encrypt('xxxxxxxx'+t.info.id,"TheRompMagazineElemental").toString());
             update_video_id(t.info.id); }} className="related-video-photo" /></div> ))}
        </div>

    }
    window.enterFullscreen = enterFullscreen;
    return (
        <div className={"background-block "+(ended == true ? 'ended-parent' : '')} style={{background: `url('https://romp.theromp.com/gorgon?height=${window.screen.height}&width=${window.screen.width}&type=cover&w=true&id=${video_id}&access_token=${token}')`}}>
        {/*  */}
        <div className={"main-player "+(ended == true ? 'ended' : '')}>
        <div className="back">
                <div  onClick={() => { history.goBack() }} style={{ cursor: "pointer" }}>
                    <FaArrowLeft />
                    <span>Go Back</span>
                </div>
                <div className="exit-video">
                <FiX onClick={() => {
                    var a = -1
                    a = setInterval(() => {
                        if(window.location.href.indexOf("/player") != -1)
                            history.goBack()
                        else {
                            clearTimeout(a);
                            window.location.reload()
                        }
                    },200)
                }} />
                </div>
            </div>
            {/* <div dangerouslySetInnerHTML={{__html: `<video style="z-index:9999999 !important;position:fixed;" autoplay muted onplay="var localplayer = this;var mql = window.matchMedia('(orientation: portrait)');mql.onchange = (e) => {if(e.matches){window.enterFullscreen(localplayer).catch(() => {});}}"  width="400" controls><source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4"><source src="https://www.w3schools.com/html/mov_bbb.ogg" type="video/ogg">Your browser does not support HTML video.</video>`}} /> */}
            <div dangerouslySetInnerHTML={{__html: `<iframe class="plyr__video-embed" id="video-player-iframe" src="https://iframe.mediadelivery.net/embed/20584/`+uri+`?autoplay=true&access_token=`+token+`&vid=`+info.video_id+`"  loading="lazy" style="border: none; position: absolute; top: 0; height: 100%; width: 100%;" loading="lazy"  frameborder="2" allow="fullscreen; accelerometer; gyroscope; autoplay; playsinline; picture-in-picture; encrypted-media; autoplay; playsinline;" playsInline autoplay="autoplay" allowfullscreen="allowfullscreen" webkitallowfullscreen mozallowfullscreen oallowfullscreen msallowfullscreen></iframe>`}}/>
        </div>
        {get_related()}
        {ended && window.autoplay == true ? 
                <div className="timer">
                    <h3>Next Video will start in.</h3>
                    <h3>{counters}</h3>
                </div>
                :
                ended == true ? 
                <div className="timer">
                    <h3>Choose a Next Video.</h3>
                </div> : ''
            }
        </div>
    )
}

export default Player;