import { useStripe, useElements, PaymentElement, PaymentRequestButtonElement , CardNumberElement} from '@stripe/react-stripe-js';
import { useContext, useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useUser } from "../../../utils/Contexts/Context";
import { useModal } from "../../../utils/Hooks/useModal";
import { AccountSettingsContext, Pen } from "./Account";
import Select, { components } from "react-select";
import { ReactComponent as card } from "../../../img/AllIconsSvg/Billing.svg";
import { Modal } from "../../../utils/CustomComponents";
import { MdClear } from "react-icons/md";
import ViewWrapper from "./ViewWrapper";
import { BiPencil, CgSpinnerTwoAlt, MdCheck } from "react-icons/all";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './style.scss';

const { Option } = components;

export default function Billing() {
    const [billingHistory, setBillingHistory] = useModal(false);
    const [change, setChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const number = useRef(null);
    const exp = useRef(null);
    const cvv = useRef(null);
    const {
        user: { user_id, token },
    } = useUser();

    const {
        DataDetails: { user },
    } = useContext(AccountSettingsContext);
    const getIcon = function(payment) {
        const parts = payment.split(' ');
        const type = parts[parts.length-1];
        switch (type) {
            case 'visa':
                return '<i id="brand-icon" class="pf pf-visa"></i>';
            case 'mastercard':
                return '<i id="brand-icon" class="pf pf-mastercard"></i>';
            case 'amex':
                return '<i id="brand-icon" class="pf pf-american-express"></i>';
            case 'discover':
                return '<i id="brand-icon" class="pf pf-discover></i>';
            default:
                return type;
        }
    }
    const transform = function(payment) {
        try {
            const parts = payment.split(' ');
            var final_string = '';
            for(var i = 0; i < parts.length-1; i++)
                final_string += parts[i] +' ';
            return final_string + getIcon(payment);
        }
        catch {
            return payment;
        }
    }
    const IconOption = props => (
        <Option {...props}>
          <span dangerouslySetInnerHTML={{__html: transform(props.data.label)}}></span>
        </Option>
      );
      
    const options = user.list_cards.map((t) => {
        return {
            value: t.id,
            label: t.card,
            isSelected: user.credit_info,
            icon: getIcon(t.card)
        };
    });
    const getPaymentInfo = function (intentId) {
        for (var i = 0; i < options.length; i++) {
            if (options[i].value === intentId) return options[i];
        }
        return { value: "", label: "No Payment Information" };
    };
    const changePaymentMethod = async function (option) {
        setLoading(true);

        await (
            await fetch("https://romp.theromp.com/api/default-card", {
                method: "POST",
                body: JSON.stringify({
                    intent: `${option.value}`,
                }),
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
        ).json();
        setLoading(false);

        toast.success("Updated Payment Info!");
        window.location.reload();
    };
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (!elements || !number.current || !exp.current || !cvv.current)
            return;
        var style = {
            base: {
                iconColor: '#666EE8',
                color: '#31325F',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: 'Helvetica Neue',
                fontSize: '15px',

                '::placeholder': {
                    color: '#CFD7E0',
                },
            },
        };
        var cardBrandToPfClass = {
            'visa': 'pf-visa',
            'mastercard': 'pf-mastercard',
            'amex': 'pf-american-express',
            'discover': 'pf-discover',
            'diners': 'pf-diners',
            'jcb': 'pf-jcb',
            'unknown': 'pf-credit-card',
        }
        var set = 0;
        if(window.card){
        
            window.card['a']?.mount(number.current);
            window.card['b']?.mount(exp.current);
            window.card['c']?.mount(cvv.current);
            setBrandIcon('unknown');
            return;
        }
        
        var cardNumberElement = elements?.create('cardNumber', {
            style: style
        });
        window.card = {};
        cardNumberElement?.mount(number.current);

        var cardExpiryElement = elements?.create('cardExpiry', {
            style: style
        });
        cardExpiryElement?.mount(exp.current);

        var cardCvcElement = elements?.create('cardCvc', {
            style: style
        });
        cardCvcElement?.mount(cvv.current);
        window.card['a'] = cardNumberElement;
        window.card['b'] = cardExpiryElement;
        window.card['c'] = cardCvcElement;

        function setBrandIcon(brand) {
            var brandIconElement = document.getElementById('brand-icon');
            var pfClass = 'pf-credit-card';
            if (brand in cardBrandToPfClass) {
                pfClass = cardBrandToPfClass[brand];
            }
            for (var i = brandIconElement.classList.length - 1; i >= 0; i--) {
                brandIconElement.classList.remove(brandIconElement.classList[i]);
            }
            brandIconElement.classList.add('pf');
            brandIconElement.classList.add(pfClass);
        }
        setBrandIcon('unknown');
        cardNumberElement.on('change', function (event) {
            if (event.brand) {
                setBrandIcon(event.brand);
            }
        });
    });
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    const handleSubmit = (stripe, elements) => async () => {
        setLoading(true);
        const cardElement = elements.getElement(CardNumberElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });
        setLoading(false);
        if (error) {
            toast.error(error.message);
        } else {
            setLoading(true);
            await (
                await fetch("https://romp.theromp.com/api/add-card", {
                    method: "POST",
                    body: JSON.stringify({
                        intent: `${paymentMethod.id}`,
                    }),
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            ).json();
            setLoading(false);
            toast.success("Added New Payment Info!");
            window.location.reload();
        }
    };
    
    return (
        <ViewWrapper icon={card} header={"Billing"}>
            <div className="billing_content">
                <div className="grid">
                    <div className="row">
                        <div className="head col">Payment Info</div>
                        {(
                            <div
                                className="info col def"
                            >
                                <div className="grid-card">
                                    <span className="card-info" dangerouslySetInnerHTML={{__html: transform(getPaymentInfo(user.credit_info.id).label) + ' '}}></span><span className="bold-rep">PREFERRED</span>
                                    <Popup trigger={<span>Edit</span>} modal nested position="right center">
                                        {close =>
                                        (<div className="modal">
                                            <div className="header"><h3>Payment Information</h3></div>
                                            <div className="content">
                                                {loading ? (
                                                    <CgSpinnerTwoAlt className="like_loader" size={45} />
                                                ) : (
                                                    <>
                                                        <h4>Select a Default Card</h4>
                                                        <div className="container">
                                                            <Select
                                                                defaultValue={getPaymentInfo(user.credit_info.id)}
                                                                options={options}
                                                                onChange={changePaymentMethod}
                                                                components={{ Option: IconOption }}
                                                                placeholder="Default Card"
                                                            />
                                                        </div>
                                                        <div className="actions">
                                                            <button className="button-cancel" onClick={() => {
                                                                close();
                                                            }}>
                                                                Close</button>
                                                        </div>
                                                    </>

                                                )}
                                            </div>
                                        </div>
                                        )}
                                    </Popup>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="head col">Add Payment </div>
                        <div
                            className="info col"
                            onClick={() => {
                                setBillingHistory();
                            }}
                        >
                            Add additional payment method <Pen />
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Next Billing</div>
                        <div className="info col">
                            {new Date(
                                Date.parse(user.credit_info.current_period_end)
                            ).toDateString()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Last Payment</div>
                        <div className="info col">
                            {new Date(
                                Date.parse(user.credit_info.current_period_start)
                            ).toDateString()}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                header="Adding Payment Method"
                setmodal={() => { setBillingHistory(); 
                    // if(window.card) { window.card['a'].destroy(); window.card['b'].destroy(); window.card['c'].destroy(); window.card = null; } 
                }}
                state={billingHistory}
            >
                
                <section className="add-card">
                    <h1>Card Details</h1>
                    {loading ? (
                                                    <CgSpinnerTwoAlt className="like_loader" size={45} />
                                                ) : 
                    <form method="POST">
                        <div class="group">
                            <label>
                                <span>Card number</span>
                                <div ref={number} id="card-number-element" class="field"></div>
                                <span class="brand"><i class="pf pf-credit-card" id="brand-icon"></i></span>
                            </label>
                            <label>
                                <span>Expiry date</span>
                                <div ref={exp} id="card-expiry-element" class="field"></div>
                            </label>
                            <label>
                                <span>CVC</span>
                                <div ref={cvv} id="card-cvc-element" class="field">
                                </div>
                            </label>
                        </div>
                        <button type="button" onClick={handleSubmit(stripe, elements)}>Add Card</button>
                        <div class="outcome">
                            <div class="error"></div>
                            <div class="success">
                                Success! Your Stripe token is <span class="token"></span>
                            </div>
                        </div>
                    </form>
                }
                </section>
            </Modal>
        </ViewWrapper>
    );
}
