import React from "react";
import "swiper/swiper-bundle.min.css";
import { useEffect, useRef, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Home from "./Home/Home";
import Account from "./Account/Account";
import Feature from "./Feature";
import { useAPIFetch } from "../../utils/Hooks/useAPIFetch";
import Videos from "./Videos";
import ShopComponent from "../Shop/components/ShopComponent/ShopComponent";
import Models from "./Models";
import List from "./List";
import Search from "./Search";
import { useTheme, useUser } from "../../utils/Contexts/Context";
import Messages from "./Messages";
import Player from "./Player";
import { v4 } from "uuid";
import NavBar from "./NavBar";
import FeaturePreview from "./Feature-preview";
function Dashboard() {
    const match = useRouteMatch();
    const navRef = useRef();
    const {
        user: { user_id: user, token },
        refetch,
        setRefetch,
    } = useUser();
    const [DataDetails, setDataDetails] = useState(null);
    const [data, loading, Err] = useAPIFetch(
        `get_account_settings/${user}`,
        null,
        "GET",
        false,
        refetch
    );
    if (data !== null && data.user.current_plan === null) {
        window.location.href = "https://romp.theromp.com/step-2";
    }

    const [NewMessageAlert, setNewMessageAlert] = useState(false);
    // All mesasages in Message box, this should be gotten from API
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if(window.tabsList == undefined)
        window.tabsList = [];
    const [tabsList, setTabsList] = useState(window.tabsList);
    const [message_list, setmessage_list] = useState(0);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const response = await (
                    await fetch("https://romp.theromp.com/api/messages", {
                        method: "GET",
                        headers: {
                            "Content-type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    })
                ).json();
                if(tabsList.length == 0 || tabsList.length != response.data.length) {
                    window.tabsList = response.data.map((t) => {
                        return {
                            id: t.message_id,
                            icon: t.avatar,
                            subject: t.name,
                            msg: t.body,
                            message: t.body.substring(0, 16)+'...',
                            seen_at: t.seen_at,
                            timestamp: new Date(t.created_at).getTime(),
                        };
                    });
                    setTabsList(
                      window.tabsList  
                    );
                }
                setmessage_list(response.data.length);
            } catch (error) {
                fetchMessages();
            }
            if (tabsList.length !== message_list) {
                setNewMessageAlert(true); //when new message inbox comes in
            }
        };
        
        fetchMessages();
        const interval = setInterval(() => fetchMessages(), 5000)
        return () => {
            clearInterval(interval);
        }
    }, [tabsList,message_list]);
    useEffect(() => {
        data && setDataDetails(data);
    },[data])
    document.body.style.background =
        DataDetails && DataDetails.user.settings.dark_mode === 1 ? "rgb(10, 10, 10)" : "";
    const { setMode } = useTheme();
    //False for Darkmode True for Light Mode
    useEffect(() => {
        setMode(DataDetails && DataDetails.user.settings.dark_mode === 1 ? false : true);
    }, [DataDetails, setMode]);
    const isEdge = window.navigator.userAgent.indexOf('Edg') !== -1 ? 'isEdge' : ''
    window.setNewMessageAlert = setNewMessageAlert;
    return (
        <>
            {Err && (
                <center style={{ margin: "100px auto" }}>
                    <h1>
                        An Error Occured{" "}
                        <span
                            className="reload"
                            onClick={() => {
                                setRefetch(v4());
                            }}
                        >
                            Reload
                        </span>
                    </h1>
                </center>
            )}
            {loading && (
                <center className="loader" style={{ margin: "100px auto" }}>
                    <h1>Loading...</h1>
                </center>
            )}
            {DataDetails && (
                <div
                    className={`dashboard ${DataDetails.user.settings.dark_mode === 1 ? "dark" : ""
                        } ${isEdge}`}
                >
                    <NavBar navRef={navRef} NewMessageAlert={NewMessageAlert}  />
                    <Switch>
                        <Route path={`${match.path}/player`} exact>
                            <Player navRef={navRef} />
                        </Route>
                        <Route path={`${match.path}/preview/videocontent`}>
                            <FeaturePreview />
                        </Route>
                        <Route path={`${match.path}/feature`}>
                            <Feature />
                        </Route>
                        <Route path={`${match.path}/account`}>
                            <Account />
                        </Route>
                        <Route path={`${match.path}/messages`}>
                            <Messages tabsList={tabsList} />
                        </Route>
                        <Route path={`${match.path}/search`}>
                            <Search />
                        </Route>
                        <Route path={`${match.path}/saved`}>
                            <List />
                        </Route>
                        <Route path={`${match.path}/models`}>
                            <Models />
                        </Route>
                        <Route path={`${match.path}/videos`}>
                            <Videos />
                        </Route>
                        <Route path={`${match.path}/shop`}>
                            <ShopComponent />
                        </Route>
                        <Route path={`${match.path}`} exact>
                            <Home />
                        </Route>
                    </Switch>
                </div>
            )}
        </>
    );
}

export default Dashboard;

export function IconWrapper({ Icon, size = 16, exe, className }) {
    const { Mode } = useTheme();
    if (Icon) {
        return (
            <Icon
                style={{ fill: Mode ? "" : "white", width: size, height: size }}
                onClick={() => exe && exe()}
                className={className}
            />
        );
    }
    else {
        return (<span></span>);
    }
}
