import { useEffect, useState } from "react";
import logo from "../../img/Nav/romp logo large@3x.png";
import { NavLink, useHistory, Redirect } from "react-router-dom";
import { useUser } from "../../utils/Contexts/Context";
function NavBar() {
    const [scroll, setScroll] = useState(() => {
        if (window.scrollY > 60) {
            return true;
        } else {
            return false;
        }
    });
    const { setUser } = useUser();
    const history = useHistory();
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 60) {
                return window.requestAnimationFrame(() => {
                    setScroll(true);
                });
            } else {
                return window.requestAnimationFrame(() => {
                    setScroll(false);
                });
            }
        });

        return () => {
            setScroll(false);
        };
    }, []);

    return (
        <div className={`nav_bar ${scroll ? "scrolled" : ""}`}>
            <div className="nav_wrapper container">
                <div className="logo">
                    <NavLink to="">
                        <img src='https://romp.theromp.com/img/logo-romp.svg' style={{'filter': 'brightness(1000%)'}} alt="romp logo" width={238} height={75} />
                    </NavLink>
                </div>
                {window.location.pathname !== "/login" ? (
                    <div className="nav_cta">
                        <NavLink
                            to="/login"
                            onClick={(e) => {
                                const user = localStorage.getItem("therompmagazine_user_id");
                                if (user) {
                                    setUser(JSON.parse(user));
                                    setTimeout(() => {
                                        history.push("/home");
                                    }, 500);
                                } else {
                                    <Redirect to="/login" />;
                                }
                            }}
                        >
                            <button className="sign_in">Sign in</button>
                        </NavLink>
                    </div>
                ) : (
                    <div className="nav_cta">
                        <NavLink to="">
                            <button className="sign_in">Home</button>
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NavBar;
