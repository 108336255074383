import { createContext, useContext, useEffect, useState } from "react";
import { useAPIFetch } from "../Hooks/useAPIFetch";
import { useWindowResize } from "../Hooks/useWindowResize";
import { v4 } from "uuid";
const UserContextWrapper = createContext();
const GlobalDimension = createContext();
const FeatureData = createContext();
const PlayerData = createContext();
const LikeList = createContext();
const ThemeMode = createContext();

export const useUser = () => {
    return useContext(UserContextWrapper);
};
export const useDim = () => {
    return useContext(GlobalDimension);
};
export const useFeat = () => {
    return useContext(FeatureData);
};
export const useLikes = () => {
    return useContext(LikeList);
};
export const usePlay = () => {
    return useContext(PlayerData);
};

export const useTheme = () => {
    return useContext(ThemeMode);
};

export function UserProvider({ children }) {
    const [user, setUser] = useState(null);
    const [refetch, setRef] = useState();
    function setRefetch() {
        setRef(v4());
    }
    const user_local = localStorage.getItem("therompmagazine_user_id");
    user_local && !user && setUser(JSON.parse(user_local));

    return (
        <UserContextWrapper.Provider value={{ user, setUser, refetch, setRefetch }}>
            {children}
        </UserContextWrapper.Provider>
    );
}
export function GlobalDimensionProvider({ children }) {
    const state = useWindowResize();
    const [dim, setDim] = useState({
        width: state ? "30vw" : "18vw",
        maxWidth: state ? "30vw" : "18vw",
        height: "100%",
        maxHeight: "100%",
        aspectRatio: state ? "330.02/482" : "355.98/199",
    });
    useEffect(() => {
        setDim((prev) => ({
            ...prev,
            width: state ? "30vw" : "18vw",
            maxWidth: state ? "30vw" : "18vw",
            maxHeight: "100%",
            aspectRatio: state ? "330.02/482" : "355.98/199",
        }));
    }, [state]);
    return <GlobalDimension.Provider value={{ dim, setDim }}>{children}</GlobalDimension.Provider>;
}
export function FeatureDataProvider({ children }) {
    const [id, setId] = useState(null);
    return <FeatureData.Provider value={{ id, setId }}>{children}</FeatureData.Provider>;
}
export function PlayerDataProvider({ children }) {
    const [video_id, seVideotId] = useState(null);
    return <PlayerData.Provider value={{ video_id, seVideotId }}>{children}</PlayerData.Provider>;
}

export function LikeListProvider({ children }) {
    const [Like_List, setLikeList] = useState({ data: null });
    const [like_list_refetch, set_like_list_refetch] = useState(null);
    const {
        user: { user_id: user },
    } = useUser();
    const [Saved_Data] = useAPIFetch(
        `user_likes?&skip=0&limit=${100000}&user_id=${user}`,
        null,
        "GET",
        false,
        like_list_refetch
    );

    useEffect(() => {
        Saved_Data && setLikeList(Saved_Data);
    }, [Saved_Data]);
    return (
        <LikeList.Provider value={{ Like_List, like_list_refetch, set_like_list_refetch }}>
            {children}
        </LikeList.Provider>
    );
}

export function ThemeModeProvider({ children }) {
    const [Mode, setMode] = useState(0);
    return <ThemeMode.Provider value={{ Mode, setMode }}>{children}</ThemeMode.Provider>;
}
