import { useContext } from "react";
import { IconWrapper } from "../Dashboard";
import { AccountSettingsContext, Pen } from "./Account";

export default function ViewWrapper({ icon, children, header, subHeader }) {
    const { UpdateUserSettings } = useContext(AccountSettingsContext);
    function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        return new Promise((resolve, reject) => {
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
     }
     
    return (
        <div className="view_wrapper profile_main">
            <div className="icon">
                <div
                    className="icon_wrapper pic"
                    style={{
                        background: `${!icon && "#f1efef"}`,
                        padding: header === "Profile" ? 0 : 10,
                        overflow: header === "Profile" ? "visible" : "hidden",
                    }}
                >
                    {icon && typeof icon === "string" ? (
                        <img src={icon} alt="" />
                    ) : (
                        <IconWrapper Icon={icon} />
                    )}
                    {header === "Profile" && (
                        <label htmlFor="profile_pic">
                            <input
                                type="file"
                                name="profile"
                                id="profile_pic"
                                style={{ display: "none" }}
                                onChange={async (e) => {
                                    const b64 = await getBase64(e.target.files[0]);
                                    const data = { avatar:  b64};
                                    console.log(b64,data);
                                    await UpdateUserSettings(data);
                                }}
                            />
                            <span className="edit">
                                <Pen />
                            </span>
                        </label>
                    )}
                </div>
                <h5>{header === 'Profile' ? 'Avatar' : header}</h5>
                <h6>{subHeader}</h6>
            </div>
            <div className="content">{children}</div>
        </div>
    );
}
