import play from "../../img/Dashboard/HomapageIcons/Triangle 1play @3x.png";
import logo_light from "../../img/Nav/romp logo large@3x.png";
import { useHistory } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { useFeat, usePlay } from "../../utils/Contexts/Context";
import { useAPIFetch } from "../../utils/Hooks/useAPIFetch";
import { useWindowResize } from "../../utils/Hooks/useWindowResize";
import { useDim } from "../../utils/Contexts/Context";
import Image from "./Home/ReusableComponenets/Image";
import { useState } from "react";
import useImage from "../../utils/Hooks/useImage";
import BookMark from "./Home/ReusableComponenets/BookMark";
import { Image as VP } from "@chakra-ui/image"
import AES from "crypto-js/aes";
import { Scrollbars } from 'react-custom-scrollbars-2';
import './style.scss'
function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}
function Feature() {
    const feat = useFeat();
    console.log(feat);
    var id = feat.id;
    if(id === null) {
        id = findGetParameter('id');
    }
    let stateObj = { id: id };
    window.history.pushState(stateObj,
             "Model Feature", "/home/feature?id="+id);
    const state = useWindowResize();
    const { seVideotId } = usePlay();
    const history = useHistory();
    const [Data, Loading] = useAPIFetch(`model_videos_contents/${id}`);
    const [View, setView] = useState("int");
    const Url = useImage(state ? Data?.data?.cover_portrait : Data?.data?.cover_landscape, true,'cover',id);

    return (
        <div className="feature">
            <div
                className={`splash ${Url === "" ? "skeleton" : ""}`}
                style={{
                    // backgroundImage: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 20%), url(${Url})`,
                    // backgroundImage: `url(${Url})`,
                }}
            >
            <img src={Url} width="100%" />
                <div className="save_top">
                    <BookMark id={id} />
                </div>
                <div className="on_splash_wrapper">
                    <div className="on_splash">
                        <div className="container">
                            <div className="control">
                                <div
                                    className="play"
                                    onClick={() => {
                                        seVideotId(id);
                                        history.push("/home/player?id="+AES.encrypt('xxxxxxxx'+id,"TheRompMagazineElemental").toString());
                                    }}
                                >
                                    <img src={play} alt="play" className="icon" />
                                    <p className="Play">Play</p>
                                </div>
                                <BookMark id={id} />
                            </div>
                            {!Loading ?                             <div
                                className={`feature_info_text ${Loading ? "skeleton text p" : ""}`}
                            dangerouslySetInnerHTML={{__html: !Loading && Data && Data.data.information}} >
                            </div> : null}

                            <div className="feature_nav">
                                <div className="nav_item">
                                    <div
                                        onClick={() => setView("int")}
                                        className={` view_setter ${View === "int" ? "active" : ""}`}
                                    >
                                        Interview
                                    </div>
                                </div>
                                <div className="nav_item">
                                    <div
                                        onClick={() => setView("pho")}
                                        className={` view_setter ${View === "pho" ? "active" : ""}`}
                                    >
                                        Photoshoot
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main>
                <div className="container">
                    {View === "int" && <Interview data={Data} />}
                    {View === "pho" && <Photoshoot data={Data} />}
                </div>
            </main>
        </div>
    );
}

export default Feature;

function Interview({ data }) {
    const state = useWindowResize();
    const history = useHistory();

    return (
        <div className="interview">
            <div className="interview_wrapper">
            <Scrollbars
                className='romp-scroll'
            >
                {data &&
                    data?.data?.video_interviews?.map(({ id, question, answer }) => (
                        <div style={{padding: '1rem'}} className="row" key={id}>
                            {<div dangerouslySetInnerHTML={{ __html: answer }}></div>}
                        </div>
                    ))}
            </Scrollbars>
            </div>
            <div className="feature_image" onClick={() => {
                        history.push("/home/player?type=interview&id="+AES.encrypt('xxxxxxxx'+data?.data.interview_link,"TheRompMagazineElemental").toString());
                    }}>
                <img src={logo_light} alt="" className="logo_label" />
                {data?.data && state !== undefined && (
                    <Image className="interview-link" big={true} id={data?.data.id} type={data?.data.interview_image ? 'interview' : 'cover'} url={ (data?.data.interview_image ?? (state ? data.data.cover_portrait : data.data.cover_landscape))+'&w=true'} />
                )}
            </div>
        </div>
    );
}

function Photoshoot({ data }) {
    const countJSX = data?.data?.video_photoshoots?.map(({ image_url, id }) => {
        return <PopUp key={id} id={id} url={image_url} />;
    });

    return (
        <SRLWrapper
            options={{
                disableKeyboardControls: false,
                disablePanzoom: true,
                disableWheelControls: false,
                usingPreact: false,
                progressBar: false,
                caption: false,
                buttons: {
                    showAutoplayButton: false,
                    showCloseButton: true,
                    showDownloadButton: false,
                    showFullscreenButton: false,
                    showThumbnailsButton: false,
                    showNextButton: false,
                    showPrevButton: false,
                },
                thumbnails: {
                    showThumbnails: false,
                },
            }}
        >
            <div className="photoshoot">{countJSX}</div>
        </SRLWrapper>
    );
}

function PopUp({ url,id }) {
    const Url = useImage(url,true,'photoshoot',id);
    const { dim } = useDim();
    return (
        <button href={Url} style={dim}>
            <VP blur={true} src={Url}  fallbackSrc={Url} alt=" "                 onContextMenu={(e) => {
                    e.preventDefault();
                }} />

        </button>
    );
}
