import play from "../../img/Dashboard/HomapageIcons/Triangle 1play @3x.png";
import logo_light from "../../img/Nav/romp logo large@3x.png";
import { useHistory } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { useFeat, usePlay } from "../../utils/Contexts/Context";
import { useAPIFetch } from "../../utils/Hooks/useAPIFetch";
import { useWindowResize } from "../../utils/Hooks/useWindowResize";
import { useDim } from "../../utils/Contexts/Context";
import Image from "./Home/ReusableComponenets/Image";
import { useState } from "react";
import useImage from "../../utils/Hooks/useImage";
import BookMark from "./Home/ReusableComponenets/BookMark";
import { IconWrapper } from "./Dashboard";
import { Image as VP } from "@chakra-ui/image"
import AES from "crypto-js/aes";

import './style.scss'
function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}
function FeaturePreview() {
    var data = window.localStorage.getItem('preview_data')
    data = JSON.parse(atob(data));
    var Data = {};
    Data.data = {};
    for (const key in data) {
        const element = data[key];
        Data.data[key] = atob(element);
    }
    var id = -1;
    let stateObj = { id: id };
    const state = useWindowResize();
    const { seVideotId } = usePlay();
    const history = useHistory();
    const [View, setView] = useState("int");
    const Url = 'https://romp.theromp.com/public'+(state ? Data?.data['cover_portrait'] : Data?.data['cover_landscape']);

    if(data === null) {
        return <div>Bad Preview Data</div>
    }
    var Loading = false;
    return (
        <div className="feature">
            <div
                className={`splash ${Url === "" ? "skeleton" : ""}`}
                style={{
                    // backgroundImage: `linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,0,0,0) 20%), url(${Url})`,
                    // backgroundImage: `url(${Url})`,
                }}
            >
            <img src={Url} width="100%" />
                <div className="save_top">
                    <BookMark id={id} />
                </div>
                <div className="on_splash_wrapper">
                    <div className="on_splash">
                        <div className="container">
                            <div className="control">
                                <div
                                    className="play"
                                    onClick={() => {
                                        seVideotId(id);
                                        history.push("/home/player?type=interview&id="+AES.encrypt('xxxxxxxx'+Data?.data['video_url'],"TheRompMagazineElemental").toString());
                                    }}
                                >
                                    <img src={play} alt="play" className="icon" />
                                    <p className="Play">Play</p>
                                </div>
                                <BookMark id={id} />
                            </div>
                            {!Loading ?                             <div
                                className={`feature_info_text ${Loading ? "skeleton text p" : ""}`}
                            dangerouslySetInnerHTML={{__html: Data.data['information'] }} >
                            </div> : null}

                            <div className="feature_nav">
                                <div className="nav_item">
                                    <div
                                        onClick={() => setView("int")}
                                        className={` view_setter ${View === "int" ? "active" : ""}`}
                                    >
                                        Interview
                                    </div>
                                </div>
                                <div className="nav_item">
                                    <div
                                        onClick={() => setView("pho")}
                                        className={` view_setter ${View === "pho" ? "active" : ""}`}
                                    >
                                        Photoshoot
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main>
                <div className="container">
                    {View === "int" && <Interview data={Data} />}
                    {View === "pho" && <Photoshoot data={Data} />}
                </div>
            </main>
        </div>
    );
}

export default FeaturePreview;

function Interview({ data }) {
    const state = useWindowResize();
    const history = useHistory();
    return (
        <div className="interview">
            <div className="interview_wrapper">
                    <div className="row">
                        {<div dangerouslySetInnerHTML={{ __html: data?.data['a[]'] }}></div>}
                    </div>
            </div>
            <div className="feature_image">
                <img src={logo_light} alt="" className="logo_label" />
                {data?.data && state !== undefined && (
                    <Image className="interview-link" exe={() => {
                        history.push("/home/player?type=interview&id="+AES.encrypt('xxxxxxxx'+data?.data.interview_link,"TheRompMagazineElemental").toString());
                    }} preview={ 'https://romp.theromp.com/public'+(data?.data.interview_image)} />
                )}
            </div>
        </div>
    );
}

function Photoshoot({ data }) {
    const countJSX = data?.data?.photoshoot.split(',').map((image_url) => {
        return <PopUp url={'https://romp.theromp.com/public'+image_url} />;
    });

    return (
        <SRLWrapper
            options={{
                disableKeyboardControls: false,
                disablePanzoom: false,
                disableWheelControls: false,
                usingPreact: false,
                progressBar: false,
                caption: false,
                buttons: {
                    showAutoplayButton: false,
                    showCloseButton: true,
                    showDownloadButton: false,
                    showFullscreenButton: false,
                    showThumbnailsButton: false,
                    showNextButton: false,
                    showPrevButton: false,
                },
                thumbnails: {
                    showThumbnails: false,
                },
            }}
        >
            <div className="photoshoot">{countJSX}</div>
        </SRLWrapper>
    );
}

function PopUp({ url,id }) {
    const { dim } = useDim();
    return (
        <a href={url} style={dim}>
            <VP blur={true} src={url}  fallbackSrc={url} alt=" "                 onContextMenu={(e) => {
                    e.preventDefault();
                }} />

        </a>
    );
}
