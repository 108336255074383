import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { base } from "../consts";
import { useUser } from "../Contexts/Context";

export function useAPIFetch(url, body, method = "GET", alert = false, refetch, auth = true) {
    const [Loading, setLoading] = useState(true);
    const [Data, setData] = useState(null);
    const [Err, setError] = useState(null);
    const { user } = useUser();
    let { token } = user || {};
    useEffect(() => {
        async function getFetch() {
            setLoading(true);
            setData(null);
            setError(null);
            try {
                let result = await fetch(`${base}/${url}`, {
                    headers: {
                        ...(auth && { Authorization: `Bearer ${token || ""}` }),
                        "content-type": "application/json",
                        accept: "*/&",
                        "access-Control-Allow-Origin": "*",
                    },
                    ...(body && body),
                    method,
                });
                if (result.status === 500) throw new Error("Server Error!");
                result = await result.json();
                setData(result);
                if (result.user || result.success) {
                    alert && toast.success(result.message || "Success!");
                } else {
                    alert && toast.error(result.message || "Failed to fetch!");
                    throw new Error(result.message, result.success);
                }
            } catch (error) {
                setError(error);
                alert && toast.error("Failed to Fetch!");
                setData(null);
            } finally {
                setLoading(false);
            }
        }
        url && getFetch();
        return function () {
            setData(null);
            setLoading(false);
            setError(null);
        };
    }, [url, body, alert, method, refetch, token, auth]);
    return [Data, Loading, Err];
}

export const regFetch = async (url, body, method, token, alert, successMsg, errMsg) => {
    try {
        var result = await fetch(`${base}/${url}`, {
            headers: {
                Authorization: `Bearer ${token || ""}`,
                "Content-type": "application/json",
                "access-Control-Allow-Origin": "*",
                Accept: "application/json",
            },
            ...(body && { body: JSON.stringify(body) }),
            method: method,
        });
        result.ok && alert ? toast.success(successMsg) : toast.error(errMsg);
        result = await result.json();
    } catch (error) {
        alert && toast.error("Faild!");
    }
    return result;
};

const getOnLineStatus = () =>
    typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
        ? navigator.onLine
        : true;

export const useNavigatorOnLine = () => {
    const [status, setStatus] = useState(getOnLineStatus());
    const warn = {
        w: false,
    };

    const setOnline = () => {
        setStatus(true);
        warn.w && toast.success("You're back Online!");
    };
    const setOffline = () => {
        setStatus(false);
        toast.error("You're Offline!");
        warn.w = true;
    };

    const CheckOnline = () => {
        if (navigator.onLine) {
            setOnline();
        } else {
            setOffline();
        }
    };

    useEffect(() => {
        window.addEventListener("load", CheckOnline);
        window.addEventListener("online", setOnline);
        window.addEventListener("offline", setOffline);

        return () => {
            window.removeEventListener("load", CheckOnline);
            window.removeEventListener("online", setOnline);
            window.removeEventListener("offline", setOffline);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return status;
};
