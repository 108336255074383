import { useState, useEffect } from "react";
export function useWindowResize(limit) {
    const [width_state, setstate] = useState();

    function handleResize() {
        setstate(window.innerWidth < (limit || 960));
    }
    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        window.addEventListener("load", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("load", handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return width_state;
}
