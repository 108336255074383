export function alterZoom(zoom = false) {
    const head = document.head;
    const meta = head.querySelector("meta[name=viewport]");
    meta && meta.remove();
    let newMeta = document.createElement("meta");
    newMeta.name = "viewport";
    newMeta.content = `width=device-width, initial-scale=1 maximum-scale=1.0 ${
        zoom ? "" : "user-scalable=no"
    }`;
    document.head.append(newMeta);
}
