import React, { Children } from "react";
import { withRouter } from "react-router";
import { BsBookmark,BsBookmarkFill, BiSearch,IoClose } from "react-icons/all";
import Countdown from 'react-countdown';
import './style.scss';

class ProductListing extends React.PureComponent {
    constructor(props) {
        super(props);
        const categoryId = this.props.match.params['categoryId'];
        this.state = {
            open_search: false
        }
        if(categoryId !== null) {
            const data = Object.entries(JSON.parse(localStorage.getItem('categories')) ?? []);
            const e = this;
            data.forEach(cat => {
                if(cat[1].name.toLowerCase()
                .replace(/ /g, '-').replace(/--/g,'-')
                .replace(/[^\w-]+/g, '')+'' === categoryId) {
                    e.props.functions.setProducts(cat[1]);
                }
            });
        }else if(categoryId === null) {
            this.props.functions.setProducts();
        }
    }
    product_navigate(product) {
        this.props.history.push('/home/shop/product/'+product.id);
    }
    get_four_product(products) {
        return products.slice(1,5);
    }
    render() {
        const products = this.props.functions.getProducts();
        const isHome = window.location.pathname === '/home/shop';
        const { isMobile } = this.props;
        const { open_search } = this.state;
        if(products === null)
            return <h1>Fetching Products.</h1>;
        if(products.items.length === 0)
            return <h1>No Products Found.</h1>;
        if(isMobile && this.props.functions.getPage() === 'Home') {
            return (
                <div>
                    <div className="top-product">
                        <div style={{ fontSize: '7em', position: 'absolute', top: '-1.9rem', left:'1em' }}
                        onClick={() => { this.setState({open_search: !open_search}) }}
                        >{open_search == true ? <IoClose /> : <BiSearch />} </div>
                        {open_search == true ? <div className="top-product-search-bar">
                            <BiSearch className="magnify" />
                        <input
                                onKeyUp={(e) => {
                                    if(e.key == 'Enter'){
                                        if (window.location.pathname !== "/home/shop/search")
                                            this.props.history.push("/home/shop/search");
                                        this.props.functions.setProducts(
                                            null,
                                            null,
                                            20,
                                            1,
                                            e.target.value
                                        );
                                    }
                                }}
                                onChange={(e) => {
                                    this.state.value = e.target.value;
                                }}
                                type="text"
                                placeholder="Search here"
                                className="search-top"
                            />
                        </div> : ''}
                        
                        <div className="top-product-image">
                            <img src={'https://romp.theromp.com/gordon?asset='+products.items[0].gallery[0]} />
                            <h3>{products.items[0].name}</h3>
                            {products.items[0].enabled === 1 ? <h4 className="product-price">{products.items[0].special_price ? <div><small><strike>${products.items[0].price}</strike></small> ${products.items[0].special_price}</div> : '$' +products.items[0].price } </h4> : <h4 className="product-price">Coming Soon</h4>}

                            <button onClick={() => products.items[0].enabled === 1 && this.product_navigate(products.items[0])} className="product-view-button"> {products.items[0].enabled === 0 ? <Countdown date={products.items[0].release_date} daysInHours={false}  renderer={props => <span>{props.days}d {props.hours}h {props.minutes}m {props.seconds}s</span>} /> : 'view product' }</button>


                            {this.props.functions.productExistsInWishList(products.items[0]) === true ? 
                            <div className="mobile-bookmarka-active" onClick={() => this.props.functions.removeFromWishList(products.items[0])}>
                                <BsBookmarkFill/>
                            </div> : 
                            <div className="mobile-bookmarka" onClick={() => this.props.functions.addToWishList(products.items[0])}>
                                <BsBookmark/>
                            </div>}


                        </div>
                        <h5>More from this shop</h5>
                        <div className="grid">
                            <div className="product-grid-left" onClick={() => { 
                                this.props.history.push('/home/shop/category/apparel');
                                this.props.functions.setProducts(
                                    {name: 'Apparel', id: 2},
                                    null,
                                    20,
                                    1,
                                    null
                                );
                            }} >
                                {Children.toArray(this.get_four_product(products.items).map(t=> <img src={'https://romp.theromp.com/gordon?asset='+t.gallery[0].replace('https://romp.theromp.com/public','')} />))}
                            </div>
                            <div className="product-grid-right" onClick={() =>  this.product_navigate(this.get_four_product(products.items)[0])} >
                                <img src={'https://romp.theromp.com/gordon?asset='+this.get_four_product(products.items)[0].gallery[0].replace('https://romp.theromp.com/public','')} />
                            </div>
                        </div>
                        <button onClick={() => this.props.functions.toggleMenu()} className="see-all-btn">See All</button>
                    </div>
                </div>
            );
        }
        if(isMobile) {
            return (<div>
                <h1 className="title">Category: {this.props.functions.getPage()}</h1>
                <ul className={isHome ? 'products-grid ishome' : 'products-grid' }>
                    {Children.toArray(Object.entries(products.items).map(t => 
                    <li className="product-grid">
                        <div className="mobile-product"  onClick={() => t[1].enabled === 1 && this.product_navigate(t[1])}>
                            <img src={'https://romp.theromp.com/gordon?asset='+t[1].gallery[0].replace('https://romp.theromp.com/public','')} />
                        </div>
                        <div className="mobile-options">
                            <h4>{t[1].name}</h4>
                            {this.props.functions.productExistsInWishList(t[1]) === true ? 
                            <div className="mobile-bookmarka-active" onClick={() => this.props.functions.removeFromWishList(t[1])}>
                                <BsBookmarkFill/>
                            </div> : 
                            <div className="mobile-bookmarka" onClick={() => this.props.functions.addToWishList(t[1])}>
                                <BsBookmark/>
                            </div>}
                            {t[1].enabled === 1 ? <h4 className="product-price">{t[1].special_price ? <div><small><strike>${t[1].price}</strike></small> ${t[1].special_price}</div> : '$' +t[1].price } </h4> : <h4 className="product-price"><Countdown date={t[1].release_date} daysInHours={false}  renderer={props => <span>{props.days}d {props.hours}h {props.minutes}m {props.seconds}s</span>} /></h4>}
                        </div>
                    </li>
                    ))}
                </ul>
                </div>)
        }
        return (
            <div>
            <h1 className="title">{this.props.functions.getPage()}</h1>
            <ul className={isHome ? 'products-grid ishome' : 'products-grid' }>
                {Children.toArray(Object.entries(products.items).map(t => 
                <li className="product-grid">
                    <div className="product-box">
                        <img src={'https://romp.theromp.com/gordon?asset='+t[1].gallery[0].replace('https://romp.theromp.com/public','')} />
                        {this.props.functions.productExistsInWishList(t[1]) === true ? 
                            <div className="bookmarka-active" onClick={() => this.props.functions.removeFromWishList(t[1])}>
                                <BsBookmarkFill/>
                            </div> : 
                            <div className="bookmarka" onClick={() => this.props.functions.addToWishList(t[1])}>
                                <BsBookmark/>
                            </div>}

                        <div className="product-content">
                            <div className="pro-bottom">
                                <span className="product-name">{t[1].name}</span>
                            </div>
                            <div className="pro-bottom">
                                {t[1].enabled === 1 ? (<span className="product-price">{t[1].special_price ? <div><small><strike>${t[1].price}</strike></small> ${t[1].special_price}</div> : '$ ' +t[1].price } </span>) : <span className="product-price">Coming Soon</span>}
                            </div>
                            <div className="pro-bottom" onClick={() => t[1].enabled === 1 && this.product_navigate(t[1])}>
                                <button className="product-view-button"> {t[1].enabled === 0 ? <Countdown date={t[1].release_date} daysInHours={false}  renderer={props => <div>{props.days}d {props.hours}h {props.minutes}m {props.seconds}s</div>} /> : 'view product' }</button>
                            </div>
                        </div>
                    </div>
                </li>
                ))}
            </ul>
            </div>
        );
    }
}
export default withRouter(ProductListing);