import { useCallback, useEffect, useState } from "react";
import { useUser } from "../Contexts/Context";

function useImage(url, big = false,type,id) {
    const [Url, setUrl] = useState("");
    const {
        user: { token },
    } = useUser();

    const getImage = useCallback(
        async function getImage() {
            if (id == null || id == undefined || id == '') {
                setUrl('')
            }
            if(type !== "" && type !== null)
                setUrl(`https://romp.theromp.com/gorgon?height=${window.screen.height}&width=${window.screen.width}&access_token=${token}&id=${id}&type=${type}${big ? "&w=true" : ""}`)
            else
                setUrl(`https://romp.theromp.com/get-image?access_token=${token}&asset=${url}${big ? "&w=true" : ""}`)
        },
        [url, token, big]
    ); 

    useEffect(() => {
        getImage();
        return () => {
            setUrl("");
        };
    }, [getImage, url]);
    return Url;
}

export default useImage;
