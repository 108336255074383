import React, { Children } from "react";
import { withRouter } from "react-router";
import { Form, Input, Button, Checkbox } from 'antd';
import { toast } from "react-hot-toast";

import './style.scss';

class CheckoutSuccess extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    renderNumberFormat(number) {
        // if((number+'').length < 8)
        //     return this.renderNumberFormat('0'+number)
        return <span>{number}</span>;
    }
    render() {
        return (
            <div className="success">
                <h1>Your Order has been Succesfully Placed.</h1>
                <h2>Your Order id is #{this.renderNumberFormat(this.props.match.params['id'])}</h2>
            </div>
        );
    }
}
export default withRouter(CheckoutSuccess);