import { useContext, useState } from "react";
import { useModal } from "../../../utils/Hooks/useModal";
import { useWindowResize } from "../../../utils/Hooks/useWindowResize";
import { AccountSettingsContext } from "./Account";
import { Line } from "react-chartjs-2";
import toast from "react-hot-toast";

import { ReactComponent as dols } from "../../../img/AllIconsSvg/Income.svg";
import { useUser } from "../../../utils/Contexts/Context";
import { Modal } from "../../../utils/CustomComponents";
import ViewWrapper from "./ViewWrapper";

export default function Income() {
    const state = useWindowResize(600);
    function splitDate(date) {
        const index = date.indexOf(",");
        let date1 =
            new Date(date.substring(0, index)).toDateString() === "Invalid Date"
                ? ""
                : new Date(date.substring(0, index)).toDateString();
        let date2 =
            new Date(date.substring(index + 1)).toDateString() === "Invalid Date"
                ? ""
                : new Date(date.substring(index + 1)).toDateString();
        return [date1, date2];
    }

    const {
        ChartDetails = {
            data: [],
        },
        setChartType,
        chartType,
    } = useContext(AccountSettingsContext);
    const [help, sethelp] = useModal(false);
    const lineGraphData = {
        labels:
            chartType === "daily"
                ? ChartDetails.data.map((lab) => {
                      let date = new Date(lab.date).toDateString();
                      return date === "Invalid Date" ? "" : date;
                  })
                : ChartDetails.data.map((lab) => [...splitDate(lab.date)]),
        datasets: [
            {
                backgroundColor: "none",
                data: ChartDetails.data.map((lab) => +lab.income),
                borderColor: "#2196f3",
                borderWidth: 3,
                pointBorderWidth: 1.5,
                pointBackgroundColor: "white",
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                position: "right",
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: state ? 8 : 14,
                    },
                },
            },
            y: {
                position: "right",
                grid: {
                    color: "rgba(225,225,225,0.4)",
                },
            },
        },
    };
    const {
        DataDetails: { user },
        UpdateUserSettings,
    } = useContext(AccountSettingsContext);
    const {
        user: { user_id, token },
    } = useUser();

    const handleInfo = async function (e) {
        e.preventDefault();
        const field1 = e.target[0].value;
        const field2 = e.target[1].value;
        const field3 = e.target[2].value;
        const field4 = e.target[3].value;
        const field5 = e.target[4].value;
        await UpdateUserSettings({ field1, field2, field3, field4, field5 });
        window.location.reload();
    };
    const handlePayout = async function () {
        if (ChartDetails.payout !== "Payout") return;
        const response = await (
            await fetch("https://romp.theromp.com/api/payouts", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
        ).json();
        if (response.success === false) {
            toast.error(response.message);
        } else {
            toast.success(response.message);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };
    const updateField = function(fieldname,value) { 
        const field = document.getElementsByName(fieldname);
        if(field.length > 0) {
            field[0].value = value;
        }
    }
    setTimeout(() => {
        updateField('field1',user.payout[0]);
        updateField('field2',user.payout[1]);
        updateField('field3',user.payout[2]);
        updateField('field4',user.payout[3]);
        updateField('field5',user.payout[4]);
    },100);
    return (
        <ViewWrapper
            icon={dols}
            header={"Income"}
            subHeader={
                "Invite friends and earn 30% of each subscription sign up that uses your unique link"
            }
        >
            <div className="icome_content">
                <div className="grid">
                    <div className="row">
                        <div className="head col">Unique Link:</div>
                        <div className="info col">
                            https://romp.theromp.com/?referrer={user.invitation_code}
                            <span
                                className="span_action"
                                onClick={() => {
                                    try {
                                        navigator.clipboard.writeText(
                                            "https://romp.theromp.com/?referrer=" +
                                                user.invitation_code
                                        );
                                        toast.success("Copied to Clipboard!");
                                    } catch {
                                        toast.error("Failed to Copy to Clipboard");
                                    }
                                }}
                            >
                                COPY
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Income</div>
                        <div className="info col">
                            ${ChartDetails.income}{" "}
                            <span className="small">(Payouts only availble over $200)</span>
                            {ChartDetails.income >= 200 ? (
                                <button onClick={handlePayout}>{ChartDetails.payout}</button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Bank Details</div>
                        <div className="info col" onClick={() => sethelp()}>
                            Update Bank Details
                        </div>
                    </div>
                </div>
                <hr />
                <div className="tab_wrapper">
                    <div className={`tab`}>
                        <div
                            className={`tab  ${chartType === "daily" ? "active" : ""}`}
                            onClick={() => {
                                setChartType("daily");
                            }}
                        >
                            Daily
                        </div>
                        <span></span>
                        <div
                            className={`tab ${chartType === "weekly" ? "active" : ""}`}
                            onClick={() => {
                                setChartType("weekly");
                            }}
                        >
                            Weekly
                        </div>
                    </div>
                </div>
                <div className="stats">
                    {/* Static values are used as ther is no API, you can add dynamic
                    classes for either red and green for percentages */}
                    <div className="stat">
                        <span className="header">Conversions</span>
                        <h3>{ChartDetails.referers}</h3>
                    </div>
                    <div className="stat">
                        <span className="header">Referrals</span>
                        <h3>{ChartDetails.total_hits}</h3>
                    </div>
                    <div className="stat">
                        <span className="header">Overall Revenue</span>
                        <h3>{ChartDetails.revenue}</h3>
                    </div>
                    <div className="stat">
                        <span className="header">Revenue Redeemed</span>
                        <h3>{ChartDetails.redeemed}</h3>
                    </div>
                </div>
                <div className="income_graph">
                    <Line data={lineGraphData} options={options} />
                </div>
            </div>

            <Modal header="Bank Information" setmodal={sethelp} state={help}>
                <section className="sec">
                    <form onSubmit={handleInfo}>
                        <h4>
                            Enter Your Bank Information, It will be used to process your payouts.
                        </h4>
                        <input name="field1" className="field" placeholder="Enter Bank Title" />
                        <input name="field2" className="field" placeholder="Enter Account Title"/>
                        <input name="field3" className="field" placeholder="Enter ABA/Routing Number"/>
                        <input name="field4" className="field" placeholder="Enter Swift Code"/>
                        <input name="field5" className="field" placeholder="Enter IBAN [Optional]"/>
                        <button className="btn">Save</button>
                    </form>
                </section>
            </Modal>
        </ViewWrapper>
    );
}
