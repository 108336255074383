import { Modal } from "../../../utils/CustomComponents";
import { useModal } from "../../../utils/Hooks/useModal";
import { ReactComponent as book } from "../../../img/AllIconsSvg/help.svg";
import ViewWrapper from "./ViewWrapper";
import { BiSearch, RiContactsBookLine } from "react-icons/all";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { useUser } from "../../../utils/Contexts/Context";
import { toast } from "react-hot-toast";
import "./ant.scss";
import { useState } from "react";

export default function Contact() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {
    user: { user_id, token },
    refetch,
    setRefetch,
  } = useUser();
  const onFinish = async (values) => {
    setLoading(true);
    const response = await (
      await fetch("https://romp.theromp.com/api/contact-email", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify({
          values,
        }),
      })
    ).json();
    setLoading(false);
    form.resetFields();
    toast.success("Your Message was sent.");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const renderLoading = function () {
    if (loading)
      return (
        <div
          className="loading"
          dangerouslySetInnerHTML={{
            __html: `    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
        <path fill="#000" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
        <animateTransform 
            attributeName="transform" 
            attributeType="XML" 
            type="rotate"
            dur="1s" 
            from="0 50 50"
            to="360 50 50" 
            repeatCount="indefinite" />
    </path>
    </svg>`,
          }}
        ></div>
      );
    else return null;
  };
  return (
    <ViewWrapper icon={RiContactsBookLine} header={"Contact Us"}>
      {renderLoading()}
      <div className="contact_content">
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Subject"
            name="subject"
            className="form-group"
            rules={[{ required: true, message: "Please select a subject" }]}
          >
            <Select className="form-control">
              <Select.Option value="Billing">Billing</Select.Option>
              <Select.Option value="Income ">Income</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
              <Select.Option value="Privacy">Privacy</Select.Option>
              <Select.Option value="Review">Review</Select.Option>
              <Select.Option value="Requests">Requests</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            className="form-group"
            rules={[
              {
                required: true,
                message: "Please Enter a Message",
              },
            ]}
          >
            <Input.TextArea className="form-control" />
          </Form.Item>

          <Form.Item
            className="form-group"
            wrapperCol={{ offset: 8, span: 16 }}
            rules={[{ required: true, message: "Please type your message" }]}
          >
            <Button
              type="primary"
              className="btn btn-primary form-control"
              htmlType="submit"
            >
              Send Email
            </Button>
          </Form.Item>
        </Form>
      </div>
    </ViewWrapper>
  );
}
