import { useEffect, useState } from "react";
import cover from "../../img/Hero/background Layer 713@3x.png";
import { useAPIFetch } from "../../utils/Hooks/useAPIFetch";
import Thumb from "./Home/ReusableComponenets/Thumb";
import Video from "./Home/Video";
import { useDim, useFeat, useUser } from "../../utils/Contexts/Context";
import { useWindowResize } from "../../utils/Hooks/useWindowResize";
import { CSSTransition } from "react-transition-group";
import { useHistory } from "react-router";
import { MdBookmarkBorder } from "react-icons/md";
function List() {
    const [Limit, setLimit] = useState(9);
    const {
        user: { user_id: user },
        refetch,
    } = useUser();
    const { dim } = useDim();
    const { setId } = useFeat();
    const state = useWindowResize();
    const history = useHistory();
    const [videodata, setVideoData] = useState({
        url: "",
        id: "",
    });
    const [Loading, setLoading] = useState(true);
    const [openVideo, setOpenVideo] = useState(false);
    const [DataAPI, , Err] = useAPIFetch(
        `user_likes?limit=${Limit}&skip=0&user_id=${user}`,
        null,
        "GET",
        false,
        refetch
    );
    const [Data, setData] = useState({ data: [] });
    useEffect(() => {
        DataAPI && setData(DataAPI);
        if (DataAPI) {
            setData(DataAPI);
            setLoading(false);
        } else if (Err) {
            setLoading(false);
        }
    }, [DataAPI, Err]);
    return (
        <div className="list page" id="list">
            {/* <div className="cover">
                <img src={cover} alt="" />
                <h1 className="model_name">SAVED</h1>
            </div> */}
            {/* <h3>
                SAVED <MdBookmarkBorder />
            </h3> */}
            <div className="container">
                <div className="header">
                <MdBookmarkBorder style={{'height' : '30px', 'width' : '30px'} } />
                <h1>SAVED </h1>
                </div>
                <div className="grid">
                    {Loading && (
                        <>
                            {Array.from({ length: Limit }).map((c) => (
                                <div key={c} className="video_card skeleton" style={dim}></div>
                            ))}
                        </>
                    )}
                    {Err && <h1>An Error Occured</h1>}
                    {Data &&
                        !Err &&
                        (Data.data.length === 0 ? (
                            <h1>No Videos</h1>
                        ) : (
                            <>
                                {Data.data.map(({ video }) => {
                                    let {
                                        id,
                                        photo_portrait,
                                        photo_landscape,
                                        cover_portrait,
                                        cover_landscape,
                                        title,
                                    } = video || {};
                                    return (
                                        <Thumb

                                            imageUrl={
                                                state === true
                                                    ? photo_portrait
                                                    : state === false && photo_landscape
                                            }
                                            type='photo'
                                            cover_Url={
                                                state === true
                                                    ? cover_portrait
                                                    : state === false && cover_landscape
                                            }
                                            id={id}
                                            sub={title}
                                            key={id}
                                            exe={() => {
                                                if (state) {
                                                    setId(id);
                                                    history.push("/home/feature");
                                                } else {
                                                    setVideoData({
                                                        id: id,
                                                        url: state
                                                            ? cover_portrait
                                                            : cover_landscape,
                                                    });
                                                    setOpenVideo(true);
                                                }
                                            }}
                                        />
                                    );
                                })}
                            </>
                        ))}
                </div>
                {Data && (
                    <div className="more">
                        <button
                            className="show_more"
                            disabled={Limit > Data.data.length}
                            onClick={() => setLimit(Limit + 10)}
                        >
                            {Limit > Data.data.length ? "No more to Show" : "Show more"}
                        </button>
                    </div>
                )}
            </div>
            <CSSTransition in={openVideo} unmountOnExit classNames="video_popup" timeout={400}>
                <Video videodata={videodata} closeVideo={setOpenVideo} />
            </CSSTransition>
        </div>
    );
}

export default List;
