import React, { Children } from "react";
import { withRouter } from "react-router";
import "./style.scss";
import { toast } from "react-hot-toast";
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from "react-image-gallery";
import { BsBookmark, BsBookmarkFill, BiSearch } from "react-icons/all";
import Collapse, { Panel } from "rc-collapse";
import {
  MdOutlineArrowDropDown,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
class Product extends React.PureComponent {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.props.functions.setProduct(id);
    this.state = {
      size: null,
      sizecost: null,
      color: null,
      colorcost: null,
      added: false,
    };
  }

  renderColorAttribute() {
    const product = this.props.functions.getProduct();
    if (product.color === undefined) return "";
    return (
      <div className="product-attribute">
        <div className="attribute-values">
          <span>Colour:</span>
          <ul>
            {Children.toArray(
              product.color.map((t) => (
                <li
                  className={t[0] === this.state.color ? "active" : ""}
                  onClick={() => {
                    this.setState({ color: t[0], colorcost: t[1] });
                  }}
                >
                  {t[0]}
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    );
  }

  renderSizeAttribute2() {
    const product = this.props.functions.getProduct();
    if (product.size === undefined) return "";
    return (
      <div className="product-attribute">
        <div className="attribute-values">
          <table className="table">
            <tr  className="tab-tr">
                <td className="option-name">
                    <span>Size:</span>
                </td>
                {Children.toArray(
                product.size.map((t) => (
                    <td
                    className="borixx"
                    
                    >
                  <span onClick={() => {
                        this.setState({ size: t[0],sizecost: t[1] });
                    }} className={t[0] === this.state.size ? "borix active" : "borix"}>{t[0]}</span>
                    </td>
                ))
                )}
            </tr>
          </table>
        </div>
      </div>
    );
  }
  
  renderColorAttribute2() {
    const product = this.props.functions.getProduct();
    if (product.color === undefined) return "";
    return (
      <div className="product-attribute">
        <div className="attribute-values">
        <table className="table">
            <tr  className="tab-tr">
                <td className="option-name">
                    <span>Colour:</span>
                </td>
            {Children.toArray(
              product.color.map((t) => (
                <td className="borixx"
                >
                  <span onClick={() => {
                    this.setState({ color: t[0],colorcost: t[1] });
                  }} className={t[0] === this.state.color ? "borix active" : "borix"}>{t[0]}</span>
                </td>
              ))
            )}
          </tr>
        </table>
        </div>
      </div>
    );
  }

  renderSizeAttribute() {
    const product = this.props.functions.getProduct();
    if (product.size === undefined) return "";
    return (
      <div className="product-attribute">
        <div className="attribute-values">
          <span>Size:</span>
          <ul>
            {Children.toArray(
              product.size.map((t) => (
                <li
                  className={t[0] === this.state.size ? "active" : ""}
                  onClick={() => {
                    this.setState({ size: t[0],sizecost: t[1] });
                  }}
                >
                  {t[0]}
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    );
  }
  
  addToCart(e) {
    const product = e.props.functions.getProduct();
    const productStore = {
      id: product.id,
      name: product.name,
      price: this.renderPrice(product.special_price ?? product.price),
      quantity: 1,
      is_downloadable: product.is_downloadable,
      gallery: product.gallery[0],
    };
    if (product.color !== undefined) {
      if (e.state.color === null) {
        toast.error("Select a Color.");
        return;
      }
      if (product.color.map(t=> t[0]).indexOf(e.state.color) === -1) {
        toast.error("Invalid Color.");
        return;
      }
      productStore["color"] = e.state.color;
    }
    if (product.size !== undefined) {
      if (e.state.size === null) {
        toast.error("Select a Size.");
        return;
      }
      if (product.size.map(t=> t[0]).indexOf(e.state.size) === -1) {
        toast.error("Invalid Size.");
        return;
      }
      productStore["size"] = e.state.size;
    }
    e.props.functions.addToCart(productStore);
    toast.success(`Added "${product.name}"" to Cart.`);

    e.setState({
      color: null,
      size: null,
      added: true,
    });
  }
  renderPrice(price) {
    return parseFloat(parseFloat(price) + parseFloat((this.state.colorcost ?? 0) + (this.state.sizecost ?? 0))).toFixed(2)
  }
  render() {
    const product = this.props.functions.getProduct();
    const { isMobile } = this.props;
    if (product === null) return <h1>Loading Product.</h1>;
    var images = [];
    product.gallery.map((t) => {
      images.push({
        original: 'https://romp.theromp.com/gordon?asset='+t,
        thumbnail: 'https://romp.theromp.com/gordon_thumb?asset='+t,
      });
    });
    if (isMobile) {
      return (
        <div className="doob">
          <ImageGallery
            thumbnailPosition={"bottom"}
            items={images}
            showThumbnails={false}
            showFullscreenButton={true}
            useBrowserFullscreen={false}
            showNav={true}
            showPlayButton={false}
            showBullets={true}
          />
          <div className="product-page">
            <div className="flex">
              <div className="left-pane">
                <h1 className="product-name">{product.name}</h1>
                {this.props.functions.productExistsInWishList(product) ===
                true ? (
                  <div
                    className="mobile-bookmarka-active"
                    onClick={() =>
                      this.props.functions.removeFromWishList(product)
                    }
                  >
                    <BsBookmarkFill />
                  </div>
                ) : (
                  <div
                    className="mobile-bookmarka"
                    onClick={() => this.props.functions.addToWishList(product)}
                  >
                    <BsBookmark />
                  </div>
                )}

                <div className="product-attributes">
                  {this.renderColorAttribute()}
                  {this.renderSizeAttribute()}
                </div>
                <h2 className="product-price">
                  {product.special_price ? (
                    <div>
                      <small>
                        <strike>${product.price}</strike>
                      </small>{" "}
                      ${product.special_price}
                    </div>
                  ) : (
                    "$ " + product.price
                  )}{" "}
                </h2>
                <button
                  className="add-to-cart"
                  onClick={() => this.addToCart(this)}
                >
                  {this.state.added === true
                    ? "Add More Piece to Cart"
                    : "Add To Cart"}
                </button>
                <div className="product-details-block">
                  <Collapse>
                    <Panel
                      forceRender={true}
                      header={
                        <p>
                          {"Details"} <MdOutlineKeyboardArrowDown />
                        </p>
                      }
                      headerClass="product-details"
                    >
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product.description,
                          }}
                        ></div>
                      </>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="product-page">
        <h4>Product Page</h4>
        <div className="flex">
          <div className="left-pane">
            <h1 className="product-name">{product.name}</h1>
            <div className="product-attributes">
              {this.renderColorAttribute2()}
              {this.renderSizeAttribute2()}
              <div className="product-attribute">
                <div className="attribute-values">
                  
                  <table className="table garm">
                  <tr>
                    <td className="option-name"></td>
                    <td className="price-box tab-tr">
                    {product.special_price ? 
                        <small>
                            <strike>${this.renderPrice(product.price)}</strike>
                          </small>
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="option-name"><span>Price:</span></td>
                    <td className="price-box tab-tr">
                      {product.special_price ? (
                        <div className="product-price">
                          ${this.renderPrice(product.special_price)}{" "}
                        </div>
                      ) : (
                        "$" + this.renderPrice(product.price)
                      )}
                    </td>
                  </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="product-details-block">
              <h1 className="product-details">Details</h1>
              <div
                dangerouslySetInnerHTML={{ __html: product.description }}
              ></div>
            </div>
            <button
              className="add-to-cart"
              onClick={() => this.addToCart(this)}
            >
              {this.state.added === true
                ? "Add More Piece to Cart"
                : "Add To Cart"}
            </button>
          </div>
          <div className="right-pane">
            <ImageGallery thumbnailPosition={"left"} items={images} />
            {this.props.functions.productExistsInWishList(product) === true ? (
              <div
                className="web-bookmarka-active"
                onClick={() => this.props.functions.removeFromWishList(product)}
              >
                <BsBookmarkFill />
              </div>
            ) : (
              <div
                className="web-bookmarka"
                onClick={() => this.props.functions.addToWishList(product)}
              >
                <BsBookmark />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Product);
