import { BiSearch, RiContactsBookLine,MdFileDownload } from "react-icons/all";
import { NavLink, useRouteMatch } from "react-router-dom";
import React, { useState } from "react";
import { ReactComponent as logout } from "../../../img/AllIconsSvg/Logout.svg";
import { Scrollbars } from 'react-custom-scrollbars-2';

// Icons
import { ReactComponent as Cog } from "../../../img/AllIconsSvg/Account.svg";
import { ReactComponent as cal } from "../../../img/AllIconsSvg/Subscription.svg";
import { ReactComponent as card } from "../../../img/AllIconsSvg/Billing.svg";
import { ReactComponent as dols } from "../../../img/AllIconsSvg/Income.svg";
import { ReactComponent as book } from "../../../img/AllIconsSvg/help.svg";
import { IconWrapper } from "../Dashboard";
import { useUser } from "../../../utils/Contexts/Context";
export default function NavBar({ Nav, Data, setNav }) {
    const tabsList = [
        {
            icon: "profile",
            text: "profile",
            sub: "View and edit account details",
            url: "",
            tags: ["profile", "account", "name", "autoplay", "darkmode", "language"],
        },
        {
            icon: Cog,
            text: "account",
            sub: "View and edit account details",
            url: "/account",
            tags: [
                "account",
                "name",
                "email",
                "password",
                "phone",
                "number",
                "address",
                "password",
            ],
        },
        {
            icon: cal,
            text: "subscription",
            sub: "View and update subscription",
            url: "/subscription",
            tags: ["subscription", "plan", "billing"],
        },
        {
            icon: card,
            text: "billing",
            sub: "View and edit billing details",
            url: "/billing",
            tags: ["billing", "payment", "info", "add"],
        },
        {
            icon: dols,
            text: "income",
            sub: "Earn money inviting friends",
            url: "/income",
            tags: ["income", "unique", "link", "statement", "bank"],
        },
        {
            icon: MdFileDownload,
            text: "downloads",
            sub: "Download your purchases",
            url: "/downloads",
            tags: ["downloads", "items", "links"],
        },
        {
            icon: book,
            text: "help",
            sub: "View legal and help articles",
            url: "/help",
            tags: ["help", "legal", "faq", "contact"],
        }
    ];
    const [tabs, setTabs] = useState(tabsList);
    const { setUser } = useUser();
    function handleSearch({ target: { value } }) {
        value === ""
            ? setTabs(tabsList)
            : setTabs(
                  tabs.filter((tab) => tab.text.match(value) || tab.tags.join("").match(value))
              );
    }
    return (
        <div className={`tabs ${Nav ? "open" : ""}`}>
            <Scrollbars
                className='romp-scroll'
            >
            <div className="search_wrapper">
                <div className="search">
                    <input type="text" placeholder="Search" onChange={handleSearch} />
                    <BiSearch />
                </div>
            </div>
            <ul className="tabs_item">
                {tabs.map(({ icon, url, text, sub }, index) => (
                    <TabItem
                        icon={icon === "profile" ? Data.user.settings.avatar : icon}
                        text={text}
                        sub={sub}
                        url={url}
                        key={index}
                        setNav={() => setNav(false)}
                    />
                ))}
                <NavLink
                    to=""
                    activeClassName="logout"
                    onClick={(e) => {
                        e.preventDefault();
                        setUser(null);
                        localStorage.removeItem("therompmagazine_user_id");
                    }}
                >
                    <li>
                        <div className="icon">
                            <IconWrapper Icon={logout} />
                        </div>
                        <div className="text">
                            <h4>logout</h4>
                            <p>Logout of The Romp Magazine</p>
                        </div>
                    </li>
                </NavLink>
            </ul>
            </Scrollbars>
        </div>
    );

    function TabItem({ url, icon, text, sub, setNav }) {
        const match = useRouteMatch();
        return (
            <NavLink to={`${match.url}${url}`} onClick={setNav} exact>
                <li>
                    <div className="icon" style={{ padding: url === "" && "0" }}>
                        {icon && typeof icon === "string" ? (
                            <img
                                src={icon}
                                alt=""
                                style={{ borderRadius: url === "" ? "50%" : 0 }}
                            />
                        ) : (
                            <IconWrapper Icon={icon} />
                        )}
                    </div>
                    <div className="text">
                        <h4>{text}</h4>
                        <p>{sub}</p>
                    </div>
                </li>
            </NavLink>
        );
    }
}
