import { useDim, useLikes, useUser } from "../../../utils/Contexts/Context";
import { useEffect, useState } from "react";
import more from "../../../img/Dashboard/HomapageIcons/more@3x.png";
import more_light from "../../../img/Dashboard/HomapageIcons/Moremore_light.png";
import play from "../../../img/Dashboard/HomapageIcons/Triangle 1play @3x.png";
import { regFetch } from "../../../utils/Hooks/useAPIFetch";
import { useHistory } from "react-router";
import Image from "./ReusableComponenets/Image";
import { v4 } from "uuid";
import { useWindowResize } from "../../../utils/Hooks/useWindowResize";
export default function FeaturedProduct({ cover_portrait, cover_landscape, id }) {
    const history = useHistory();
    const {
        user: { user_id: user, token },
    } = useUser();
    const {
        Like_List: { data },
        set_like_list_refetch,
    } = useLikes();
    const state = useWindowResize();
    const { dim } = useDim();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        data && setLoading(false);
        return () => {
            setLoading(false);
        };
    }, [data]);
    const like_List_extract = data && data.filter((dat) => dat.video_id === id);
    let liked =
        like_List_extract && like_List_extract.find((x) => x.video_id === id) ? true : false;

    async function like() {
        setLoading(true);
        const result = await regFetch(
            `user_likes`,
            { user_id: user, video_id: id },
            "POST",
            token,
            true,
            "Video Saved to your List!",
            "Failed to save Video"
        );
        result.success && set_like_list_refetch(v4());
        setLoading(false);
    }

    async function dislike() {
        setLoading(true);
        const result = await regFetch(
            `user_likes/${like_List_extract.filter((list) => list.video_id === id)[0].id}`,
            null,
            "DELETE",
            token,
            true,
            "Video removed from your List!",
            "Failed to remove Video"
        );
        result && result.success && set_like_list_refetch(v4());
        setLoading(false);
    }
    if((state ? cover_portrait : cover_landscape) == "")
        return "";
    return (
        <>
            <Image
                className="home-productImage"
                url={state ? cover_portrait : cover_landscape}
                type={'cover'}
                big={true}
            />
            <div className="container">
                <div
                    className="control"
                    style={{
                        bottom: `calc(${dim.width} * .9)`,
                    }}
                >
                    <div
                        className="list"
                        onClick={async () => {
                            liked ? dislike() : like();
                        }}
                    ></div>
                    <div
                        className="play"
                        onClick={() => {
                            history.push("/home/shop/product/" + id);
                        }}
                    >
                        <img src={play} alt="play" className="icon" />
                        <p className="Play View-Product">View Product</p>
                    </div>
                </div>
            </div>
        </>
    );
}
