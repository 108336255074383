import React, { useContext, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useUser } from "../../../utils/Contexts/Context";
import { regFetch } from "../../../utils/Hooks/useAPIFetch";
import { useModal } from "../../../utils/Hooks/useModal";
import { AccountSettingsContext } from "./Account";
import { ReactComponent as cal } from "../../../img/AllIconsSvg/Subscription.svg";
import toast from "react-hot-toast";
import { BiPencil, CgSpinnerTwoAlt, MdCheck, MdClear } from "react-icons/all";
import { Modal } from "../../../utils/CustomComponents";
import ViewWrapper from "./ViewWrapper";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function Subscription() {
    const {
        DataDetails: { user },
        setRefetch,
    } = useContext(AccountSettingsContext);
    const { plans } = user || {};
    const a = {
        s: "Settings Saved!",
        e: "Failed to save Settings!",
    };
    const {
        user: { user_id: current_user, token },
        setUser,
    } = useUser();
    const update = async (values) => {
        setloading(true);
        const result = await regFetch(`update-plan`, { ...values }, "POST", token, true, a.s, a.e);
        result.status && setRefetch(v4());
        setloading(false);
        setPlan(null);
        window.location.reload();
    };
    const [billingHistory, setBillingHistory] = useModal(false);
    const [billing, setBilling] = useState([]);
    const [loading, setloading] = useState(false);
    const [plan, setPlan] = useState(null);
    useEffect(() => {
        async function getInvoices() {
            setloading(true);
            const response = await (
                await fetch("https://romp.theromp.com/api/customer_invoice", {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            ).json();
            setloading(false);
            setPlan(null);
            setBilling(response);
        }
        getInvoices();
    }, [token]);
    const getUTC = (epoch) => {
        var d = new Date(0);
        d.setUTCSeconds(epoch);
        return d.toDateString();
    };
    const getComparePlans = function (btn_title = null) {
        return <Popup trigger={btn_title ? <span>{btn_title}</span> : <span>{user.current_plan === null ? 'No Plan' : plans.filter(t => t.stripe_plan_id === user.current_plan)[0]?.text ?? '[Deleted Developer] '} Plan</span>} modal nested position="right center">
            {close =>
            (<div className="modal">
                <div className="header"><h3>Change Current Plan</h3></div>
                <div className="content">
                    {loading ? (
                        <CgSpinnerTwoAlt className="like_loader" size={45} />
                    ) : (
                        <>
                            <div className="plan-boxes">
                                {React.Children.toArray(plans.map(({ id, sub_text, text, stripe_plan_id }) => {
                                    return <div className={"plan-box " + (stripe_plan_id === user.current_plan ? 'current-plan ' : ' ') + (plan === stripe_plan_id ? 'change-plan' : '')} onClick={() => { if (stripe_plan_id !== user.current_plan) setPlan(stripe_plan_id) }}>
                                        <div className="plan-field-1">
                                            {id}
                                        </div>
                                        <div className="plan-field-2">
                                            {sub_text.substring(1, sub_text.length - 1)}
                                        </div>
                                        <div className="plan-field-3">
                                            {text}
                                        </div>
                                    </div>
                                }))}
                            </div>
                            <div className="actions">
                                {plan !== null ?
                                    <button className="button-yes" onClick={async () => {
                                        await update({ user_id: current_user, plan: plan });
                                    }}
                                    >
                                        Change Plan
                                    </button>
                                    : null
                                }
                                <button className="button-cancel" onClick={() => {
                                    close();
                                }}>
                                    Close</button>
                            </div>
                        </>

                    )}
                </div>
            </div>
            )}
        </Popup>
    }
    return (
        <ViewWrapper icon={cal} header={"Subscription"}>
            <div className="subscription_content">
                <div className="grid">
                    <div className="row">
                        <div className="head col">Plan</div>
                        <div className="info col">
                            {getComparePlans()}
                            <br />
                            <small className="small-message">Current plan displayed. Tap to Change.</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Billing</div>
                        <div className="info col">
                            <span onClick={() => {
                                setBillingHistory();
                            }}
                            >View Billing History</span><br />
                            <small className="small-message">Tap button to view billing history</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Cancel</div>
                        <div className="info col"> {
                            user.cancelled != undefined ? <span><a href="#"  onClick={async () => {
                                try {
                                    const result = await regFetch(
                                        `cancel_subscription?user_id=${current_user}&cancel=false`,
                                        null,
                                        "POST",
                                        token,
                                        true,
                                        a.s,
                                        a.e
                                    );
                                    setRefetch(v4());
                                    (result.status || result.success) &&
                                        toast.success("Subscription Cancellation Cancelled!");

                                    // localStorage.removeItem("therompmagazine_user_id");
                                    // setUser(null);
                                } catch {
                                    toast.success("Error Reverting Subscription!");
                                }
                            }}>Revert Cancellation</a></span> :
                            <Popup trigger={<span>Cancel Subscription</span>} modal nested position="right center">
                                {close =>
                                (<div className="modal">
                                    <div className="content">
                                        {loading ? (
                                            <CgSpinnerTwoAlt className="like_loader" size={45} />
                                        ) : (
                                            <>
                                                <div className="cancellation-membership">
                                                    <div className="done-watching">
                                                        <h1>Are you done watching?</h1>
                                                        <h4>If you're sure you'd like to cancel your membership, finish below.</h4>
                                                        <div className="inver">
                                                            Cancellation will be effective at the end of your current billing period.
                                                        </div>
                                                        <button className="btn finish-member" onClick={async () => {
                                                            try {
                                                                const result = await regFetch(
                                                                    `cancel_subscription?user_id=${current_user}&cancel=true`,
                                                                    null,
                                                                    "POST",
                                                                    token,
                                                                    true,
                                                                    a.s,
                                                                    a.e
                                                                );
                                                                setRefetch(v4());
                                                                (result.status || result.success) &&
                                                                    toast.success("Subscription Canceled!");

                                                                // localStorage.removeItem("therompmagazine_user_id");
                                                                // setUser(null);
                                                            } catch {
                                                                toast.success("Error Cancelling Subscription!");
                                                            }
                                                        }}
                                                        >Finish Cancellation</button>
                                                        <button className="btn back-member" onClick={() => { close(); }}>Go Back</button>
                                                    </div>
                                                    <div className="stick-watching">

                                                        <h1>Stick around</h1>
                                                        <h4>Switch to our other plan and keep watching all your favourite models/items, without interruption.</h4>
                                                        <button className="btn downgrade-member">{getComparePlans('Switch My Plan')}</button>
                                                    </div>
                                                </div>
                                            </>

                                        )}
                                    </div>
                                </div>
                                )}
                            </Popup>
                            }
                            <br />
                            <small className="small-message">Tap to Cancel your subscription, it will not be renewed at end of period.</small>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="head col">Abandon</div>
                        <div className="info col">
                        <Popup trigger={<span>Abandon Subscription</span>} modal nested position="right center">
                                {close =>
                                (<div className="modal">
                                    <div className="content">
                                        {loading ? (
                                            <CgSpinnerTwoAlt className="like_loader" size={45} />
                                        ) : (
                                            <>
                                                <div className="cancellation-membership">
                                                    <div className="done-watching">
                                                        <h1>We are sad to see you go</h1>
                                                        <h4>If you're sure you'd like to abandon your membership, finish below.</h4>
                                                        <div className="inver">
                                                            Abandon will be effective Immeditely.
                                                        </div>
                                                        <button className="btn finish-member" onClick={async () => {
                                                            try {
                                                                const result = await regFetch(
                                                                    `abandon_subscription?user_id=${current_user}`,
                                                                    null,
                                                                    "POST",
                                                                    token,
                                                                    true,
                                                                    a.s,
                                                                    a.e
                                                                );
                                                                setRefetch(v4());
                                                                (result.status || result.success) &&
                                                                    toast.success("Subscription Canceled!");

                                                                // localStorage.removeItem("therompmagazine_user_id");
                                                                // setUser(null);
                                                            } catch {
                                                                toast.success("Error Cancelling Subscription!");
                                                            }
                                                        }}
                                                        >Finish Abandon</button>
                                                        <button className="btn back-member" onClick={() => { close(); }}>Go Back</button>
                                                    </div>
                                                    <div className="stick-watching">

                                                        <h1>Stick around</h1>
                                                        <h4>Switch to our other plan and keep watching all your favourite models/items, without interruption.</h4>
                                                        <button className="btn downgrade-member">{getComparePlans('Switch My Plan')}</button>
                                                    </div>
                                                </div>
                                            </>

                                        )}
                                    </div>
                                </div>
                                )}
                            </Popup>
                            <br />
                            <small className="small-message">Tap to Abandon your subscription your subscription will be immediately abandoned.</small>
                        </div>
                    </div> */}
                </div>
            </div>
            <Modal header="Billing Details" setmodal={setBillingHistory} state={billingHistory}>
                <section>
                    <h4 className="head">Invoices</h4>
                    <table>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Paid At</th>
                            <th>PDF</th>
                        </tr>
                        {React.Children.toArray(
                            billing.map((t) => (
                                <tr>
                                    <td>{t.invoice_number}</td>
                                    <td>{getUTC(t.date)}</td>
                                    <td>{t.description}</td>
                                    <td>$ {t.price}</td>
                                    <td>{t.status}</td>
                                    <td>{getUTC(t.paid_at)}</td>
                                    <td>
                                        <a href={t.pdf} target="_blank" rel="noreferrer">
                                            Download
                                        </a>
                                    </td>
                                </tr>
                            ))
                        )}
                    </table>
                </section>
            </Modal>
        </ViewWrapper>
    );
}
