import { useContext } from "react";
import { AccountSettingsContext, ChangeAbleInput } from "./Account";
import ViewWrapper from "./ViewWrapper";

export default function Profile() {
    const {
        DataDetails: { user },
        UpdateUserSettings,
    } = useContext(AccountSettingsContext);
    const { details, settings } = user || {};
    const { name } = details || {};
    const { auto_play = true, dark_mode = true, language, avatar } = settings || {};

    return (
        <ViewWrapper header={"Profile"} icon={avatar}>
            <div className="profile_content">
                <div className="grid">
                    <div className="row doobayje">
                        <div className="head col">Name</div>
                        <div className="info col">
                            <ChangeAbleInput
                                text={name}
                                exe={async (value) => {
                                    // Using fetch with Files doesnt seem to work...i never knew this
                                    await UpdateUserSettings({ name: value });
                                }}
                                fieldName="name"
                            />
                        </div>
                    </div>
                    <div className="row action">
                        <div className="head col">Auto Play</div>
                        <div className="info col">
                            Allows for next video in the series to play automatically.
                        </div>
                        <div className="action col">
                            <CheckBox
                                id={"autoplay"}
                                default_checked={auto_play}
                                onChange={async (checked) => {
                                    UpdateUserSettings({ auto_play: checked });
                                }}
                            />
                        </div>
                    </div>
                    <div className="row action">
                        <div className="head col">Dark Mode</div>
                        <div className="info col">
                            Enables background colour to change to dark themed layout.
                        </div>
                        <div className="action col">
                            <CheckBox
                                id={"mode"}
                                default_checked={dark_mode === 0 ? false : true}
                                onChange={async (value) => {
                                    UpdateUserSettings({ dark_mode: value ? 1 : 0 });
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="head col">Language</div>
                        <div className="info col">
                            <select
                                name="lang"
                                id="lang"
                                defaultValue={language}
                                onChange={(e) => {
                                    UpdateUserSettings({ settings: { language: e.target.value } });
                                }}
                            >
                                <option value="english">English</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </ViewWrapper>
    );

    function CheckBox({ id, default_checked, onChange }) {
        return (
            <label htmlFor={id} style={{ background: `${default_checked ? "#479eef" : "#eee"}` }}>
                <input
                    type="checkbox"
                    name="check"
                    className="account_check"
                    id={id}
                    defaultChecked={default_checked}
                    onChange={(e) => {
                        onChange(e.target.checked);
                    }}
                />
                <div
                    className="toggle"
                    style={{ left: `${default_checked ? "calc(100% - 20px)" : "-10px"}` }}
                ></div>
            </label>
        );
    }
}
