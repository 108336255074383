import { MdKeyboardArrowRight } from "react-icons/md";
import CMS from '../../utils/cms';

function Hero() {
    const get_mobile_banner = function() {
        if(window.screen.height > window.screen.width)
            return CMS("landing_page_bg_mobile");
        return CMS("landing_page_bg");
    }
    const mobile_banner = get_mobile_banner();
    if(mobile_banner !== '                            ')
    return (
        <div className="hero" style={{backgroundColor:'black', background: `linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.4)),url(https://romp.theromp.com/gordon?asset=`+mobile_banner+`)`, backgroundSize: 'cover', backgroundPosition: `center`,backgroundRepeat: `no-repeat`}}>
            <div className="hero_wrapper container">
            <h1 dangerouslySetInnerHTML={{__html: CMS("landing_page_heading_1")}} ></h1>
                <p dangerouslySetInnerHTML={{__html: CMS("landing_page_p1")}}></p>
                <p className="sub"  dangerouslySetInnerHTML={{__html: CMS("landing_page_p2")}}></p>
                <EmailForm />
            </div>
        </div>
    );
    else
    return (
    <div className="hero" style={{backgroundColor:'black', background: `linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.4)),url(https://romp.theromp.com/gordon?asset=/uploads/cms/content/6389-1646945442.jpg)`, backgroundSize: 'cover', backgroundPosition: `center`,backgroundRepeat: `no-repeat`}}>
        <div className="hero_wrapper container">
        <h1 dangerouslySetInnerHTML={{__html: CMS("landing_page_heading_1")}} ></h1>
            <p dangerouslySetInnerHTML={{__html: CMS("landing_page_p1")}}></p>
            <p className="sub"  dangerouslySetInnerHTML={{__html: CMS("landing_page_p2")}}></p>
            <EmailForm />
        </div>
    </div> )
}

export default Hero;

export function EmailForm({ formRef }) {
    function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        const result = decodeURIComponent(results[2].replace(/\+/g, " "));
        return result ? result : "";
    }
    return (
        <form
            ref={formRef}
            className="email_form"
            action="https://romp.theromp.com/exclusive/confirm-email"
            method="POST"
        >
            <input type="text" hidden name="referrer" defaultValue={window.localStorage.getItem('referrer') ?? ''} />
            <input type="email" name="email" id="email" placeholder="Email address" required/>
            <button type="submit">
                {CMS("landing_page_btn_text")}
                <MdKeyboardArrowRight size={25} />
            </button>
        </form>
    );
}
