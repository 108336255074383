import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import NavBar from "../HomePage/NavBar";
import { useUser } from "./../../utils/Contexts/Context";
import { toast } from "react-hot-toast";
import { base } from "./../../utils/consts";
import { CgSpinnerTwoAlt } from "react-icons/cg";
import CMS from '../../utils/cms';
function Login() {
    const email = useRef();
    const password = useRef();
    const rem = useRef();
    const { setUser } = useUser();
    const history = useHistory();
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        return () => {
            email.current = null;
            password.current = null;
            rem.current = null;
            setLoading(false);
        };
    }, []);

    async function submit(e) {
        e.preventDefault();
        if (email.current.value.trim() === "" || password.current.value.trim() === "")
            return toast.error("Please fill all Inputs!");
        setLoading(true);
        try {
            let user = await fetch(`${base}/authenticate`, {
                headers: {
                    "Content-type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                    email: `${email.current.value}`,
                    password: `${password.current.value}`,
                }),
            });
            user = await user.json();
            if (user.status !== "Success") throw user.message;
            setUser(user.data);
            toast.success(user.message || "Signed in!");
            localStorage.setItem("therompmagazine_user_id", JSON.stringify(user.data));
            history.push("/home");
            window.location.reload();
        } catch (error) {
            console.log(error);
            toast.error(typeof error === "object" ? "Failed to Sign In!" : error);
        } finally {
            setLoading(false);
        }
        return;
    }
    const get_mobile_banner = function() {
        console.log(CMS("landing_page_bg_mobile"));
        if(window.screen.height > window.screen.width)
            return CMS("landing_page_bg_mobile");
        return CMS("landing_page_bg");
    }
    return (
        <div id="login" className="hero"  style={{background: `linear-gradient(rgba(0,0,0,.4),rgba(0,0,0,.4)),url(https://romp.theromp.com/gordon?asset=/uploads/cms/content/6389-1646945442.jpg)`, backgroundSize: 'cover', backgroundPosition: `center`,backgroundRepeat: `no-repeat`}}>
            <NavBar />
            <div style={{ width: "100%" }}>
                <div className="container">
                    <div className="login_box">
                        <h3>Sign in</h3>
                        <form onSubmit={submit}>
                            <input
                                ref={email}
                                type="email"
                                name="email"
                                placeholder="E-mail Address"
                            />
                            <input
                                ref={password}
                                type="password"
                                name="password"
                                placeholder="Password"
                            />
                            <button type="submit" disabled={Loading}>
                                {Loading ? <CgSpinnerTwoAlt className="like_loader" /> : "Sign in"}
                            </button>
                            <div className="extras">
                                <label htmlFor="rem">
                                    <input ref={rem} type="checkbox" name="rem" id="rem" />
                                    <span>Remember Me</span>
                                </label>
                                <a href="https://romp.theromp.com/password/reset">
                                    Need Help?
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
