import { useState } from "react";
import { ReactComponent as Video_img } from "../../img/AllIconsSvg/Video.svg";
import Thumb from "./Home/ReusableComponenets/Thumb";
import { CSSTransition } from "react-transition-group";
import Video from "./Home/Video";
import { useAPIFetch } from "./../../utils/Hooks/useAPIFetch";
import { useWindowResize } from "./../../utils/Hooks/useWindowResize";
import { useDim, useFeat } from "../../utils/Contexts/Context";
import { useHistory } from "react-router";
import { v4 } from "uuid";
import { IconWrapper } from "./Dashboard";
function Videos() {
    const [videodata, setVideoData] = useState({
        url: "",
        id: "",
    });
    const [openVideo, setOpenVideo] = useState(false);
    const [Category, setCategory] = useState("all");

    return (
        <div id="videos" className="page">
            <div className="container">
                <div className="header">
                    <IconWrapper size={30} Icon={Video_img} />
                    <h1>Videos</h1>
                    <select
                        name="category"
                        id="category"
                        defaultValue={Category}
                        onChange={(e) => {
                            setCategory('')
                            setCategory(e.target.value);

                        }}
                    >
                        <option value="all">All</option>
                        <option value="Bikini">Bikini</option>
                        <option value="Travel">Travel</option>
                        <option value="Fashion">Fashion</option>
                    </select>
                </div>
                {Category === "all" ? (
                    <AllVideos setVideoData={setVideoData} setOpenVideo={setOpenVideo} />
                ) : (
                    <CategoryVideos Category={Category}  setVideoData={setVideoData} setOpenVideo={setOpenVideo} key={v4()} />
                )}
            </div>
            <CSSTransition in={openVideo} unmountOnExit classNames="video_popup" timeout={400}>
                <Video videodata={videodata} closeVideo={setOpenVideo} />
            </CSSTransition>
        </div>
    );
}

export default Videos;

function AllVideos({ setOpenVideo, setVideoData }) {
    const [Limit, setLimit] = useState(9);
    const { dim } = useDim();
    const [Data, Loading, Err] = useAPIFetch(
        `model_videos_contents?limit=${Limit}&skip=0`,
        null,
        "GET"
    );
    const state = useWindowResize();
    const { setId } = useFeat();
    const history = useHistory();
    return (
        <>
            <div className="grid">
                {Loading && (
                    <>
                        {Array.from({ length: Limit }).map((_, index) => (
                            <div key={index} style={dim} className="video_card skeleton"></div>
                        ))}
                    </>
                )}
                {Err && <h1>An Error Occured</h1>}
                {Data &&
                    (Data.data.length === 0 ? (
                        <h1>No Videos</h1>
                    ) : (
                        <>
                            {Data.data.map(
                                ({
                                    id,
                                    photo_landscape,
                                    photo_portrait,
                                    title,
                                    cover_landscape,
                                    cover_portrait,
                                }) => {
                                    return (
                                        <Thumb
                                            key={id}
                                            imageUrl={
                                                state === true
                                                    ? photo_portrait
                                                    : state === false && photo_landscape
                                            }
                                            type='photo'
                                            videodata={id}
                                            sub={title}
                                            id={id}
                                            exe={() => {
                                                if (state) {
                                                    setId(id);
                                                    history.push("/home/feature");
                                                } else {
                                                    setVideoData({
                                                        id: id,
                                                        url: state
                                                            ? cover_portrait
                                                            : cover_landscape,
                                                    });
                                                    setOpenVideo(true);
                                                }
                                            }}
                                        />
                                    );
                                }
                            )}
                        </>
                    ))}
            </div>
            {Data && (
                <div className="more">
                    <button
                        className="show_more"
                        disabled={Limit > Data.data.length}
                        onClick={() => setLimit(Limit + 10)}
                    >
                        {Limit > Data.data.length ? "No more to Show" : "Show more"}
                    </button>
                </div>
            )}
        </>
    );
}
function CategoryVideos({ Category, setOpenVideo, setVideoData }) {
    const state = useWindowResize();
    const { dim } = useDim();
    const { setId } = useFeat();
    const history = useHistory();
    const [Limit, setLimit] = useState(10);
    const [Data, Loading, Err] = useAPIFetch(
        `categories?limit=${Limit}&skip=0&category=${Category}`
    );
    return (
        <>
            <div className="grid">
                {Loading && (
                    <>
                        {Array.from({ length: Limit }).map((c) => (
                            <div style={dim} key={c} className="video_card skeleton"></div>
                        ))}
                    </>
                )}
                {Err && <h1>An Error Occured</h1>}
                {Data &&
                    (Data.data[0].video_categories.length === 0 ? (
                        <h1>No Videos</h1>
                    ) : (
                        <>
                            {Data.data[0].video_categories.map(({ id, video }) => {
                                let {
                                    photo_landscape,
                                    photo_portrait,
                                    title,
                                    cover_landscape,
                                    cover_portrait,
                                } = video;
                                return (
                                    <Thumb
                                        key={video.id}
                                        type='photo'
                                        imageUrl={
                                            state === true
                                                ? photo_portrait
                                                : state === false && photo_landscape
                                        }
                                        videodata={video.id}
                                        sub={title}
                                        id={video.id}
                                        exe={() => {
                                            if (state) {
                                                setId(video.id);
                                                history.push("/home/feature");
                                            } else {
                                                setVideoData({
                                                    id: video.id,
                                                    url: state ? cover_portrait : cover_landscape,
                                                });
                                                setOpenVideo(true);
                                            }
                                        }}
                                    />
                                );
                            })}
                        </>
                    ))}
            </div>
            {Data && (
                <div className="more">
                    <button
                        className="show_more"
                        disabled={Limit > Data.data.length}
                        onClick={() => setLimit(Limit + 10)}
                    >
                        {Limit > Data.data.length ? "No more to Show" : "Show more"}
                    </button>
                </div>
            )}
        </>
    );
}
