import useImage from "../../../../utils/Hooks/useImage";
import { useState,useRef, useEffect } from "react";
import { Image as VP } from "@chakra-ui/image"

import './style.scss'

const MAX_RETRIES = 3;
const DEFAULT_TIMEOUT = 1500;

export default function Image({ url, exe, big = false,type = "", id = "",  className = "",preview="" }) {
    var Url = useImage(url, big, type, id);
    const retries = useRef(0);
    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <div style={{position: 'relative'}} onClick={exe && exe}>
        <VP
            blur={true}
            className={className+' c'}
            src={preview === "" ? Url : preview}
            loading="lazy"
            onError={(e) => {
                e.currentTarget.parentElement.style.animationName = 'placeHolderShimmer'
                e.currentTarget.parentElement.style.animationFillMode = 'forwards';
                e.currentTarget.parentElement.style.animationDuration = '3s';
                e.currentTarget.parentElement.style.animationIterationCount = 'infinite'
                e.currentTarget.parentElement.style.animationTimingFunction = 'linear';
                e.currentTarget.parentElement.style.height = '100%';
                e.currentTarget.parentElement.style.background = "linear-gradient(to right, #eeeeee 9%, #dddddd 20%, #eeeeee 44%)";
                e.target.style.display = "inline-block";

                var currentTarget = e.currentTarget;
                retries.current += 1;
                setTimeout(() => {
                    currentTarget.src = Url+'&retry='+retries.current;
                },500)
            }}
            onLoad={(e) => {
                e.currentTarget.parentElement.style.animationName = ''
                e.currentTarget.parentElement.style.animationFillMode = '';
                e.currentTarget.parentElement.style.animationDuration = '';
                e.currentTarget.parentElement.style.animationIterationCount = ''
                e.currentTarget.parentElement.style.animationTimingFunction = '';
                e.currentTarget.parentElement.style.background = "";
                e.target.style.display = "inline-block";
            }}
            onContextMenu={(e) => {
                e.preventDefault();
            }}
        />
        </div>
    );
}
