import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/all";
import { useWindowResize } from "../../../../utils/Hooks/useWindowResize";
import { useFeat } from "../../../../utils/Contexts/Context";
import { useHistory } from "react-router";
import Thumb from "./Thumb";

export default function ImageGallery({
    className = "",
    tag = "",
    data,
    setOpenVideo,
    setVideoData,
}) {
    SwiperCore.use([Navigation]);
    const state = useWindowResize();
    const { setId } = useFeat();
    const history = useHistory();
    const [swiperInstance, setSwiperInstance] = useState(null);
    let SwiperWrapper = null;
    const mobileBreakPoint = {
        960: {
            freeMode: false,
        },
        0: {
            freeMode: true,
        },
    };

    const props = {
        slidesPerView: "auto",
        spaceBetween: 5,
        navigation: true,
        freeMode: false,
        breakpoints: mobileBreakPoint,
        ...(tag === "rank" && { loop: true, loopdslides: 150 }),
    };

    switch (tag) {
        case "rank":
            SwiperWrapper = data.video_editions.slice(0, 10).map(({ video }, index) => {
                let {
                    id,
                    photo_portrait,
                    photo_landscape,
                    cover_portrait,
                    cover_landscape,
                    title,
                } = video || {};
                return (
                    <SwiperSlide
                        key={index + 1}
                        className={`index ${index + 1 === 1 ? "under" : ""} ${
                            index + 1 > 9 ? "over" : ""
                        }`}
                    >
                        <span className="rank_index">{index + 1}</span>
                        <Thumb
                            type='photo'
                            key={id}
                            imageUrl={
                                state === true ? photo_portrait : state === false && photo_landscape
                            }
                            id={id}
                            setVideoCoverUrl={setVideoData}
                            sub={title}
                            exe={() => {
                                if (state) {
                                    setId(id);
                                    history.push("/home/feature");
                                } else {
                                    setVideoData({
                                        id: id,
                                        url: state ? cover_portrait : cover_landscape,
                                    });
                                    setOpenVideo(true);
                                }
                            }}
                        />
                    </SwiperSlide>
                );
            });
            break;
        case "comming_soon":
            const timestamp_to_date = function(timestamp) {
                const date = new Date(0);
                date.setUTCSeconds(timestamp);
                return date.toDateString();
            }
            if(data.map)
            SwiperWrapper = data.map(
                ({
                    id,
                    photo_portrait,
                    photo_landscape,
                    cover_portrait,
                    cover_landscape,
                    published_date,
                    title,
                }) => {
                    return (
                        <SwiperSlide key={id}>
                            <Thumb
                                key={id}
                                type='photo'
                                id={id}
                                coming_soon={true}
                                imageUrl={
                                    state === true
                                        ? photo_portrait
                                        : state === false && photo_landscape
                                }
                                sub={title}
                                exe={() => {
                                    if (state) {
                                        // setId(id);
                                        // history.push("/home/feature");
                                    } else {
                                        // setVideoData({
                                            // id: id,
                                            // url: state ? cover_portrait : cover_landscape,
                                        // });
                                        // setOpenVideo(true);
                                    }
                                }}
                            />
                            <span className="card_label">
                                Available {timestamp_to_date(published_date)}
                            </span>
                        </SwiperSlide>
                    );
                }
            );
            break;
        default:
            SwiperWrapper = data.video_editions.map(({ video }, index) => {
                let {
                    id,
                    photo_portrait,
                    photo_landscape,
                    cover_portrait,
                    cover_landscape,
                    title,
                } = video || {};
                return (
                    <SwiperSlide key={index}>
                        <Thumb
                            key={id}
                            id={id}
                            type='photo'
                            imageUrl={
                                state === true ? photo_portrait : state === false && photo_landscape
                            }
                            sub={title}
                            setPlay={setOpenVideo}
                            exe={() => {
                                if (state) {
                                    setId(id);
                                    history.push("/home/feature");
                                } else {
                                    setVideoData({
                                        id: id,
                                        url: state ? cover_portrait : cover_landscape,
                                    });
                                    setOpenVideo(true);
                                }
                            }}
                        />
                    </SwiperSlide>
                );
            });
    }
    if(tag === "comming_soon" && (SwiperWrapper === null ||  SwiperWrapper.length === 0)) {
            return null;
    }
    return (
        <div className={`gallery ${className} ${tag === "rank" ? "rank" : ""}`}>
            <h4 className="header">{tag === "comming_soon" ? "Coming Soon" : data.edition}</h4>
            <div className="list">
                <div
                    className="swiper_custom prev"
                    onClick={() => {
                        swiperInstance.slidePrev();
                    }}
                >
                    <MdKeyboardArrowLeft />
                </div>
                <div className="swiper_wrapper_custom">
                    <Swiper
                        onInit={(swiper) => {
                            setSwiperInstance(swiper);
                        }}
                        {...props}
                    >
                        {SwiperWrapper}
                    </Swiper>
                </div>
                <div
                    className=" swiper_custom next"
                    onClick={() => {
                        swiperInstance.slideNext();
                    }}
                >
                    <MdKeyboardArrowRight />
                </div>
            </div>
        </div>
    );
}
